import React, { useEffect, useState, useContext } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as AkaswapIcon } from './images/akaswap.svg'
import { ReactComponent as DiscordIcon } from './images/discord.svg'
import { ReactComponent as InstagramIcon } from './images/instagram.svg'
import { ReactComponent as ObjktIcon } from './images/objkt.svg'
import { ReactComponent as TwitterIcon } from './images/twitter.svg'
import { ReactComponent as ExpandButton } from './images/menu.svg'
import { SyncButton } from '../syncButton'
import { NavHashLink } from 'react-router-hash-link'
import { TezDozenContext } from '../../context/TezDozenContext'
import { NavLink } from 'react-router-dom'

export const Header = ({ fixed, current, showMenu }) => {
    // function getWindowDimensions() {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     return {
    //         width,
    //         height
    //     }
    // }
    // function handleResize() {
    //     setWindowDimensions(getWindowDimensions())
    // }
    const [expand, setExpand] = useState(false)
    const context = useContext(TezDozenContext)
    useEffect(() => {
        context.setAccount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <header className={styles.header + ' ' + (fixed ? styles.fixed : '')}>

            <div className={styles.logo}>
                <NavLink to="/">
                    {!showMenu && <span className={styles.hoverMessage}>Go to Homepage</span>}
                    <img alt='Tez Dozen' src={'/images/logo.png'}></img>
                </NavLink>
            </div>
            <div className={styles.headerBar + ' ' + (!showMenu ? styles.transparent : '')}>
                <div className={styles.menuContainer + ' ' + (expand ? styles.expand : 'yo')}>
                    {showMenu &&
                        <div className={styles.nav}>
                            <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/#Home" smooth

                            >Home</NavHashLink>
                            {/* <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/#Mint" smooth
                            >Mint</NavHashLink> */}
                            <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/#About" smooth

                            >About
                            </NavHashLink>

                            <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/Gallery" smooth

                            >Gallery
                            </NavHashLink>

                            <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/DoZenVerse" smooth
                            >
                                DozenVerse
                            </NavHashLink>

                            <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/#Timeline" smooth
                            >
                                Roadmap
                            </NavHashLink>

                            <NavHashLink
                                onClick={() => setExpand(false)}
                                to="/#FAQ" smooth
                            >
                                FAQ
                            </NavHashLink>

                            {/* <NavHashLink to="/roar-auction" onClick={() => setExpand(false)}>
                                Auction
                            </NavHashLink> */}

                            {/* <NavHashLink to="/fomo" onClick={() => setExpand(false)}>
                                FOMO
                            </NavHashLink> */}
                        </div>
                    }
                    <div className={styles.community}>
                        <a target='_blank' rel='noreferrer' href='https://akaswap.com/metaverse?collections=KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax,KT1ShjqosdcqJBhaabPvkCwoXtS1R2dEbx4W'><AkaswapIcon /></a>
                        <a target='_blank' rel='noreferrer' href='https://twitter.com/TezDoZen'><TwitterIcon /></a>
                        <a target='_blank' rel='noreferrer' href='https://discord.gg/5zkrspaKp4'><DiscordIcon /> </a>
                        <a target='_blank' rel='noreferrer' href='https://www.instagram.com/tez_dozen/'><InstagramIcon /> </a>
                        <a target='_blank' rel='noreferrer' href='https://objkt.com/collection/tezdozen'><ObjktIcon /> </a>
                    </div>
                </div>
                <div className={styles.menu}>
                    <SyncButton feature='sync' className={styles.SyncButton}></SyncButton>
                    <button className={styles.expandButton} onClick={() => setExpand(expand ? false : true)} ><ExpandButton /></button>
                </div>
            </div>
        </header >
    )
}