import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CloseButton } from './images/close_button.svg'

export const Modal = ({ children, setIsOpened = () => null, theme, className }) => {
  useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.modalOverlay+' '+(theme==='white'?styles.white:'')+' '+className} onClick={(e) => { if (e.target === e.currentTarget) setIsOpened(false) }}>
      <div className={styles.modal}>
        <div className={styles.CloseButton} onClick={(e) => { setIsOpened(false) }}><CloseButton /></div>
        {children}
        </div>
    </div>
  )
}