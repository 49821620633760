import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { Footer } from '../../components/footer'
import { mint as mintDatas } from '../../data/mint.json'
import { tdg } from '../../data/tdg.json'
import { GallerySection } from '../../components/gallery'

export const Gallery = ({ headerControl = () => null }) => {

    useEffect(() => {
        headerControl({ fixed: true, showMenu: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Page top>
                <Section
                    bottomoffset={100} className={styles.gallerySection}>

                    <div className={styles.bgDeco}>
                        {/* <img alt='' src={'./images/deco/river.png'} className={styles.decoLeft} />
                        <img alt='' src={'./images/deco/stone.png'} className={styles.decoRight} /> */}
                    </div>
                    <Container className={styles.content} larger>
                        <h2 className={styles.title}><img alt='' className={styles.titleDeco} src={'./images/deco/tree.png'} />TDTM & TDG - Gallery<img alt='' className={styles.titleDeco} src={'./images/deco/tree.png'} /></h2>
                        <GallerySection showFilter teams={mintDatas.concat(tdg)} pageSize={40}></GallerySection>
                    </Container>
                </Section>
                <Footer />

            </Page>
        </>
    )
}