import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { Footer } from '../../components/footer'
import { mint as mintDatas } from '../../data/mint.json'
import { DaoStoreSection } from '../../components/dao-store'

export const DaoStore = ({ headerControl = () => null }) => {

    useEffect(() => {
        headerControl({ fixed: true, showMenu: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Page top>
                <Section
                    bottomoffset={100} className={styles.gallerySection}>

                    <div className={styles.bgDeco}>
                        <img alt='' src={'./images/deco/hornbill.png'} className={styles.decoLeft} />
                        <img alt='' src={'./images/deco/neko.png'} className={styles.decoRight} />
                    </div>
                    <Container className={styles.content} larger>
                        <h2 className={styles.title}><img alt='' className={styles.titleDeco} src={'./images/deco/money.png'} />DAO-Exclusive Store<img alt='' className={styles.titleDeco} src={'./images/deco/money_r.png'} /></h2>
                        <DaoStoreSection/>
                    </Container>
                </Section>

            </Page>
        </>
    )
}