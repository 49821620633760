import React, { useContext, useState } from 'react'
import "swiper/swiper.scss";
import styles from './styles.module.scss'
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { TezDozenContext } from "../../context/TezDozenContext"
import { ReactComponent as RemoveButton } from './images/delete.svg'
import { ReactComponent as CartIcon } from './images/cart.svg'
import { ReactComponent as SuccessIcon } from './images/success.svg'
import { ReactComponent as ErrorIcon } from './images/error.svg'
import { ReactComponent as TickIcon } from './images/tick.svg'
import { ReactComponent as NotSupportedIcon } from './images/not_support.svg'
import { ReactComponent as AddIcon } from './images/add.svg'
import { ReactComponent as MinusIcon } from './images/minus.svg'
import sizeShirt from "./images/size_shirt.png"
import { products, shippingFee, countryData } from './irlConfig'
import { irlAPI, irl3Addr, tzktUrl } from '../../context/constant';
import { Modal } from '../modal';

export const TDTMCard = ({ token, className, onClick = () => null }) => {
    // parse token info
    const name = token.name.split("Tez Dozen ")[1].replace('Guardian', '')
    const id = token.tokenId
    const displayUri = token.thumbnailUri.split("ipfs://")[1]
    var attrs = {}
    token.attributes.forEach(({ name, value }) => { attrs[name] = value })

    return (
        <div className={styles.TDTMCard + ' ' + (className ? className : '')} >
            {(attrs['Background'] !== 'tex')
                ? <div className={styles.clickable} onClick={() => onClick()}></div>
                : <div className={styles.notClickable}><NotSupportedIcon /></div>}
            <div className={styles.image}>
                <img alt={id} src={`https://assets.akaswap.com/ipfs/${displayUri}`}></img>
            </div>
            <div className={styles.title}>
                {name}
            </div>
        </div>
    )
}

const IRLModal = ({ collection, tdgCollection, setIsOpened = () => null }) => {
    const axios = require('axios')
    const context = useContext(TezDozenContext)

    const [step, setStep] = useState(0) //0: welcome, 1: selectProduct, 2: shirt, 3: shot, 4: bag, 5: cart, 6: Confirmation, 7: processing, 8: result

    // product detail states (reset when navigate away)
    const [zoomImage, setZoomImage] = useState(0)
    const [selectOption, setSelectOption] = useState(null)
    const [selectTDTM, setSelectTDTM] = useState(null)
    const [quantity, setQuantity] = useState(1)

    // cart states
    const [cart, setCart] = useState([]) // [{type, quantity, arrIndex, tdgId, tdtmId, option}]
    const [typeQuantity, setTypeQuantity] = useState([])
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [subtotal, setSubtotal] = useState(0)

    // shipping info states
    const [recipient, setRecipient] = useState('')
    const [addr, setAddr] = useState('')
    const [country, setCountry] = useState(208) // default to TW
    const [postcode, setPostCode] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [confirmed, setConfirmed] = useState(false)

    // transaction result states
    const [success, setSuccess] = useState(false)
    const [resultMessage, setResultMessage] = useState('Connecting to your wallet')

    // clear product page option
    function clearProductOption() {
        setStep(cart.length > 0 ? (cart[0].type + 2) : 1)
        setSelectTDTM(null)
        setSelectOption(null)
        setQuantity(1)
        // setZoomImage(0)
    }

    // add item from product page state to cart state
    function addItem() {
        // add to cart
        var arr = cart
        var productIndex = step - 2
        var product = products[productIndex]

        // add Quantity to cart item if exist
        var i = arr.findIndex(({ type, option, arrIndex }) => (
            type === productIndex &&
            (!product.options || option === selectOption) &&
            (!product.name.includes('Customized') || arrIndex === selectTDTM)
        ))
        if (i > -1)
            arr[i].quantity += parseInt(quantity)

        // add new cart item if not exist
        else {
            var newItem = {
                type: productIndex,
                quantity: parseInt(quantity)
            }
            if (product.name.includes('Customized')) {
                newItem.arrIndex = selectTDTM
                if (selectTDTM < collection.length) newItem.tdtmId = collection[selectTDTM].tokenId
                else newItem.tdgId = collection.concat(tdgCollection)[selectTDTM].tokenId
            }
            if (product.options) newItem.option = selectOption
            arr.push(newItem)
        }

        setCart([...arr])

        // calculate type quantity
        var arr2 = typeQuantity.length === 0 ? products.map(() => 0) : typeQuantity
        arr2[productIndex] += parseInt(quantity)
        setTypeQuantity([...arr2])

        // calculate total Quantity
        setTotalQuantity(totalQuantity + quantity)

        // calculate subtotal
        setSubtotal(subtotal + product.price * quantity)
    }

    // remove nth item from cart
    function removeItem(index) {

        // calculate type quantity
        var item = cart[index]
        var arr2 = typeQuantity
        arr2[item.type] -= parseInt(item.quantity)
        setTypeQuantity([...arr2])

        // calculate total quantity
        setTotalQuantity(totalQuantity - item.quantity)

        // calculate subtotal
        setSubtotal(subtotal - products[item.type].price * item.quantity)

        // remove item from cart
        var arr = cart
        arr.splice(index, 1)
        setCart([...arr])
    }

    // remove nth item from cart
    function clearCart() {
        setCart([])
        setTypeQuantity([])
        setTotalQuantity(0)
        setSubtotal(0)
    }
    // send order to backend
    function submitOrder() {

        setStep(7)
        setConfirmed(false)

        setResultMessage('Placing order...')

        const orderInfo = {
            name: recipient,
            phone: phone,
            email: email,
            addr: addr,
            country: countryData[country][0],
            postcode: postcode,
            wallet: context.address,
            fee: subtotal + shippingFee[cart[0].type][countryData[country][2]],
            hasTDG: tdgCollection.length > 0,
            items: cart.map(({ arrIndex, type, option, tdgId, tdtmId, ...item }, i) => ({
                type: products[type].name,
                token: arrIndex ? collection.concat(tdgCollection)[arrIndex].name.replace('Tez Dozen ', '') : null,
                option: products[type].options ? products[type].options[option] : '',
                ...item
            }))
        }


        context.irl3Collect(
            irl3Addr,   // contract addr
            tdgCollection.length > 0 ? tdgCollection[0].tokenId : null,   //tdgId
            collection[0].tokenId,  //tdtmId
            1 + countryData[country][2] + (cart[0].type * 3),   // shippingType,
            cart.map(item => Array(item.quantity).fill(item.tdtmId ? { tdtm: item.tdtmId } : item.tdgId ? { tdg: item.tdgId } : { none: null })).flat(),    // collectList
            cart[0].type + 1,   // productId
            subtotal + shippingFee[cart[0].type][countryData[country][2]],    // total
            (opHash) => {
                setResultMessage(link(opHash, 'Processing your transaction...'))
            },  // loading
            (opHash) => {  // completed

            },
            (result) => {   // failed
                setSuccess(false)
                setResultMessage('Order placed failed.')
                setStep(8)
            }
        ).then((e) => {
            if (e) {
                setResultMessage(e.description)
                setSuccess(false)
                setStep(8)
            } else {
                axios.post(irlAPI, orderInfo)
                    .then(response => {
                        if (response.statusText === 'OK') {
                            setSuccess(true)
                            setResultMessage(`Thank you for the order.
                            Please check your email ${email} for order confirmation and delivery information.
                            If you haven't received a confirmation email, please contact us at Twitter, Discord or tezosdozen12@gmail.com`)
                            clearCart()
                            setStep(8)
                        }
                    })
                // setResultMessage('Transaction failed!')
            }
        })
            .catch((e) => {
                setResultMessage(e.description)
                setSuccess(false)
                setStep(8)
            })
    }

    const link = (opHash, message) => (
        <>{message}<br></br>
            {opHash && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={tzktUrl + opHash}>View on tzkt.io →</a>}
        </>
    )

    // Step 0
    const welcome = () => (
        <>
            <div className={styles.row + ' ' + styles.welcome}>
                <div className={styles.left}>
                    <img className={styles.productImage} alt='' />
                </div>
                <div className={styles.right}>
                    <h4 className={styles.stepTitle}>Welcome to the IRL-Shop <b className={styles.hightlight}>exclusively for TDTM holders </b> </h4>
                    <ul>
                        <li>TezDozen IRL Shop launches new product TD-bag ! Let the classic TDTM accompany you to explore in your life.</li>
                        {/* <li>Due to printing limitations, TDTM/TDG with background of “tex” attribute is not supported.</li> */}
                        <li>Shipping and handling fees will be calculated based on your shipping address.</li>
                        <li>A limited number of mysterious gifts (TD-Masks) will be given away with the purchase of any items. <b className={styles.hightlight}> An additional bonus will be given if you own any TDG. </b>
                        </li>
                        <div className={styles.checkTDG + ' ' + (tdgCollection.length > 0 ? styles.checked : '')}>
                            {/* <img src='./images/teams/meow/ipfs.png' /> */}
                            {tdgCollection.length > 0 ? <TickIcon /> : 'No '}
                            TDG
                        </div>
                    </ul>
                </div>
            </div>
            <button className={styles.bottomButton} onClick={() => setStep(1)}>Start</button>
        </>
    )

    // Step 1
    const selectProduct = () => (
        <>
            <h4 className={styles.stepTitle}>Step 1. Choose what you want</h4>
            <div className={styles.row + ' ' + styles.products}>
                {products.map((product, i) => (
                    <div className={styles.product + ' ' + ((product.comingSoon || product.ended) ? '' : styles.clickable)} onClick={!(product.comingSoon || product.ended) ? () => setStep(i + 2) : () => null}>
                        <div className={styles.productImage}>
                            <img src={`./images/irl3/${product.images[0]}`} alt='' />
                            {product.comingSoon && <div className={styles.productOverlay}>Coming soon! <br /><br /> Join the Discord to get the news!</div>}
                            {product.ended && <div className={styles.productOverlay}>Ended</div>}
                        </div>
                        <div className={styles.productName}>{product.name}</div>
                        {!product.comingSoon && !product.ended && <div className={styles.productName}>{product.price} xtz</div>}
                    </div>
                ))}
            </div>
        </>
    )

    // Step 2~4 product details
    const productDetail = (productIndex) => {
        var product = products[productIndex]
        return (
            <>
                {/* <h4 className={styles.stepTitle}>Step 2. Select your favorite TDTM/TDG</h4> */}
                <h4 className={styles.stepTitle}>Step 2. Select the TD-bag</h4>
                <div className={styles.row + ' ' + styles.productDetail}>
                    <div className={styles.left}>
                        {productIndex === 0 &&
                            <div className={styles.shirtMockup}>
                                <div>
                                    {collection.length > 0 && <img className={styles.realExampleTDTM} alt=''
                                        src={'https://assets.akaswap.com/ipfs/' +
                                            collection.concat(tdgCollection)[selectTDTM ? selectTDTM : 0]
                                                .thumbnailUri.split("ipfs://")[1]}
                                    />}
                                    {collection.length > 0 && <img className={styles.realExampleTDTM2} alt=''
                                        src={'https://assets.akaswap.com/ipfs/' +
                                            collection.concat(tdgCollection)[selectTDTM ? selectTDTM : 0]
                                                .thumbnailUri.split("ipfs://")[1]}
                                    />}
                                </div>
                            </div>
                        }
                        {productIndex === 1 &&
                            <div className={styles.shotMockup}>
                                <div>
                                    {
                                        collection.length > 0 && <img className={styles.realExampleTDTM} alt=''
                                            src={'https://assets.akaswap.com/ipfs/' +
                                                collection.concat(tdgCollection)[selectTDTM ? selectTDTM : 0]
                                                    .thumbnailUri.split("ipfs://")[1]}
                                        />}
                                </div>
                            </div>
                        }
                        {collection.length > 0 && product.name.includes('Customized') &&
                            <div className={styles.thumbnails}>
                                <Swiper slidesPerView={6} spaceBetween={0} slidesPerGroup={1} pagination={{ "clickable": true }}
                                    navigation={true} className={styles.TDTMList}
                                    initialSlide={selectTDTM ? selectTDTM : 0}
                                    // centeredSlides={true} slideToClickedSlide={true} preventClicks={false} preventClicksPropagation={false} onSlideChange={(swiper) => { setSelectTDTM(swiper.realIndex) }}
                                    breakpoints={{
                                        320: { slidesPerView: 3 },
                                        768: { slidesPerView: 4 },
                                        1024: { slidesPerView: 6 }
                                    }}>
                                    {collection.concat(tdgCollection).map((tdtm, i) =>
                                        <SwiperSlide><TDTMCard className={i === selectTDTM ? styles.selected : ''} token={tdtm} onClick={() => setSelectTDTM(i)} /></SwiperSlide>
                                    )}
                                </Swiper>
                            </div>
                        }

                        {productIndex === 2 &&
                            <div className={styles.imageZoom}>
                                <img src={`./images/irl3/${product.images[zoomImage]}`}></img>
                            </div>
                        }
                        {productIndex === 2 &&
                            <div className={styles.thumbnails}>
                                <Swiper slidesPerView={6} spaceBetween={0} slidesPerGroup={1} pagination={{ "clickable": true }}
                                    navigation={true} className={styles.TDTMList}
                                    initialSlide={selectTDTM ? selectTDTM : 0}
                                    // centeredSlides={true} slideToClickedSlide={true} preventClicks={false} preventClicksPropagation={false} onSlideChange={(swiper) => { setSelectTDTM(swiper.realIndex) }}
                                    breakpoints={{
                                        320: { slidesPerView: 3 },
                                        768: { slidesPerView: 4 },
                                        1024: { slidesPerView: 6 }
                                    }}>
                                    {product.images.map((img, i) =>
                                        <SwiperSlide>
                                            <div className={styles.thumbnail} key={i} onClick={() => setZoomImage(i)}>
                                                <img src={`./images/irl3/${img}`}></img>
                                            </div>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </div>
                        }
                    </div>

                    <div className={styles.right}>
                        <div className={styles.productName}>{product.name}</div>
                        <div className={styles.productPrice}>Price: {product.price} xtz</div>

                        <div className={styles.productDesc}>{product.desc && product.desc.split('\n').map((p) => <p>{p}</p>)}</div>
                        {productIndex === 0 && <img class={styles.sizeChart} src={sizeShirt} alt='' />}
                        {product.options && <div className={styles.productOptions}>
                            {/* <p>Size</p> */}
                            <p>Version</p>
                            {product.options.map((option, i) =>
                                <button key={i} className={selectOption === i ? styles.selected : ''}
                                    onClick={() => {
                                        setSelectOption(i)
                                        setZoomImage(i)
                                    }}>{option}</button>
                            )}
                        </div>}

                        <div className={styles.productQuantity}>
                            <p>Quantity</p>
                            <button className={styles.inputButton}
                                onClick={() => parseInt(quantity) > 1 ? setQuantity(parseInt(quantity) - 1) : null}
                                disabled={parseInt(quantity) <= 0}>
                                <MinusIcon />
                            </button>
                            <input value={quantity} type='number' min={1}
                                onChange={(e) => { if (e.target.value > 0) setQuantity(e.target.value) }} />
                            <button className={styles.inputButton}
                                onClick={() => setQuantity(parseInt(quantity) + 1)}>
                                <AddIcon />
                            </button>
                        </div>
                    </div>

                </div>

                <button className={styles.bottomButton} onClick={() => addItem()} disabled={
                    collection.length === 0 ||
                    (quantity + (typeQuantity[productIndex] ? typeQuantity[productIndex] : 0) > product.limit) ||
                    (product.name.includes('Customized') && selectTDTM === null) ||
                    (product.options && selectOption === null)
                }>Add to Cart</button>
                <div className={styles.warningMessage}>
                    {collection.length === 0 ?
                        `TDTM needed.`
                        : (quantity + (typeQuantity[productIndex] ? typeQuantity[productIndex] : 0) > product.limit) ?
                            `Maximum ${product.limit} ${product.name}s per Order.`
                            : product.name.includes('Customized') && selectTDTM === null ?
                                `Please select an TDTM. `
                                : (product.options && selectOption === null) &&
                                `Please select an option. `}
                </div>
            </>
        )
    }

    // cart
    const cartList = (edit) => (
        <div className={styles.cartList + ' ' + styles.left}>
            <div>
                <h5 className={styles.cartTitle}>Order information</h5>
                {cart.map((item, i) => (
                    <div className={styles.cartItem} key={i}>
                        {edit && <RemoveButton onClick={() => removeItem(i)} />}
                        <div>
                            {`${products[item.type].name} *${item.quantity}`}
                            {item.option !== undefined && `, ${products[item.type].options[item.option]}`}
                            {item.arrIndex !== undefined && `, ${collection.concat(tdgCollection)[item.arrIndex].name.replace('Tez Dozen ', '')}`}
                        </div>
                        <div className={styles.itemPrice}>{item.quantity * products[item.type].price} xtz</div>
                    </div>
                ))}
                {/* {!edit && tdgCollection.length > 0 && <div className={styles.cartItem}>(Free gifts - TD mask *{totalQuantity} set)</div>}
                {!edit && <div className={styles.cartItem}>(Free gifts - TD Stickers *1 set)</div>} */}

                {!edit && <div className={styles.cartItem}>(Free gifts - TD mask *{tdgCollection.length > 0 ? (totalQuantity * 2) : totalQuantity} set)</div>}

                <hr></hr>
                <div className={styles.subtotal}>Subtotal <div className={styles.itemPrice}>{subtotal} xtz</div></div>
                {!edit && <div className={styles.subtotal}>Shipping and handling fee <div className={styles.itemPrice}>{shippingFee[cart[0].type][countryData[country][2]]} xtz</div></div>}

            </div>
            <div className={styles.note}>{edit && `**Shipping and handling fee will be calculated based on your shipping address.`}</div>
            {!edit && <div className={styles.total}>Total<div className={styles.itemPrice}> {subtotal + shippingFee[cart[0].type][countryData[country][2]]} xtz</div></div>}
        </div >
    )

    // Step 5
    const shipping = () => (
        <>
            <h4 className={styles.stepTitle}>Step 3. Shipping Address</h4>
            <div className={styles.row + ' ' + styles.shipping}>
                {cartList(true)}

                <div className={styles.shippingForm + ' ' + styles.right}>
                    <label>Receiver's Full Name</label>
                    <input onChange={(e) => setRecipient(e.target.value)} value={recipient}></input>
                    <label>Address</label>
                    <select className={styles.select} onChange={e => setCountry(e.target.value)} defaultValue={country} >
                        {countryData && countryData.map((country, i) => {
                            return (<option value={i} key={i}>{country[1]}</option>)
                        })}
                    </select>
                    <input onChange={(e) => setAddr(e.target.value)} value={addr}></input>
                    <label>Postcode</label>
                    <input onChange={(e) => setPostCode(e.target.value)} value={postcode}></input>
                    <label>Mobile Number</label>
                    <input onChange={(e) => setPhone(e.target.value)} value={phone}></input>
                    <label>Email</label>
                    <input onChange={(e) => setEmail(e.target.value)} value={email}></input>
                </div>
            </div>
            <button className={styles.bottomButton} disabled={recipient === '' || addr === '' || postcode === '' || phone === '' || email === ''}
                onClick={() => setStep(6)}>Confirm</button>
            <div className={styles.warningMessage}>{(recipient === '' || addr === '' || postcode === '' || phone === '' || email === '') && `Please fill in all the information.`}</div>
        </>
    )

    // Step 6
    const confirmation = () => (
        <>
            <h4 className={styles.stepTitle}>Step 4. Confirmation</h4>
            <div className={styles.row + ' ' + styles.confirmation}>
                {cartList()}
                <div className={styles.shippingForm + ' ' + styles.right}>
                    <div className={styles.label}>Receiver's Full Name</div>
                    <div className={styles.value}>{recipient}</div>
                    <div className={styles.label}>Address</div>
                    <div className={styles.value}>[{countryData[country][0]}] {addr}</div>
                    <div className={styles.label}>Postcode</div>
                    <div className={styles.value}>{postcode}</div>
                    <div className={styles.label}>Mobile number</div>
                    <div className={styles.value}>{phone}</div>
                    <div className={styles.label}>Email</div>
                    <div className={styles.value}>{email}</div>
                    <div className={styles.confirmed}>
                        <input type='checkbox' value={confirmed} onChange={() => setConfirmed(!confirmed)} />
                        I have confirmed that the above shipping information is correct.
                    </div>
                </div>
            </div>
            <button className={styles.bottomButton} onClick={() => submitOrder()} disabled={!confirmed}>Check Out</button>
        </>
    )

    // Step 7
    const processing = () => (
        <div className={styles.container}>
            <div className={styles.loading}></div>
            <div>{resultMessage}</div>
        </div>
    )

    // Step 8
    const result = (success) => {
        function onContinue() {
            setStep(0)
        }
        function onFinish() {
            setIsOpened(false)
        }
        function onBack() {
            setStep(5)
        }
        return (
            <>
                {success && <h4 className={styles.stepTitle}>Step 5. Transaction completed</h4>}
                <div className={styles.container + ' ' + styles.result}>
                    <div className={styles.resultIcon}>{success ? <SuccessIcon /> : <ErrorIcon />}</div>
                    <div className={styles.resultMessage}>
                        {resultMessage.split('\n').map((p, i) => <p key={i}>{p}</p>)}
                    </div>
                    {success ? <button onClick={onContinue}>Continue</button>
                        : <button onClick={onBack}>Back</button>}
                    <button onClick={onFinish}>Finish</button>
                </div>
            </>
        )
    }

    return (
        <Modal setIsOpened={setIsOpened}>

            {step < 5 && <div className={styles.fullheight}>
                {step < 5 && <div className={styles.decoLeft}></div>}
                {step < 5 && <div className={styles.decoRight}></div>}
            </div>}

            <h3 className={styles.title}>
                <div className={styles.titledecoLeft}></div>
                IRL-Shop
                <div className={styles.titledecoRight}></div>
            </h3>

            {/* {(step > 1 && step < 5 && cart.length === 0) && <button className={styles.backButton} onClick={() => { setStep(1) }}>Back</button>} */}
            {(step === 1 || (step > 4 && step < 7)) && <button className={styles.backButton} onClick={
                (step === 5) ? () => { clearProductOption() }
                    : () => { setStep(step - 1); setConfirmed(false) }}>Back</button>}
            {step > 0 && step < 5 && cart.length > 0 && <button className={styles.cartButton} onClick={() => setStep(5)}><CartIcon /><div className={styles.totalQuantity}>{totalQuantity}</div></button>}

            {step === 0 && welcome()}
            {step === 1 && selectProduct()}
            {step > 1 && step < 5 && productDetail(step - 2)}
            {step === 5 && shipping()}
            {step === 6 && confirmation()}
            {step === 7 && processing()}
            {step === 8 && result(success)}

        </Modal>
    )
}

export default IRLModal