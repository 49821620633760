import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { SwapSection } from '../../components/dao-store'

export const DSSwap = ({ headerControl = () => null }) => {
    const header = (args) => headerControl(args)

    useEffect(() => {
        header({ fixed: true, showMenu: false })
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Page top>

                <Section full
                    id="Club" className={styles.clubSection}>
                        <Container smaller>
                        <SwapSection/></Container>
                </Section>

            </Page >
        </>
    )
}