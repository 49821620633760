import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { AuthButton, SyncButton } from '../../components/syncButton'

export const Auth = ({ match, headerControl = () => null }) => {
    const location = useLocation()
    const header = (args) => headerControl(args)
    useEffect(() => {
        header({ hide: true })
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getNonce = () => location.pathname.split('/dcauth/')[1]
    return (
        <>
            <Page top>
                <Section
                    id='Home' className={styles.homeSection}>

                    <div className={styles.full}>
                        <div className={styles.bg}></div>
                    </div>
                    <div className={styles.full}>
                        <div className={styles.butterfly}></div>
                        <div className={styles.bee}></div>
                    </div>
                    <div className={styles.full}>
                        <Container className={styles.content}>
                            <div className={styles.text}>
                                <img alt='Tez Dozen' className={styles.titleImage} src={'/images/title_2.png'} />
                                <h2>Link your Discord account and Tezos wallet</h2>
                                <p>Link your Tezos wallet now to enjoy TDTM holder privileges in Tez Dozen Discord Server! Make sure you complete the following three steps:</p>

                                <p className={styles.stepstyle}>STEP 1. Sync your wallet.</p>
                                <SyncButton feature='sync' className={styles.enrollButton} />
                                <p className={styles.stepstyle}>STEP 2. Sign 'TezDozen' with your wallet (No fee required).</p>
                                <AuthButton className={styles.enrollButton} nonce={getNonce()} />
                                <p className={styles.stepstyle}>STEP 3. Welcome to TDVerse, you can return to the Discord server and check your role.</p>
                            </div>
                        </Container>
                    </div>
                </Section>

            </Page>
        </>
    )
}