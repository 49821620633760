// ENV = dev / production
export const ENV = 'prod'
// export const VERSION = 'v1.3'

// DEV settings
// export const admin = 'tz1TZBh6WG9mPyaWjUFe9UGtrWjCjQ2AjcG2'
// export const networkType = 'custom'
// export const nodeUrl = 'https://dev.akaswap.com:8080/tezos'
// export const crowdSaleAddr = 'KT1U8zZhsTBRJhGHCptf9pnZ9HuqJMiUHAcH'

// TESTNET settings
// export const admin = 'tz1NgXNo9K46BAsgFP829ZRXCkXmc7XwS5TR'
// export const networkType = 'granadanet'
// export const nodeUrl = 'https://granadanet.api.tez.ie'
// export const apiUrl = 'https://api.granadanet.tzkt.io'
// export const crowdSaleAddr = 'KT1PRJvNnVkvMUJ75ZB7rFMgZv4bFMoByH1E'

// MAINNET settings
export const admin = 'tz1hmpmvpEzrdcvYjunNEGGEtSQgSEwt39ge'
export const networkType = 'mainnet'
export const apiUrl = 'https://api.tzkt.io'
export const tzktUrl = 'https://tzkt.io/'
export const nodeUrl = 'https://mainnet.api.tez.ie'
// export const nodeUrl = 'https://mainnet.smartpy.io'

export const crowdSaleAddr = 'KT1QNqwxNfymBoW6Ua5yXrrvxsC1n1dQsuyB'
export const dcAuthApi = 'https://tools.tezdozen.xyz/dcrolebot/auth/'

// Dozenverse setting
export const doorList = ['ar', 'irl', 'atm', 'ds', 're', 'bg']
export const voucherList = []
export const akaswapApi = 'https://api.akaswap.com/v2'
export const fa2 = 'KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax'
export const tgfa2 = 'KT1ShjqosdcqJBhaabPvkCwoXtS1R2dEbx4W'
export const voucherFA2 = 'KT1AFq5XorPduoYyWxs5gEyrFK6fVjJVbtCj'

// IRL setting
export const irlOpen = true
export const irlAddr = 'KT1PyWaSWkupZv4xf66bQE8mBgcEXrX1FCuZ'
export const irl2Addr = 'KT1Vvaz67RfD2k7ELUo7psQ6MPZk3tuNZMt5'
export const irl3Addr = 'KT1E4uH9SwhonqX8WfXoczyfhoAR1unwArwd'
export const irlAPI = 'https://tools.tezdozen.xyz/irl'
export const irlVoucherTokenID = 2345

// ATM setting
export const atmContract = 'KT1AXScSrpWLMud1hGN7BcsjDqPgXzAwCxo1'

// BG setting
export const bgOpen = false
export const bgContract = 'KT1T45ktthVwgVE3uMoxLsuh91c3JsdiB58J'
export const bgVoucherTokenID = 3186

// GG setting
export const ggOpen = false
export const meowContract = 'KT1E9krXSKTLG4XGgtqNMTGwfjhHtdcZDuNr'
export const meowVoucherTokenID = 4140
export const growlContract = 'KT1FfrLnitubaST7u2937H2YRG4YgHLs9bXr'
export const growlVoucherTokenID = 6370
export const roarContract = 'KT1XoTvh6PWkYogfzB2vKp3n235uJn7ywBG1'
export const roarVoucherTokenID = 9542

// Auction setting
export const auctionStart = '2022-08-23T23:00:00+08:00'
export const auctionEnd = '2022-08-24T00:05:00+08:00'
export const auctionContract = 'KT1M5eSwWqYGGUEdH3iyCQbNKB9GNpaxcvXb'

// DAO store setting
export const daoStoreOpen = true
export const storeContract = 'KT1LH3AnSgjJnKnugDNtb6kF1W1ZTaseG1Qq'
export const objFA2 = 'KT1AFq5XorPduoYyWxs5gEyrFK6fVjJVbtCj'
export const daofa2 = 'KT1AM3PV1cwmGRw28DVTgsjjsjHvmL6z4rGh'
// export const storeContract = 'KT1Axh7BAaCG46o84NR7uTtDtjDtNeWUx852' // old contract
export const whiteListBigMap = 237174 // new contract
export const englishAuctionsBigMap = 237171
export const dutchAuctionsBigMap = 237170
export const swapItemsBigMap = 237173
export const collectedBigMap = 237169

// Raffle setting
export const raffleOpen = false
export const raffleContract = 'KT1CGDaktf7aYWyqGhgzUXE4be2epPKhRvsj'
export const raffleTicketTokenID = 7909
export const rafflesBigMap = 179383

// Showroom setting
export const akaverseUrl = 'https://akaverse.app'
export const room1VoucherTokenID = 10827
export const room2VoucherTokenID = 10828
export const room1Redeem = 'KT1AHvL8waeZWFddCdgbdB4F2BnxvTJ4qouS'
export const room2Redeem = 'KT1NrmfXkg3dLV8SnmgjKv6AUbStAXc6VKaM'

export const GOOGLE_ANALYTICS_ID='G-3RTMTHBL3Q'
