import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { TezDozenContext } from "../../context/TezDozenContext"
import { ReactComponent as ARicon } from './images/scanAR.svg'
import { ReactComponent as QRicon } from './images/scanQR.svg'
import { ReactComponent as Arrow } from './images/arrow.svg'
import ARQRcode from './images/ARQRcode.png'
import Step1 from './images/step1.png'
import Step2 from './images/step2.png'
import Step3 from './images/step3.png'
import { tzktUrl, akaverseUrl, room1VoucherTokenID, room2VoucherTokenID, room1Redeem, room2Redeem } from '../../context/constant';
import { Modal } from '../modal';
import { useEffect } from 'react'

export const TDTMCard = ({ id, className, onClick = () => null }) => {
    return (
        <div className={styles.TDTMCard + ' ' + (className ? className : '')} onClick={onClick} >
            <img alt={id} src={`./images/ar-tdtm/${id}.png`}></img>
            <div className={styles.image}>
            </div>
        </div>
    )
}

const ARModal = ({ setIsOpened = () => null }) => {

    const [page, setPage] = useState(0) // 0: instruction, 1: qr codes
    const [selectTDTM, setSelectTDTM] = useState(0)

    const tdtm = [
        69,
        234,
        321,
        383,
        577,
        839,
        1118,
        1289,
        1833,
        2053,
        2407,
        3160
    ]

    const igLink = [
        "https://www.instagram.com/ar/238144112366764/",
        "https://www.instagram.com/ar/1141202900604156/",
        "https://www.instagram.com/ar/676516717648762/",
        "https://www.instagram.com/ar/651144347075628/",
        "https://www.instagram.com/ar/1327436854832099/",
        "https://www.instagram.com/ar/1827033884364640/",
        "https://www.instagram.com/ar/773996487793647/",
        "https://www.instagram.com/ar/6376026999148790/",
        "https://www.instagram.com/ar/746221420589307/",
        "https://www.instagram.com/ar/746221420589307/",
        "https://www.instagram.com/ar/6580997298617303/",
        "https://www.instagram.com/ar/675085897771508/"
    ]


    useEffect(() => {
    }, [])


    return (
        <Modal setIsOpened={setIsOpened}>

            <h3 className={styles.title}>
                <div className={styles.titledecoLeft}></div>
                AR-Workshop
                <div className={styles.titledecoRight}></div>
            </h3>

            {page == 0 && <div className={styles.row}>
                <div className={styles.steps}>
                    <h3>Step 1.</h3>
                    <p>Scan the QR code (or click the link) to activate the TDTM-AR effect.</p>
                    <img alt="" srcset="" src={Step1}></img>
                </div>
                <div className={styles.Arrow}><Arrow /></div>
                <div className={styles.steps}>
                    <h3>Step 2.</h3>
                    <p>Capture your portrait using the front camera to generate your personal TDTM-augmented PFP.</p>
                    <img alt="" srcset="" src={Step2}></img>
                </div>
                <div className={styles.Arrow}><Arrow /></div>
                <div className={styles.steps}>
                    <h3>Step 3.</h3>
                    <p>Use the rear camera to scan the TDTM and interact with the 3D augmented TDTM animation. Click to hear the TDTM sounds.</p>
                    <img alt="" srcset="" src={Step3}></img>
                </div>
                <button className={styles.bottomButton} onClick={() => setPage(1)}>Start</button>
            </div>}

            {
                page == 1 && <div className={styles.row}>
                    <h3>
                        Select the TDTM you want to interact
                    </h3>
                    <div className={styles.TDTMList}>
                        {tdtm.map((tdtm, i) =>
                            <TDTMCard className={i === selectTDTM ? styles.selected : ''} id={tdtm} onClick={() => setSelectTDTM(i)} />
                        )}
                    </div>
                    <div className={styles.left}>
                        <div className={styles.imageZoom}>
                            <img src={`./images/ar-tdtm/${tdtm[selectTDTM]}.png`}></img>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.qrcode}>
                            <a href={igLink[selectTDTM]} target="_blank">
                                <img src={`./images/ar-tdtm/qr_ig_${selectTDTM + 1}.png`} alt="" srcset="" />
                                <br />
                                Scan me
                            </a>
                        </div>
                        {/* <div className={styles.qrcode}>
                            <a href={fblink[selectTDTM]} target="_blank">
                                <img src={`./images/ar-tdtm/qr_fb_${selectTDTM + 1}.png`} alt="" srcset="" />
                                <br />
                                Facebook
                            </a>
                        </div> */}
                    </div>

                    <button className={styles.bottomButton} onClick={() => setPage(0)}>Instruction</button>

                </div>
            }

        </Modal >
    )
}

export default ARModal