export const products = [
    {
        name: 'Customized TD-Shirt',
        options: ['150', '160', 'S', 'M', 'L', 'XL', 'XXL'],
        images: ['shirt_final_3.png'],
        price: 16,
        limit: 10,
        ended: true,
        desc:
            `The must-have prop for every member.
            Materials: Cotton 100%.
            The picture on the website is for reference only.
            Please prevail in kind.            
            Maximum 10 TD-shirts per order.
            Size Chart:`
    },
    {
        name: 'Customized TD-Shot',
        images: ['shot1.png'],
        price: 32,
        limit: 3,
        ended: true,
        desc:
            `This portrait is open-editioned and comes with a steel stamp to show your TDTM membership of a specific instant.
            The picture on the website is for reference only.
            Please prevail in kind.    
            Maximum 3 TD-shots per order.
            Size: 25 x 25 x 8(cm)`
    },
    {
        name: 'TD-Bag',
        options: ['Collection ver.', 'Community ver.'],
        images: ['bag1_final.png', 'bag2_final.png'],
        price: 15,
        limit: 5,
        desc:
            `size: 37(55)*24 cm  (H (Include handle) xW) 
            material: Canvas 8oz, 100% Cotton
            The image on the website is for reference only.
            Please prevail in kind.
            Maximum 5 TD-bags per order.`
    }
]
export const shippingFee = [[3, 40, 80], [3, 80, 160], [3, 40, 80]]

export const countryData = [
    ["AF", "Afghanistan", 1],
    ["AL", "Albania", 2],
    ["DZ", "Algeria", 2],
    ["AS", "American Samoa", 2],
    ["AD", "Andorra", 2],
    ["AO", "Angola", 2],
    ["AI", "Anguilla", 2],
    ["AG", "Antigua", 2],
    ["AR", "Argentina", 2],
    ["AM", "Armenia", 1],
    ["AW", "Aruba", 2],
    ["AU", "Australia", 2],
    ["AT", "Austria", 2],
    ["AZ", "Azerbaijan", 1],
    ["BS", "Bahamas", 2],
    ["BH", "Bahrain", 1],
    ["BD", "Bangladesh", 1],
    ["BB", "Barbados", 2],
    ["BY", "Belarus", 2],
    ["BE", "Belgium", 2],
    ["BZ", "Belize", 2],
    ["BJ", "Benin", 2],
    ["BM", "Bermuda", 2],
    ["BT", "Bhutan", 1],
    ["BO", "Bolivia", 2],
    ["XB", "Bonaire", 2],
    ["BA", "Bosnia And Herzegovina", 2],
    ["BW", "Botswana", 2],
    ["BR", "Brazil", 2],
    ["BN", "Brunei", 1],
    ["BG", "Bulgaria", 2],
    ["BF", "Burkina Faso", 2],
    ["BI", "Burundi", 2],
    ["KH", "Cambodia", 1],
    ["CM", "Cameroon", 2],
    ["CA", "Canada", 2],
    ["IC", "Canary Islands, The", 2],
    ["CV", "Cape Verde", 2],
    ["KY", "Cayman Islands", 2],
    ["CF", "Central African Republic", 2],
    ["TD", "Chad", 2],
    ["CL", "Chile", 2],
    ["CN", "China, Peoples Republic", 2],
    ["CO", "Colombia", 2],
    ["MP", "Commonwealth No. Mariana Islands", 2],
    ["KM", "Comoros", 2],
    ["CG", "Congo", 2],
    ["CD", "Congo, The Democratic Republic Of", 2],
    ["CK", "Cook Islands", 2],
    ["CR", "Costa Rica", 2],
    ["CI", "Cote D Ivoire", 2],
    ["HR", "Croatia", 2],
    ["CU", "Cuba", 2],
    ["XC", "Curacao", 2],
    ["CY", "Cyprus", 1],
    ["CZ", "Czech Republic, The", 2],
    ["DK", "Denmark", 2],
    ["DJ", "Djibouti", 2],
    ["DM", "Dominica", 2],
    ["DO", "Dominican Republic", 2],
    ["TP", "East Timor", 1],
    ["EC", "Ecuador", 2],
    ["EG", "Egypt", 1],
    ["SV", "El Salvador", 2],
    ["ER", "Eritrea", 2],
    ["EE", "Estonia", 2],
    ["ET", "Ethiopia", 2],
    ["FK", "Falkland Islands", 2],
    ["FO", "Faroe Islands", 2],
    ["FJ", "Fiji", 2],
    ["FI", "Finland", 2],
    ["FR", "France", 2],
    ["FR", "France", 2],
    ["FR", "France", 2],
    ["GF", "French Guyana", 2],
    ["GA", "Gabon", 2],
    ["GM", "Gambia", 2],
    ["GE", "Georgia", 1],
    ["DE", "Germany", 2],
    ["DE", "Germany", 2],
    ["DE", "Germany", 2],
    ["DE", "Germany", 2],
    ["GH", "Ghana", 2],
    ["GI", "Gibraltar", 2],
    ["GR", "Greece", 2],
    ["GL", "Greenland", 2],
    ["GD", "Grenada", 2],
    ["GP", "Guadeloupe", 2],
    ["GU", "Guam", 2],
    ["GT", "Guatemala", 2],
    ["GG", "Guernsey", 2],
    ["GN", "Guinea Republic", 2],
    ["GW", "Guinea-Bissau", 2],
    ["GQ", "Guinea-Equatorial", 2],
    ["GY", "Guyana (British)", 2],
    ["HT", "Haiti", 2],
    ["HN", "Honduras", 2],
    ["HK", "Hong Kong", 1],
    ["HU", "Hungary", 2],
    ["IS", "Iceland", 2],
    ["IN", "India", 1],
    ["ID", "Indonesia", 1],
    ["IR", "Iran (Islamic Republic Of)", 1],
    ["IQ", "Iraq", 1],
    ["IE", "Ireland, Republic Of", 2],
    ["IL", "Israel", 1],
    ["IT", "Italy", 2],
    ["JM", "Jamaica", 2],
    ["JP", "Japan", 1],
    ["JE", "Jersey", 2],
    ["JO", "Jordan", 1],
    ["KZ", "Kazakhstan", 1],
    ["KE", "Kenya", 2],
    ["KI", "Kiribati", 2],
    ["KR", "Korea, Republic Of (South K.)", 1],
    ["KP", "Korea, The D.P.R Of (North K.)", 1],
    ["KV", "Kosovo", 2],
    ["KW", "Kuwait", 1],
    ["KG", "Kyrgyzstan", 1],
    ["LA", "Lao Peoples Democratic Republic", 1],
    ["LV", "Latvia", 2],
    ["LB", "Lebanon", 1],
    ["LS", "Lesotho", 2],
    ["LR", "Liberia", 2],
    ["LY", "Libya", 2],
    ["LT", "Lithuania", 2],
    ["LU", "Luxembourg", 2],
    ["MO", "Macau", 2],
    ["MK", "Macedonia, Republic Of", 2],
    ["MG", "Madagascar", 2],
    ["MW", "Malawi", 2],
    ["MY", "Malaysia", 1],
    ["MV", "Maldives", 1],
    ["ML", "Mali", 2],
    ["MT", "Malta", 2],
    ["MH", "Marshall Islands", 2],
    ["MQ", "Martinique", 2],
    ["MR", "Mauritania", 2],
    ["MU", "Mauritius", 2],
    ["YT", "Mayotte", 2],
    ["MX", "Mexico", 2],
    ["FM", "Micronesia, Federated States Of", 2],
    ["MD", "Moldova, Republic Of", 2],
    ["MN", "Mongolia", 1],
    ["ME", "Montenegro, Republic Of", 2],
    ["MS", "Montserrat", 2],
    ["MA", "Morocco", 2],
    ["MZ", "Mozambique", 2],
    ["MM", "Myanmar", 1],
    ["NA", "Namibia", 2],
    ["NR", "Nauru, Republic Of", 2],
    ["NP", "Nepal", 1],
    ["NL", "Netherlands, The", 2],
    ["XN", "Nevis", 2],
    ["NC", "New Caledonia", 2],
    ["NZ", "New Zealand", 2],
    ["NI", "Nicaragua", 2],
    ["NE", "Niger", 2],
    ["NG", "Nigeria", 2],
    ["NU", "Niue", 2],
    ["NO", "Norway", 2],
    ["OM", "Oman", 1],
    ["PK", "Pakistan", 1],
    ["PW", "Palau", 2],
    ["DHL沒有列", "Palestine", 1],
    ["PA", "Panama", 2],
    ["PG", "Papua New Guinea", 2],
    ["PY", "Paraguay", 2],
    ["PE", "Peru", 2],
    ["PH", "Philippines, The", 1],
    ["PL", "Poland", 2],
    ["PT", "Portugal", 2],
    ["PR", "Puerto Rico", 2],
    ["QA", "Qatar", 1],
    ["RE", "Reunion, Island Of", 2],
    ["RO", "Romania", 2],
    ["RU", "Russian Federation, The", 2],
    ["RW", "Rwanda", 2],
    ["WS", "Samoa", 2],
    ["SM", "San Marino", 2],
    ["ST", "Sao Tome And Principe", 2],
    ["SA", "Saudi Arabia", 1],
    ["SN", "Senegal", 2],
    ["RS", "Serbia, Republic Of", 2],
    ["SC", "Seychelles", 2],
    ["SL", "Sierra Leone", 2],
    ["SG", "Singapore", 1],
    ["SK", "Slovakia", 2],
    ["SI", "Slovenia", 2],
    ["SB", "Solomon Islands", 2],
    ["SO", "Somalia", 2],
    ["XS", "Somaliland, Rep Of (North Somalia)", 2],
    ["ZA", "South Africa", 2],
    ["ES", "Spain", 2],
    ["LK", "Sri Lanka", 1],
    ["XY", "St. Barthelemy", 2],
    ["XE", "St. Eustatius", 2],
    ["KN", "St. Kitts", 2],
    ["LC", "St. Lucia", 2],
    ["XM", "St. Maarten", 2],
    ["VC", "St. Vincent", 2],
    ["SD", "Sudan", 2],
    ["SR", "Suriname", 2],
    ["SZ", "Swaziland", 2],
    ["SE", "Sweden", 2],
    ["CH", "Switzerland", 2],
    ["SY", "Syria", 1],
    ["PF", "Tahiti", 2],
    ["TW", "Taiwan", 0],
    ["TJ", "Tajikistan", 1],
    ["TZ", "Tanzania", 2],
    ["TH", "Thailand", 1],
    ["TG", "Togo", 2],
    ["TO", "Tonga", 2],
    ["TT", "Trinidad And Tobago", 2],
    ["TN", "Tunisia", 2],
    ["TR", "Turkey", 1],
    ["TM", "Turkmenistan", 1],
    ["TC", "Turks And Caicos Islands", 2],
    ["TV", "Tuvalu", 2],
    ["UG", "Uganda", 2],
    ["UA", "Ukraine", 2],
    ["AE", "United Arab Emirates", 1],
    ["US", "United States Of America", 2],
    ["UY", "Uruguay", 2],
    ["UZ", "Uzbekistan", 1],
    ["VU", "Vanuatu", 2],
    ["VE", "Venezuela", 2],
    ["VN", "Vietnam", 1],
    ["VG", "Virgin Islands (British)", 2],
    ["VI", "Virgin Islands (Us)", 2],
    ["YE", "Yemen, Republic Of", 1],
    ["ZM", "Zambia", 2],
    ["ZW", "Zimbabwe", 2]
]

