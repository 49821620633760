import React, { useContext, useEffect, useState } from 'react'
import "swiper/swiper.scss";
import styles from './styles.module.scss'
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper.scss";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { ReactComponent as TickIcon } from './images/tick.svg'
import { ReactComponent as SuccessIcon } from './images/success.svg'
import { ReactComponent as ErrorIcon } from './images/error.svg'
import { ReactComponent as CloseButton } from './images/close_button.svg'
import { TezDozenContext } from '../../context/TezDozenContext';
import { roarContract, tzktUrl } from '../../context/constant';

SwiperCore.use([Navigation]);

export const GgModal = ({ tgCollection, collection, growlVoucherAmount, setIsOpened = () => null, updateVoucher = () => null }) => {
  const [step, setStep] = useState(0)
  const [success, setSuccess] = useState(false)
  const [resultMessage, setResultMessage] = useState('Connecting wallet...')
  const [tdIds, setTdIds] = useState(Array(12).fill(0))
  const [tdBools, setTdBools] = useState(Array(12).fill(0))
  const [tgBools, setTgBools] = useState(Array(2).fill(0))
  const [tgId, settgId] = useState(0)
  const [hasCombo, setHasCombo] = useState(false)
  const context = useContext(TezDozenContext)

  useEffect(() => {

    // check if has tdg

    var arrBool = Array(2).fill(0)
    var tgCombo = 0
    for (let i = 0; i < tgCollection.length; i++) {
      if (tgCollection[i].tokenId < 400) { // if has meow
        arrBool[0] = tgCollection[i].tokenId
        tgCombo = 1
        break
      }
    }
    for (let i = 0; i < tgCollection.length; i++) {
      if (tgCollection[i].tokenId >= 400 && tgCollection[i].tokenId < 603) { // if has growl
        arrBool[1] = tgCollection[i].tokenId
        tgCombo = 2
        break
      }
    }
    setTgBools(arrBool)
    settgId(tgCombo == 2 ? arrBool[1] : arrBool[0])

    // check if has tdtm
    arrBool = Array(12).fill(0)
    var arrId = Array(12).fill(0)
    var combo = 0
    for (let index = 0; index < collection.length; index++) {
      const token = collection[index];
      var team
      if (token.tokenId <= 120) {
        team = 0
      } else if (token.tokenId >= 121 && token.tokenId <= 240) {
        team = 1
      } else if (token.tokenId >= 241 && token.tokenId <= 360) {
        team = 2
      } else if (token.tokenId >= 361 && token.tokenId <= 480) {
        team = 3
      } else if (token.tokenId >= 481 && token.tokenId <= 720) {
        team = 4
      } else if (token.tokenId >= 721 && token.tokenId <= 960) {
        team = 5
      } else if (token.tokenId >= 961 && token.tokenId <= 1200) {
        team = 6
      } else if (token.tokenId >= 1201 && token.tokenId <= 1440) {
        team = 7
      } else if (token.tokenId >= 1441 && token.tokenId <= 1920) {
        team = 8
      } else if (token.tokenId >= 1921 && token.tokenId <= 2400) {
        team = 9
      } else if (token.tokenId >= 2401 && token.tokenId <= 2880) {
        team = 10
      } else if (token.tokenId >= 2881 && token.tokenId <= 3360) {
        team = 11
      }

      arrBool[team] = token.tokenId
    }

    for (let team = 0; team < 12; team++) {
      if (arrBool[team] !== 0) {
        combo++
        arrId[team] = arrBool[team]

        if (combo + tgCombo >= 12) {
          setHasCombo(true)
          break
        }
      }
    }

    setTdIds(arrId)
    setTdBools(arrBool)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // STEP 1: Welcome
  const welcome = () => {

    const link = (opHash, message) => (
      <>{message}<br></br>
        {opHash && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={tzktUrl + opHash}>View on tzkt.io →</a>}
      </>
    )

    function onRedeem() {
      // call beacon here
      console.log(tdIds)
      console.log(tgId)
      var send = context.roarRedeem
      setResultMessage('Connecting your wallet...')
      setStep(1)
      send(
        roarContract,
        tgId,
        tdIds,
        (opHash) => {
          growlVoucherAmount = 0
          setResultMessage(link(opHash, 'Processing your transaction...'))
        },
        (opHash) => {
          setStep(2)
          setSuccess(true)
          updateVoucher()
          setResultMessage(link(opHash, 'Redeemed successfully'))
        },
        (opHash) => {
          setStep(2)
          setResultMessage(link(opHash, `Failed to redeem`))
        }
      ).then((e) => {
        if (e) {
          setResultMessage(e.description)
          setStep(2)
        } else {
          // setResultMessage('Transaction failed!')
        }
      })
      // setStep(2)
      // setSuccess(true)
      // updateVoucher()
      // setResultMessage(link(1234, 'Redeemed sucessfully'))
    }

    return (
      <>
        <div className={styles.row}>
          <div className={styles.welcomeText}>
            <ol>
              <li>Tez Dozen Guardians (TDG) are companions of TDTM, which appear after all teams of Tez Dozen have been fully deployed.</li>
              <li>Guardian Garden allows you to redeem one Roar NFT, between 8/24 00:00 and 9/6 23:59 (UTC+8).</li>
              <li>Note: your wallet needs to have at least one TDTM in each of the twelve consecutive teams and one TDG's Roar-Voucher.
                TDG GrOwl can take place of any two TDTM and TDG Meow can take the place of any TDTM team, but only one TDG Meow or TDG GrOwl can be used in one wallet.</li>
            </ol>
          </div>
        </div>
        <div className={styles.teamsCheck}>
          <div className={growlVoucherAmount > 0 ? styles.hasVoucher : styles.noVoucher}>{growlVoucherAmount > 0 && <TickIcon />}</div>
          <div className={tdBools[0] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[0] !== 0 && <TickIcon />}<img alt='' src="./images/teams/alpha/ipfs.png" /></div>
          <div className={tdBools[1] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[1] !== 0 && <TickIcon />}<img alt='' src="./images/teams/beta/ipfs.png" /></div>
          <div className={tdBools[2] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[2] !== 0 && <TickIcon />}<img alt='' src="./images/teams/gamma/ipfs.png" /></div>
          <div className={tdBools[3] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[3] !== 0 && <TickIcon />}<img alt='' src="./images/teams/delta/ipfs.png" /></div>
          <div className={tdBools[4] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[4] !== 0 && <TickIcon />}<img alt='' src="./images/teams/epsilon/ipfs.png" /></div>
          <div className={tdBools[5] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[5] !== 0 && <TickIcon />}<img alt='' src="./images/teams/zeta/ipfs.png" /></div>
          <div className={tdBools[6] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[6] !== 0 && <TickIcon />}<img alt='' src="./images/teams/eta/ipfs.png" /></div>
          <div className={tdBools[7] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[7] !== 0 && <TickIcon />}<img alt='' src="./images/teams/theta/ipfs.png" /></div>
          <div className={tdBools[8] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[8] !== 0 && <TickIcon />}<img alt='' src="./images/teams/iota/ipfs.png" /></div>
          <div className={tdBools[9] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[9] !== 0 && <TickIcon />}<img alt='' src="./images/teams/kappa/ipfs.png" /></div>
          <div className={tdBools[10] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[10] !== 0 && <TickIcon />}<img alt='' src="./images/teams/lambda/ipfs.png" /></div>
          <div className={tdBools[11] > 0 ? styles.hasTeam : styles.noTeam}>{tdBools[11] !== 0 && <TickIcon />}<img alt='' src="./images/teams/mu/ipfs.png" /></div>
          <div className={tgBools[0] > 0 ? styles.hasTeam : styles.noTeam}>{tgBools[0] !== 0 && <TickIcon />}<img alt='' src="./images/teams/meow/ipfs.png" /></div>
          <div className={tgBools[1] > 0 ? styles.hasTeam : styles.noTeam}>{tgBools[1] !== 0 && <TickIcon />}<img alt='' src="./images/teams/growl/ipfs.png" /></div>
        </div>
        <div className={styles.redeemButton}>
          {growlVoucherAmount > 0 ?
            (hasCombo ? <button onClick={() => onRedeem()}>Redeem</button> :
              <button disabled>12 consecutive teams Needed</button>)
            : <button disabled>Roar-Voucher Needed</button>}
        </div>
      </>
    )
  }

  // STEP 2: 
  const processing = () => (
    <div className={styles.container}>
      <div className={styles.loading}></div>
      <div>{resultMessage}</div>
    </div>
  )

  // STEP 3
  const result = (success) => {
    function onFinish() {
      setIsOpened(false)
    }
    function onBack() {
      setStep(0)
    }
    return (
      <div className={styles.container + ' ' + styles.result}>
        <div className={styles.resultIcon}>{success ? <SuccessIcon /> : <ErrorIcon />}</div>
        <div className={styles.resultMessage}>{resultMessage}</div>
        {!success && <button onClick={onBack}>Back</button>}
        <button onClick={onFinish}>Finish</button>
      </div>
    )
  }

  return (
    <div className={styles.modalOverlay} onClick={(e) => { if (e.target === e.currentTarget) setIsOpened(false) }}>
      <div className={styles.ggModal}>
        <div className={styles.CloseButton} onClick={(e) => { setIsOpened(false) }}><CloseButton /></div>
        {step !== 1 && <div className={styles.decoLeft}></div>}
        {step !== 1 && <div className={styles.decoRight}></div>}
        <h3 className={styles.title}><div className={styles.titledecoLeft}></div>Guardian Garden<div className={styles.titledecoRight}></div></h3>
        {step === 0 && welcome(collection)}
        {step === 1 && processing()}
        {step === 2 && result(success)}
      </div>
    </div>
  )
}