import React, { useContext, useEffect, useState } from 'react'
import "swiper/swiper.scss";
import styles from './styles.module.scss'
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { ReactComponent as AkaDaoIcon } from './images/akaDao.svg'
import { ReactComponent as WalletIcon } from './images/wallet.svg'
import { ReactComponent as ArrowIcon } from './images/arrow.svg'
import { ReactComponent as SuccessIcon } from './images/success.svg'
import { ReactComponent as ErrorIcon } from './images/error.svg'
import { ReactComponent as CloseButton } from './images/close_button.svg'
import { TezDozenContext } from '../../context/TezDozenContext';
import { atmContract, tzktUrl, apiUrl } from '../../context/constant';
import atmData from '../../data/atmData.json'
import cardback from '../../data/CardBack.json'
import { getAkaDao } from '../../getAkaDao';

SwiperCore.use([Navigation]);

export const TDTMCard = ({ token, className, onClick = () => null }) => {
  // parse token info
  const name = token.name.split("Tez Dozen ")[1]
  const id = token.tokenId
  const displayUri = token.displayUri.split("ipfs://")[1]
  var attrs = {}
  token.attributes.forEach(({ name, value }) => { attrs[name] = value})

  return (
    <div className={styles.TDTMCard + ' ' + (className ? className : '')}>
      <div className={styles.clickable} onClick={() => onClick()}></div>
      <div className={styles.image}>
        <img alt={id} src={`https://assets.akaswap.com/ipfs/${displayUri}`}></img>
      </div>
      <div className={styles.title}>
        {name}
      </div>
    </div>
  )
}

export const AtmModal = ({ collection, setIsOpened = () => null, updateDao = () => null, walletBalance }) => {
  const decimalPlace = 1
  const [step, setStep] = useState(0)
  const [selectedCollection, setSelectedCollection] = useState(0) // collection Index
  const [cardBanksBalance, setCardBanksBalance] = useState(Array(collection.length).fill(0))
  // const [updateBalanceCB, setUpdateBalanceCB] = useState(null)
  const [action, setAction] = useState(0) // withdraw / deposit
  const [amount, setAmount] = useState(null)
  const [success, setSuccess] = useState(false)
  const [resultMessage, setResultMessage] = useState('Connecting wallet...')
  const context = useContext(TezDozenContext)
  const axios = require('axios')

  useEffect(() => {
    if (step === 0)
      setTDTMBalance()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function floor(value) {
    var pow = Math.pow(10, decimalPlace)
    return Math.floor(value * pow) / pow
  }
  async function setTDTMBalance() {
    // get atm data
    var atm = await axios.get(`${apiUrl}/v1/contracts/${atmContract}/storage`)
      .then((res) => { return res.data ? res.data : atmData })

    var temp = cardBanksBalance

    // update each collection
    collection.forEach((c, i) => {
      var tokenId = c.tokenId

      // get attrs(CardBank, Team) from akaswap token
      var attrs = {}
      c.attributes.forEach(({ name, value }) => { attrs[name] = value })

      // for hangzhounet (does not have additional attributes)
      if (!attrs.CardBank)
        attrs.CardBank = cardback[tokenId].isGoldBack ? 'sextuple' : 'regular'
      if (!attrs.Team)
        attrs.Team = 'iota'

      // get withdrawn and deposit history
      var bigmap_id = atm.TDTM_data
      axios.get(`${apiUrl}/v1/bigmaps/${bigmap_id}/keys/${tokenId}`)
        .then((res) => {

          if (res.data) // if has history
            temp[i] = floor(getAkaDao(atm, tokenId, res.data.value, attrs))

          else // if no history
            temp[i] = floor(getAkaDao(atm, tokenId, { withdrawn: '0', single_bonus: '0' }, attrs))

          setCardBanksBalance([...temp])
        })
    })
  }

  // STEP 0: select TDTM
  const selectTDTM = (collection) => {

    function handleTDTMChange(index) {
      setSelectedCollection(index)
    }
    function onNext(action) {
      // clearInterval(updateBalanceCB)
      setAction(action)
      setAmount('')
      setStep(1)
    }
    return (
      <>
        <h4 className={styles.stepTitle}>Step 1. Choose the TDTM.</h4>
        <div>
          <Swiper slidesPerView={3} spaceBetween={0} slidesPerGroup={1} pagination={{
            "clickable": true
          }} centeredSlides={true} navigation={true} className={styles.TDTMList}
            initialSlide={selectedCollection}
            preventClicks={false}
            preventClicksPropagation={false}
            slideToClickedSlide={true}
            onSlideChange={(swiper) => { handleTDTMChange(swiper.realIndex) }}
            breakpoints={{
              320: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 3
              }
            }}>
            {collection.map((tdtm, i) => (<SwiperSlide key={i}>
              <TDTMCard token={tdtm} />
              <div className={styles.amount}><AkaDaoIcon />{cardBanksBalance[i] && cardBanksBalance[i].toFixed(decimalPlace)}</div>
            </SwiperSlide>))}
          </Swiper></div>
        <div className={styles.control}>
          {/* <div className={styles.amount}><AkaDaoIcon />{cardBankBalance}</div> */}
          <button onClick={() => onNext('withdraw')}>WITHDRAW</button>
          <button onClick={() => onNext('deposit')}>DEPOSIT</button>
        </div>
      </>
    )
  }
  // STEP 2: 
  const enterAmount = (selectedTDTM, cardBankBalance, action, walletBalance) => {
    // wallet balance floored
    var max = (action === 'withdraw') ? cardBankBalance : walletBalance
    function onInputChange(e) {
      let n = e.target.value
      if (parseFloat(n) >= 0 && parseFloat(n) <= max) {
        setAmount(floor(n))
      } else if (parseFloat(n) > max) {
        setAmount(max)
      } else {
        setAmount('')
      }
    }
    function onNext() {
      setStep(2)
    }
    return (<>
      <h4 className={styles.stepTitle}>Step 2. {action === 'withdraw' ? 'Withdraw akaDAO from the TDTM.' : 'Deposit akaDAO to the TDTM.'}</h4>
      <div className={styles.form}>
        <div className={styles.left}>
          <TDTMCard token={selectedTDTM} />

        </div>
        <div className={styles.right}>
          <div className={styles.balance}>
            <div className={styles.label}>{selectedTDTM.name.replace('Tez Dozen ', '')} Cardbank</div>
            <div className={styles.amount}><AkaDaoIcon />{cardBankBalance.toFixed(decimalPlace)}</div>
          </div>
          <div className={styles.balance}>
            <div className={styles.label}>My Wallet Balance</div>
            <div className={styles.amount}><AkaDaoIcon />{walletBalance.toFixed(decimalPlace)}</div>
          </div>
          <div className={styles.input}>
            <label className={styles.label} htmlFor='amount'>Amount</label>
            <div>
              <input id='amount' placeholder={'Enter amount'} value={amount} max={cardBankBalance} onChange={(e) => onInputChange(e)} type='number'></input>
              <button className={styles.max} onClick={() => setAmount(max)}>Max</button>
              <div className={styles.warning}>You can enter a value between 0.1 ~ {max}, up to one decimal place.</div>
            </div>
          </div>
          {/* <div className={styles.note}>
            <p className={styles.label}>Note: </p>
            <p>ooxxxxx</p>
          </div> */}
          <div className={styles.submit}>
            <button disabled={!(amount > 0)} onClick={() => onNext()}>{action}</button>
          </div>
        </div>
      </div>
    </>)
  }

  // STEP 3: Confirm
  const confirm = (selectedTDTM, cardBankBalance, action, walletBalance, amount) => {
    // wallet balance floored
    var deposit = (action === 'deposit' ? 1 : -1) * amount

    const link = (opHash, message) => (
      <>{message}<br></br>
        {opHash && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={tzktUrl + opHash}>View on tzkt.io →</a>}
      </>
    )

    function onConfirm() {
      var send = (action === 'withdraw') ? context.akaDaoWithdraw : context.akaDaoDeposit
      setResultMessage('Connecting your wallet...')
      setStep(3)
      send(
        atmContract,
        selectedTDTM.tokenId,
        amount,
        (opHash) => {
          setResultMessage(link(opHash, 'Processing your transaction...'))
        },
        (opHash) => {
          setStep(4)
          setSuccess(true)
          setResultMessage(link(opHash, `${action.charAt(0).toUpperCase() + action.slice(1)} successfully!`))
          updateDao()
          // update cardbank
          var temp = [...cardBanksBalance]
          temp[selectedCollection] += deposit
          setCardBanksBalance(temp)
        },
        (opHash) => {
          setStep(4)
          setResultMessage(link(opHash, `${action.charAt(0).toUpperCase() + action.slice(1)} failed!`))
        }
      ).then((e) => {
        if (e) {
          setResultMessage(e.description)
          setStep(4)
        } else {
          // setResultMessage('Transaction failed!')
        }
      })
    }
    return (<>
      <h4 className={styles.stepTitle}>Step 3. Confirm transaction information.</h4>

      <div className={styles.graph}>

        <div className={styles.left}>
          <TDTMCard token={selectedTDTM} />
          <div className={styles.label}>
            <div>Before</div>
            <div>After</div>
          </div>
          <div className={styles.balance}>
            <div className={styles.amount + ' ' + styles.before}><AkaDaoIcon />{cardBankBalance.toFixed(decimalPlace)}</div>
            <div className={styles.amount}><AkaDaoIcon />{(cardBankBalance + deposit).toFixed(decimalPlace)}</div>
          </div>
        </div>

        <div className={styles.mid}>
          <div>
            <div className={styles.amount}><AkaDaoIcon />{amount.toFixed(decimalPlace)}</div>
            <div className={styles.arrow + ' ' + ((action === 'deposit') ? styles.flip : '')}><ArrowIcon /></div>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.wallet}>
            <div className={styles.walletBackground}>
              <WalletIcon />
            </div>
            <div className={styles.address}>{context.address.substring(0, 5) + '...' + context.address.substring(context.address.length - 5, context.address.length)}</div>
          </div>
          <div className={styles.label}>
            <div>Before</div>
            <div>After</div>
          </div>
          <div className={styles.balance}>
            <div className={styles.amount + ' ' + styles.before}><AkaDaoIcon />{walletBalance.toFixed(decimalPlace)}</div>
            <div className={styles.amount}><AkaDaoIcon />{(walletBalance - deposit).toFixed(decimalPlace)}</div>
          </div>
        </div>

      </div>
      <button className={styles.confirm} onClick={() => onConfirm()}>confirm</button>
    </>)
  }

  // STEP 4
  const processing = () => (
    <div className={styles.container}>
      <div className={styles.loading}></div>
      <div>{resultMessage}</div>
    </div>
  )

  // STEP 5
  const result = (action, success) => {
    function onContinue() {
      setStep(0)
    }
    function onFinish() {
      setIsOpened(false)
    }
    function onBack() {
      setStep(2)
    }
    return (
      <div className={styles.container + ' ' + styles.result}>
        <div className={styles.resultIcon}>{success ? <SuccessIcon /> : <ErrorIcon />}</div>
        <div className={styles.resultMessage}>{resultMessage}</div>
        {success ? <button onClick={onContinue}>Continue</button>
          : <button onClick={onBack}>Back</button>}
        <button onClick={onFinish}>Finish</button>
      </div>
    )
  }


  function onBack() {
    setStep(step - 1)
  }
  return (
    <div className={styles.modalOverlay} onClick={(e) => { if (e.target === e.currentTarget) setIsOpened(false) }}>
      <div className={styles.atmModal}>
        <div className={styles.CloseButton} onClick={(e) => { setIsOpened(false) }}><CloseButton /></div>
        <div className={styles.decoLeft}></div>
        <div className={styles.decoRight}></div>
        <h3 className={styles.title}><div className={styles.titledecoLeft}></div>akaDAO ATM<div className={styles.titledecoRight}></div></h3>
        {step === 0 && selectTDTM(collection)}
        {step === 1 && enterAmount(collection[selectedCollection], cardBanksBalance[selectedCollection], action, floor(walletBalance))}
        {step === 2 && confirm(collection[selectedCollection], cardBanksBalance[selectedCollection], action, floor(walletBalance), amount)}
        {step === 3 && processing()}
        {step === 4 && result(action, success)}
        {step > 0 && step < 3 && <button className={styles.navButton} onClick={() => onBack()}>Back</button>}
      </div>
    </div>
  )
}