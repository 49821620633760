import React from 'react'
import styles from './styles.module.scss'


export const Feature = ({ image, title, desc, hint, className }) => (
    <div className={styles.feature + ' ' + (className ? className : '')}>
        {image && <div className={styles.image}>
            <img alt={title} src={'./images/' + image}></img>
        </div>}
        <div className={styles.title}>
            {title}
        </div>
        <div className={styles.desc}>
            {desc.map((d, i) => <p key={i}>{d}</p>)}
            {hint && <p className={styles.hint}>{hint}</p>}
        </div>
    </div>
)

export const FeaturesContainer = ({ className, children }) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}