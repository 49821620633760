import React, { useContext, useState } from 'react'
import { Container, Page, Section } from '../../components/layout'
import { TezDozenContext } from '../../context/TezDozenContext'
import { admin } from '../../context/constant'
import { MichelsonMap } from '@taquito/taquito'

export const Reveal = () => {
    const context = useContext(TezDozenContext)
    const { addBlacklist, readySale, revealMetadata, upgradeMetadata, directUpdateMetadata, syncTaquito } = useContext(TezDozenContext)
    const [crowdsaleAddr, setCrowdsaleAddr] = useState()
    const [blackAddrs, setBlackAddrs] = useState()
    const [addresses, setAddresses] = useState()
    const [timestamp, setTimestamp] = useState()
    const [tokenMetadata, setTokenMetadata] = useState()

    const handleAddBlacklist = () => {
        if (context.acc?.address) {

            let updateAddrList = []
            let addrs = blackAddrs
            while (true) {
                let tzIdx = addrs.search("tz")
                if (tzIdx === -1)
                    break;
                if (addrs.length < 36)
                    break;
                let addr = addrs.substring(tzIdx, tzIdx + 36)
                updateAddrList.push({
                    add_address: addr
                })
                addrs = addrs.substring(tzIdx + 36)
            }

            addBlacklist(crowdsaleAddr, updateAddrList)
                .then((e) => {
                    console.log('update blacklist confirm', e)
                })
                .catch((e) => {
                    console.log(e)
                    alert('an error occurred')
                })
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    const handleReadySale = () => {
        if (context.acc?.address) {

            let addressList = []
            let addrs = addresses
            while (true) {
                let tzIdx = addrs.search("tz")
                if (tzIdx === -1)
                    break;
                if (addrs.length < 36)
                    break;
                let addr = addrs.substring(tzIdx, tzIdx + 36)
                addressList.push(addr)
                addrs = addrs.substring(tzIdx + 36)
            }

            readySale(crowdsaleAddr, addressList, parseInt(timestamp))
                .then((e) => {
                    console.log('ready sale confirm', e)
                })
                .catch((e) => {
                    console.log(e)
                    alert('an error occurred')
                })
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    const handleMetadata = (isReveal) => {
        if (context.acc?.address) {

            let tokenMetadataList = []
            let dict_data = JSON.parse(tokenMetadata)
            for (var key in dict_data) {
                let token_info = new MichelsonMap()

                token_info.set("", makeIPFSBytes(dict_data[key]))
                tokenMetadataList.push(
                    {
                        token_id: key,
                        token_info: token_info
                    }
                )
            }
            console.log(tokenMetadataList)
            if (isReveal) {
                revealMetadata(crowdsaleAddr, tokenMetadataList)
                    .then((e) => {
                        console.log('reveal metadata confirm', e)
                    })
                    .catch((e) => {
                        console.log(e)
                        alert('an error occurred')
                    })
            }
            else {
                upgradeMetadata(crowdsaleAddr, tokenMetadataList)
                    .then((e) => {
                        console.log('upgrade metadata confirm', e)
                    })
                    .catch((e) => {
                        console.log(e)
                        alert('an error occurred')
                    })
            }
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    const handleTDGMetadata = () => {
        if (context.acc?.address) {

            let tokenMetadataList = []
            let dict_data = JSON.parse(tokenMetadata)
            for (var key in dict_data) {
                let token_info = new MichelsonMap()

                token_info.set("", makeIPFSBytes(dict_data[key]))
                tokenMetadataList.push(
                    {
                        serial_id: key,
                        token_info: token_info
                    }
                )
            }
            console.log(tokenMetadataList)
            revealMetadata(crowdsaleAddr, tokenMetadataList)
                .then((e) => {
                    console.log('reveal metadata confirm', e)
                })
                .catch((e) => {
                    console.log(e)
                    alert('an error occurred')
                })
            
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    const handleDirectUpdateMetadata = () => {
        if (context.acc?.address) {

            let tokenMetadataList = []
            let dict_data = JSON.parse(tokenMetadata)
            for (var key in dict_data) {
                let token_info = new MichelsonMap()

                token_info.set("", makeIPFSBytes(dict_data[key]))
                tokenMetadataList.push(
                    {
                        token_id: parseInt(key),
                        token_info: token_info
                    }
                )
            }
            console.log(tokenMetadataList)
            directUpdateMetadata(crowdsaleAddr, tokenMetadataList)
                .then((e) => {
                    console.log('update metadata confirm')
                })
                .catch((e) => {
                    console.log(e)
                    alert('an error occurred')
                })
            
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    const makeIPFSBytes = (str) => {

        return (
            ('ipfs://' + str)
                .split('')
                .reduce(
                    (hex, c) =>
                        (hex += c.charCodeAt(0).toString(16).padStart(2, '0')),
                    ''
                )
        )
    }


    
    return (
        <Page>
            <Section>
                <Container>
                    {(context.acc?.address === admin) &&
                        <div>

                            <h1>Manager</h1>
                            Crowdsale Contract Address:<br />
                            <input
                                type="text"
                                onChange={(e) => setCrowdsaleAddr(e.target.value)}
                            ></input><br />
                            <h2>Blacklist</h2>
                            <textarea
                                placeholder="Blacklist addresses"
                                onChange={(e) => setBlackAddrs(e.target.value)}
                            ></textarea><br />
                            <button onClick={() => handleAddBlacklist()} >
                                Update Blacklist
                            </button>
                            <h2>Start Sale Batch</h2>
                            <textarea
                                placeholder="Early deployer addresses"
                                onChange={(e) => setAddresses(e.target.value)}
                            ></textarea><br />
                            FOMO start timestamp:<br />
                            <input
                                type="text"
                                onChange={(e) => setTimestamp(e.target.value)}
                            ></input><br />
                            <button onClick={() => handleReadySale()} >
                                Start Sale
                            </button >
                            <h2>Reveal / Upgrade Metadata</h2>
                            <textarea
                                placeholder="New Token Metadata (JSON format)"
                                onChange={(e) => setTokenMetadata(e.target.value)}
                            ></textarea><br />
                            <button onClick={() => handleMetadata(true)} >
                                Reveal Metadata
                            </button>
                            &emsp;
                            <button onClick={() => handleMetadata(false)} >
                                Upgrade Metadata
                            </button>
                            &emsp;
                            <button onClick={() => handleTDGMetadata()} >
                                Reveal TDG Metadata
                            </button>
                            &emsp;
                            <button onClick={() => handleDirectUpdateMetadata()} >
                                Direct Update Metadata
                            </button>
                        </div>
                    }
                </Container>
            </Section>
        </Page>
    )
}