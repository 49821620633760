import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Page, Section } from '../../components/layout'
import { DozenVerseSection } from '../../components/dozenVerse'

export const DozenVerse = ({ headerControl = () => null }) => {
    const header = (args) => headerControl(args)


    useEffect(() => {
        header({ fixed: true, showMenu: true })
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Page top>
                <Section
                    id="Club" className={styles.clubSection}>
                    <DozenVerseSection />
                </Section>

            </Page >
        </>
    )
}