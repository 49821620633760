import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { SyncButton } from '../../components/syncButton'
import { parseDateTime } from '../../datetime'
import { TezDozenContext } from '../../context/TezDozenContext'

export const Fomo_reg = ({ headerControl = () => null }) => {
    const header = (args) => headerControl(args)
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [fomoTeam, setFomoTeam] = useState(null)
    const [available, setAvailable] = useState(false)
    const context = useContext(TezDozenContext)
    useEffect(() => {
        header({ fixed: true, showMenu: false })
        if (fomoTeam === null && context && context.fomoTeam) {
            setFomoTeam(context.fomoTeam)
            if (parseDateTime(context.fomoTeam.fomoRegisterStart) < today)
                setAvailable(true)

            if (parseDateTime(context.fomoTeam.fomoRegisterEnd) < today)
                setAvailable(false)
        }
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const formatDate = (dateString) => {
        let date = parseDateTime(dateString)
        let hour = date.getUTCHours()+8
        if(hour >= 24)
        hour-=24
        return (`${date.getMonth() + 1}/${date.getDate()},
        ${(hour < 10) ? '0' : ''}${hour}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`)
    }
    return (
        <>
            <Page top>
                <Section
                    id='Home' className={styles.homeSection}>

                    <div className={styles.full} y={[-80, 80]}>
                        <div className={styles.bg}></div>
                    </div>
                    <div className={styles.full} y={[-30, 30]}>
                        {fomoTeam && <img alt='' className={styles.decoLeft} src={`./images/teams/${fomoTeam.imageFolder}/deco_left.png`} />}
                        {fomoTeam && <img alt='' className={styles.decoRight} src={`./images/teams/${fomoTeam.imageFolder}/deco_right.png`} />}
                    </div>
                    <div className={styles.full}>
                        <div className={styles.butterfly}></div>
                        <div className={styles.bee}></div>
                    </div>
                    <div y={[0, 0]}>
                        <Container className={styles.content}>
                            <div className={styles.text}>
                                <img alt='Tez Dozen' className={styles.titleImage} src={'./images/title_2.png'} />
                                <h2>FOMO Enrollment for {fomoTeam ? fomoTeam.team : ''}</h2>
                                <p className={styles.fomodate}>from {fomoTeam ? formatDate(fomoTeam.fomoRegisterStart) : ''} to {fomoTeam ? formatDate(fomoTeam.fomoRegisterEnd) : ''} (UTC+8).</p>
                                <ul className={styles.point}>
                                    <li> Those enrolled in the FOMO list can mint a limited number of NFTs (less than 20% of the total number of each team) at a discounted price of 10 xtz, <span className={styles.underline}>from {fomoTeam ? formatDate(fomoTeam.fomoMintStart) : ''} to {fomoTeam ? formatDate(fomoTeam.fomoMintEnd) : ''} (UTC+8).</span></li>
                                    <li> Each enrollment is limited to purchasing one NFT only. </li>
                                </ul>
                                <p>After enrollment, you will acquire the FOMO qualification for the limited purchase of NFTs before the public sale. If you want to enroll, make sure you complete the following two steps:</p>
                                {available ?
                                    <>
                                        <p className={styles.stepstyle}>STEP 1. Sync your wallet.</p>
                                        <SyncButton feature='sync' className={styles.enrollButton} />
                                        <p className={styles.stepstyle}>STEP 2. Enroll your wallet in the FOMO list.</p>
                                        <SyncButton feature='fomoReg' className={styles.enrollButton} />
                                    </>
                                    : <button className={styles.enrollButton} disabled>
                                        <span>Enrollment will start on at {fomoTeam ? formatDate(fomoTeam.fomoRegisterStart) : ''}(UTC+8)</span>
                                    </button>}
                                <p className={styles.hint}>Note</p>
                                <ul className={styles.hintpoint}>
                                    <li> This enrollment will be verified by a smart contract and hence will cost you a small amount of gas (estimated about 0.1 USD).  </li>
                                    <li> The FOMO enrollment for one team will not carry to the next team. </li>
                                </ul>
                            </div>
                        </Container>
                    </div>
                </Section>

            </Page>
        </>
    )
}