import React, { createContext, Component } from 'react'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { TezosToolkit, OpKind } from '@taquito/taquito'
import { networkType, nodeUrl, apiUrl, irlAddr, voucherFA2, irlVoucherTokenID, bgVoucherTokenID, meowVoucherTokenID, growlVoucherTokenID, roarVoucherTokenID, raffleTicketTokenID, objFA2, daofa2, irl2Addr } from './constant'
import { mint as mintDatas } from '../data/mint.json'
import { parseDateTime } from '../datetime'
import { Pixel } from '../pixel'

var ls = require('local-storage')

export const TezDozenContext = createContext()
const Tezos = new TezosToolkit(nodeUrl)
const wallet = new BeaconWallet({
    name: 'TezDozen',
})
Pixel()
Tezos.setWalletProvider(wallet)
const axios = require('axios')
export default class TezDozenContextProvider extends Component {

    setTeam = () => {
        const today = new Date()
        var fomoTeam, mintTeam, fomoTeamIndex, mintTeamIndex
        mintDatas.forEach((mint, i) => {
            const fomoSwitch = parseDateTime(mint.generalMintStart)
            const mintSwitch = parseDateTime(mint.generalMintStart)
            switch (mint.team) {
                case 'Team η':
                    fomoSwitch.setDate(fomoSwitch.getDate() - 8)
                    break;
                case 'Team θ':
                    fomoSwitch.setDate(fomoSwitch.getDate() - 8)
                    break;
                case 'Team ι':
                    fomoSwitch.setDate(fomoSwitch.getDate() - 11)
                    mintSwitch.setDate(mintSwitch.getDate() - 8)
                    break;
                case 'Team κ':
                    fomoSwitch.setDate(fomoSwitch.getDate() - 9)
                    mintSwitch.setDate(mintSwitch.getDate() - 4)
                    break;
                case 'Team λ':
                    fomoSwitch.setDate(fomoSwitch.getDate() - 6)
                    mintSwitch.setDate(mintSwitch.getDate() - 1)
                    break;
                case 'Team μ':
                    fomoSwitch.setDate(fomoSwitch.getDate() - 11)
                    mintSwitch.setDate(mintSwitch.getDate() - 6)
                    break;
                default:
                    break;
            }
            if (today > fomoSwitch && today < parseDateTime(mint.generalMintStart)) {
                if (!this.state || this.state.fomoTeam !== mint) {
                    fomoTeam = mint
                    fomoTeamIndex = i
                }
            }
            if (today > mintSwitch) {
                if (!this.state || this.state.mintTeam !== mint) {
                    mintTeam = mint
                    mintTeamIndex = i
                }
            }
        })
        this.setState({
            fomoTeam: fomoTeam,
            fomoTeamIndex: fomoTeamIndex,
            mintTeam: mintTeam,
            mintTeamIndex: mintTeamIndex
        })
    }
    async checkFOMO({ fomoApi }, address) {
        let isfomo = await axios.get(`${apiUrl}/v1/bigmaps/${fomoApi}/keys`, {
            params: {
                key: address,
            },
        }).then((res) => {
            if (res.data.length > 0) {
                return true
            } else {
                return false
            }
        })
        return isfomo
    }
    async checkBlacklist({ blacklistApi }, address) {
        let isfomo = await axios.get(`${apiUrl}/v1/bigmaps/${blacklistApi}/keys`, {
            params: {
                key: address,
            },
        }).then((res) => {
            if (res.data.length > 0) {
                return true
            } else {
                return false
            }
        })
        return isfomo
    }
    async setFOMO() {
        if (this.state.fomoTeam !== undefined) {
            let fomo = await this.checkFOMO(this.state.fomoTeam, this.state.address)
            this.setState({ registeredFOMO: fomo })
            let blacklist = await this.checkBlacklist(this.state.fomoTeam, this.state.address)
            this.setState({ fomoBlacklist: blacklist })
        }
        if (this.state.mintTeam !== undefined) {
            let fomo = await this.checkFOMO(this.state.mintTeam, this.state.address)
            this.setState({ isFOMO: fomo })
            let blacklist = await this.checkBlacklist(this.state.mintTeam, this.state.address)
            this.setState({ mintBlacklist: blacklist })
        }
    }
    async setRarity() {
        await axios.get(`https://akaswap.com/data/list/rarity/tezdozen_rarity.json`)
            .then((res) => {
                if (res.data.length !== 0) {
                    this.setState({ rarity: res.data })
                    return res.data
                }
            })
        await axios.get(`https://akaswap.com/data/list/rarity/tdg_rarity.json`)
            .then((res) => {
                if (res.data.length !== 0) {
                    this.setState({ tgRarity: res.data })
                    return res.data
                }
            })
    }

    constructor(props) {
        super(props)
        this.state = {
            address: '',
            Tezos: null,
            wallet: null,
            acc: null,
            fomoTeam: null,
            mintTeam: null,
            fomoTeamIndex: null,
            mintTeamIndefomoTeamIndex: null,
            registeredFOMO: null,
            mintFOMO: null,
            fomoBlacklist: null,
            mintBlacklist: null,
            rarity: null,
            tgRarity: null,
            sales: null,
            soldOut: null,
            setAuth: (address) => {
                ls.set('auth', address)
            },

            updateLs: (key, value) => {
                ls.set(key, value)
            },

            getLs: (key) => {
                return ls.get(key)
            },

            getAuth: () => {
                return ls.get('auth')
            },
            client: null,
            setClient: (client) => {
                this.setState({
                    client: client,
                })
            },
            dAppClient: async () => {
                this.state.client = wallet.client

                // It doesn't look like this code is called, otherwise the active account should be checked, see below.
                this.state.client
                    .requestPermissions({
                        network: {
                            type: networkType,
                            rpcUrl: nodeUrl,
                        },
                    })
                    .then((permissions) => {
                        this.setState({
                            address: permissions.address,
                        })

                        this.state.setAuth(permissions.address)
                    })
                    .catch((error) => console.log(error))
            },
            signMessage: async () => {
                Tezos.setWalletProvider(wallet)
                const payload = {
                    signingType: 'micheline',
                    payload: '0501333235343635376134343666376136353665',// '05013138313654657a446f7a656e',
                };

                const signedPayload = await wallet.client.requestSignPayload(payload);
                const { signature } = signedPayload;
                return signature
            },
            setAccount: async (done = () => null) => {
                const acc = Tezos !== undefined
                    ? await wallet.client.getActiveAccount()
                    : undefined
                this.setState({
                    acc: acc,
                    address: acc?.address
                }, () => {
                    done()
                    this.setFOMO()
                })
            },

            syncTaquito: async (cb = () => null) => {
                const network = {
                    type: networkType,
                    rpcUrl: nodeUrl,
                }
                let activeAccount = await wallet.client.getActiveAccount()
                if (activeAccount === undefined) {
                    await wallet.clearActiveAccount()
                    await wallet.requestPermissions({ network })
                        .then((response) => {
                            // console.log(response)
                            cb()
                        })
                        .catch((e) => console.error(e))
                }
                this.setState({
                    Tezos: Tezos,
                    address: await wallet.getPKH(),
                    acc: await wallet.client.getActiveAccount(),
                    wallet,
                }, () => {
                    this.setFOMO()
                    this.state.setAuth(this.state.address)
                })
            },
            disconnect: async () => {
                console.log('disconnect wallet')
                // This will clear the active account and the next "syncTaquito" will trigger a new sync
                await wallet.client.clearActiveAccount()
                this.setState({
                    address: undefined,
                    acc: undefined,
                })
            },
            mint: async (mintAmount, isFOMO, contractAddr, minting = (hash) => null, success = (hash) => null, failed = () => null) => {
                console.log(contractAddr)
                let c_crowdsale = await Tezos.wallet.at(contractAddr)
                let c1 = c_crowdsale.methods.register().toTransferParams()
                c1.amount = 0
                c1.mutez = true
                let c2 = c_crowdsale.methods.mint(parseInt(mintAmount)).toTransferParams()
                c2.amount = 12000000 * parseInt(mintAmount)
                c2.mutez = true
                c2.storageLimit = 3000
                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    }
                ])
                    .send()
                    .then((op) => {
                        console.log("Hash : " + op.opHash)
                        minting(op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    success(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    failed(result)
                                }
                            })
                    })
            },
            registerFOMO: async (contractAddr, enrolling = (hash) => null, completed = (hash) => null, getError = () => null) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        console.log('addr:' + contractAddr)
                        console.log('nodeURL:' + nodeUrl)
                        return (
                            c.methods
                                .register_fomo()
                                .send({ amount: 0 })
                                .then((op) => {
                                    enrolling(op.opHash)
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Minted');
                                                completed(op.opHash)
                                            } else {
                                                console.log('An error has occurred');
                                                getError(result)
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)

            },
            addBlacklist: async (crowdsaleAddr, blackAddrList) => {
                return await Tezos.wallet
                    .at(crowdsaleAddr)
                    .then((c) => {
                        return (
                            c.methods
                                .update_blacklist(blackAddrList)
                                .send({ amount: 0 })
                                .then((op) => {
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Set Blacklist Done!');
                                            } else {
                                                console.log('An error has occurred');
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            // TDTMTokenID: token id of TDTM in fa2
            // shipping Type: 1 - In Taiwan, 2 - In Asia, 3 - Out of Asia
            // shipping Fee (mutez): 1 - 2000000, 2 - 12000000, 3 - 24000000
            irlExchange: async (TDTMTokenID, shippingType, shippingFee) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_irl = await Tezos.wallet.at(irlAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: irlAddr,
                            token_id: parseInt(irlVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })
                let c2 = c_irl.methods.exchange(
                    parseInt(TDTMTokenID),
                    parseInt(shippingType)
                ).toTransferParams({ amount: parseFloat(shippingFee), mutez: true })
                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: irlAddr,
                            token_id: parseInt(irlVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })


                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
            },
            irl2Redeem: async (voucherTokenID, shippingType, shippingFee) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_irl = await Tezos.wallet.at(irl2Addr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: irl2Addr,
                            token_id: parseInt(voucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })
                let c2 = c_irl.methods.redeem(
                    voucherFA2, parseInt(voucherTokenID),
                    parseInt(shippingType)
                ).toTransferParams({ amount: parseFloat(shippingFee), mutez: true })
                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: irl2Addr,
                            token_id: parseInt(voucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })


                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
            },
            irl3Collect: async (contractAddr, tdgId, tdtmId, shippingType, collectList, productId, total, loading = () => null, completed = () => null, getError = () => null) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        console.log('addr:' + contractAddr)
                        console.log('nodeURL:' + nodeUrl)
                        console.log('shippingType:' + shippingType)
                        console.log('tdgId:' + tdgId)
                        console.log('tdtmId:' + tdtmId)
                        console.log('productId:' + productId)
                        console.log(collectList)
                        console.log(total)
                        return (
                            c.methods
                                .collect(collectList, productId, shippingType, tdgId, tdtmId)
                                .send({ amount: total })
                                .then((op) => {
                                    loading(op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Minted');
                                                completed(op.opHash)
                                            } else {
                                                console.log('An error has occurred');
                                                getError(result)
                                            }
                                        })
                                })
                        )
                    })
            },
            akaDaoWithdraw: async (contractAddr, tokenId, amount, loading = () => null, completed = () => null, getError = () => null) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        console.log('addr:' + contractAddr)
                        console.log('nodeURL:' + nodeUrl)
                        console.log(amount)
                        console.log(tokenId)
                        return (
                            c.methods
                                .withdraw(parseInt(tokenId), parseInt(amount * 1000000))
                                .send({ amount: 0 })
                                .then((op) => {
                                    loading(op.opHash)
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Minted');
                                                completed(op.opHash)
                                            } else {
                                                console.log('An error has occurred');
                                                getError(result)
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            akaDaoDeposit: async (contractAddr, tokenId, amount, loading = () => null, completed = () => null, getError = () => null) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        console.log('addr:' + contractAddr)
                        console.log('nodeURL:' + nodeUrl)
                        console.log(amount)
                        console.log(tokenId)
                        return (
                            c.methods
                                .deposit(parseInt(tokenId), parseInt(amount * 1000000))
                                .send({ amount: 0 })
                                .then((op) => {
                                    loading(op.opHash)
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Minted');
                                                completed(op.opHash)
                                            } else {
                                                console.log('An error has occurred');
                                                getError(result)
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            bgChange: async (contractAddr, bgType, tokenId, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_bg = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: contractAddr,
                            token_id: parseInt(bgVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                let c2 = c_bg.methods.redeem(
                    parseInt(bgType),
                    parseInt(tokenId)
                ).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: contractAddr,
                            token_id: parseInt(bgVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            meowRedeem: async (contractAddr, iotaId, kappaId, lambdaId, muId, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_meow = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: contractAddr,
                            token_id: parseInt(meowVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                let c2 = c_meow.methods.mint(
                    parseInt(iotaId),
                    parseInt(kappaId),
                    parseInt(lambdaId),
                    parseInt(muId),
                ).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: contractAddr,
                            token_id: parseInt(meowVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            growlRedeem: async (contractAddr, tgId, tdtmIds, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_growl = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: contractAddr,
                            token_id: parseInt(growlVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                let c2 = c_growl.methods.mint(
                    parseInt(tgId),
                    tdtmIds
                ).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: contractAddr,
                            token_id: parseInt(growlVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            roarRedeem: async (contractAddr, tgId, tdtmIds, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_roar = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: contractAddr,
                            token_id: parseInt(roarVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                let c2 = c_roar.methods.mint(
                    parseInt(tgId),
                    tdtmIds
                ).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: contractAddr,
                            token_id: parseInt(roarVoucherTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            roomRedeem: async (amount, contractAddr, voucherId, { type, tdtmId, tgId }, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_room = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: contractAddr,
                            token_id: parseInt(voucherId),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                let c2

                if (type) c2 = c_room.methods.mint(
                    parseInt(amount),
                    parseInt(tgId),
                    parseInt(tdtmId)
                ).toTransferParams({ amount: 0, mutez: true })

                else c2 = c_room.methods.mint(
                    parseInt(amount),
                    parseInt(tdtmId)
                ).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: contractAddr,
                            token_id: parseInt(voucherId),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            meowAuction: async (contractAddr, amount, loading = () => null, completed = () => null, getError = () => null) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        console.log('addr:' + contractAddr)
                        console.log('nodeURL:' + nodeUrl)
                        console.log(amount)
                        return (
                            c.methods
                                .collect_auction()
                                .send({ amount: amount })
                                .then((op) => {
                                    loading(op.opHash)
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Minted');
                                                completed(op.opHash)
                                            } else {
                                                console.log('An error has occurred');
                                                getError(result)
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            growlAuction: async (contractAddr, amount, loading = () => null, completed = () => null, getError = () => null) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        console.log('addr:' + contractAddr)
                        console.log('nodeURL:' + nodeUrl)
                        console.log(amount)
                        return (
                            c.methods
                                .collect_auction()
                                .send({ amount: amount })
                                .then((op) => {
                                    loading(op.opHash)
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Minted');
                                                completed(op.opHash)
                                            } else {
                                                console.log('An error has occurred');
                                                getError(result)
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            daoStoreSwap: async (contractAddr, auctionType, tokens, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let addOperators = []
                let removeOperators = []
                tokens.forEach((t) => {
                    addOperators.push(
                        {
                            add_operator: {
                                operator: contractAddr,
                                token_id: parseInt(t.token_id),
                                owner: tz.address
                            }
                        }
                    )
                    removeOperators.push(
                        {
                            remove_operator: {
                                operator: contractAddr,
                                token_id: parseInt(t.token_id),
                                owner: tz.address
                            }
                        }
                    )
                })

                let c_fa2 = await Tezos.wallet.at(objFA2)
                let c_swap = await Tezos.wallet.at(contractAddr)

                let c1 = c_fa2.methods.update_operators(addOperators).toTransferParams({ amount: 0, mutez: true })

                let entryPoint = auctionType === 1 ? c_swap.methods.make_english_auction : auctionType === 2 ? c_swap.methods.make_dutch_auction : c_swap.methods.swap
                let c2 = entryPoint(tokens).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(removeOperators).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            collectDutchAuction: async (contractAddr, auction_id, validate, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let addOperators = [{
                    add_operator: {
                        operator: contractAddr,
                        token_id: 0,
                        owner: tz.address
                    }
                }]
                let removeOperators = [{
                    remove_operator: {
                        operator: contractAddr,
                        token_id: 0,
                        owner: tz.address
                    }
                }]
                let c_fa2 = await Tezos.wallet.at(daofa2)
                console.log(contractAddr)
                let c_auction = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(addOperators).toTransferParams({ amount: 0, mutez: true })
                let c2 = c_auction.methods.collect_dutch_auction(
                    parseInt(auction_id), validate.is_tdtm, parseInt(validate.token_id)).toTransferParams({ amount: 0, mutez: true })
                let c3 = c_fa2.methods.update_operators(removeOperators).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            bidEnglishAuction: async (contractAddr, auction_id, bidValue, validate, loading = () => null, completed = () => null, getError = () => null) => {

                const tz = await wallet.client.getActiveAccount()
                let addOperators = [{
                    add_operator: {
                        operator: contractAddr,
                        token_id: 0,
                        owner: tz.address
                    }
                }]
                let removeOperators = [{
                    remove_operator: {
                        operator: contractAddr,
                        token_id: 0,
                        owner: tz.address
                    }
                }]
                let c_fa2 = await Tezos.wallet.at(daofa2)
                console.log(contractAddr)
                let c_auction = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(addOperators).toTransferParams({ amount: 0, mutez: true })
                let c2 = c_auction.methods.bid_english_auction(
                    parseInt(auction_id), bidValue, validate.is_tdtm, parseInt(validate.token_id)
                ).toTransferParams({ amount: 0, mutez: true, storageLimit: 1e3 })
                let c3 = c_fa2.methods.update_operators(removeOperators).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Bid Success');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },

            collectDSSwap: async (contractAddr, swap_id, validate, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let addOperators = [{
                    add_operator: {
                        operator: contractAddr,
                        token_id: 0,
                        owner: tz.address
                    }
                }]
                let removeOperators = [{
                    remove_operator: {
                        operator: contractAddr,
                        token_id: 0,
                        owner: tz.address
                    }
                }]
                let c_fa2 = await Tezos.wallet.at(daofa2)
                console.log(contractAddr)
                let c_daoStore = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(addOperators).toTransferParams({ amount: 0, mutez: true })
                let c2 = c_daoStore.methods.collect_swap(
                    parseInt(swap_id), validate.is_tdtm, parseInt(validate.token_id)
                ).toTransferParams({ amount: 0, mutez: true, storageLimit: 1e3 })
                let c3 = c_fa2.methods.update_operators(removeOperators).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)

            },
            raffle: async (contractAddr, amount, loading = () => null, completed = () => null, getError = () => null) => {
                const tz = await wallet.client.getActiveAccount()
                let c_fa2 = await Tezos.wallet.at(voucherFA2)
                let c_raffle = await Tezos.wallet.at(contractAddr)
                let c1 = c_fa2.methods.update_operators(
                    [{
                        add_operator: {
                            operator: contractAddr,
                            token_id: parseInt(raffleTicketTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                let c2 = c_raffle.methods.raffle(
                    parseInt(amount)
                ).toTransferParams({ amount: 0, mutez: true })

                let c3 = c_fa2.methods.update_operators(
                    [{
                        remove_operator: {
                            operator: contractAddr,
                            token_id: parseInt(raffleTicketTokenID),
                            owner: tz.address
                        }
                    }]
                ).toTransferParams({ amount: 0, mutez: true })

                return await Tezos.wallet.batch([
                    {
                        kind: OpKind.TRANSACTION,
                        ...c1,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c2,
                    },
                    {
                        kind: OpKind.TRANSACTION,
                        ...c3,
                    }
                ]).send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Minted');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            readySale: async (crowdsaleAddr, earlyDeployerList, fomoStart_unixTimestamp) => {
                const fomoStart = new Date(fomoStart_unixTimestamp * 1000)
                const c_crowdsale = await Tezos.wallet.at(crowdsaleAddr)
                console.log(fomoStart)
                await Tezos.wallet.batch()
                    .withContractCall(c_crowdsale.methods.ready_sale(fomoStart.toISOString()))
                    .withContractCall(c_crowdsale.methods.voucher_early_deployer(earlyDeployerList))
                    .send()
            },
            revealMetadata: async (crowdsaleAddr, revealList) => {
                return await Tezos.wallet
                    .at(crowdsaleAddr)
                    .then((c) => {
                        return (
                            c.methods
                                .reveal_token_metadata(revealList)
                                .send({ amount: 0 })
                                .then((op) => {
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Reveal Done!');
                                            } else {
                                                console.log('An error has occurred');
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            upgradeMetadata: async (crowdsaleAddr, upgradeList) => {
                return await Tezos.wallet
                    .at(crowdsaleAddr)
                    .then((c) => {
                        return (
                            c.methods
                                .upgrade_token_metadata(upgradeList)
                                .send({ amount: 0 })
                                .then((op) => {
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Upgrade Done!');
                                            } else {
                                                console.log('An error has occurred');
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            },
            directUpdateMetadata: async (contractAddr, updateList) => {
                return await Tezos.wallet
                    .at(contractAddr)
                    .then((c) => {
                        return (
                            c.methods
                                .update_token_metadata(updateList)
                                .send({ amount: 0 })
                                .then((op) => {
                                    console.log("Hash : " + op.opHash)
                                    op.confirmation()
                                        .then((result) => {
                                            if (result.completed) {
                                                console.log('Update Done!');
                                            } else {
                                                console.log('An error has occurred');
                                            }
                                        })
                                }))
                    }
                    )
                    .catch((e) => e)
            }
        }
    }
    componentDidMount() {
        this.setTeam()
        this.setRarity()
        // setInterval(this.setTeam(), 1000)    // Check fomo team every 1 second
    }
    render() {
        return (
            <TezDozenContext.Provider
                value={{
                    ...this.state
                }}
            >
                {this.props.children}
            </TezDozenContext.Provider>
        )
    }
}