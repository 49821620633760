import React, { useContext, useEffect, useState } from 'react'
import "swiper/swiper.scss";
import styles from './styles.module.scss'
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { ReactComponent as ArrowIcon } from './images/arrow.svg'
// import { ReactComponent as NotsupportIcon } from './images/not_support.svg'
import { ReactComponent as CloseButton } from './images/close_button.svg'
import { ReactComponent as ErrorIcon } from './images/error.svg'
import { TezDozenContext } from '../../context/TezDozenContext';
import { bgContract, tzktUrl, bgOpen } from '../../context/constant';

SwiperCore.use([Navigation]);

export const TDTMCard = ({ token, className, onClick = () => null, noTitle }) => {
  // parse token info
  const name = token.name.split("Tez Dozen ")[1]
  const id = token.tokenId
  const displayUri = token.displayUri.split("ipfs://")[1]
  var attrs = {}
  token.attributes.forEach(({ name, value }) => { attrs[name] = value })

  return (
    <div className={styles.TDTMCard + ' ' + (className ? className : '')}>
      {(attrs['Background'] !== 'tex' && attrs['Class'] !== 'artist_original')
        ? <div className={styles.clickable} onClick={() => onClick()}></div>
        : <div className={styles.notClickable}>Not Supported</div>}
      <div className={styles.image}>
        <img alt={id} src={`https://assets.akaswap.com/ipfs/${displayUri}`}></img>
      </div>
      {!noTitle && <div className={styles.title}>
        {name}
      </div>}
    </div>
  )
}

export const BgPreview = ({ selectedBg, token, className }) => {
  if (token) {
    const displayUri = token.displayUri.split("ipfs://")[1]
    const id = token.tokenId
    var attrs = {}
    token.attributes.forEach(({ name, value }) => { attrs[name] = value })
    return (
      <div className={className + ' ' + styles.bgPreview + ' ' + (selectedBg === 1 ? styles.bg1 : selectedBg === 2 ? styles.bg2 : selectedBg === 3 ? styles.bg3 : '')}>
        {selectedBg > 0 ? <img alt={id} className={styles.previewTDTM} src={`https://static.tezdozen.xyz/clearedTDTM/${id}.png`}></img>
          : <img alt={token.tokenId} className={styles.previewTDTM} src={`https://assets.akaswap.com/ipfs/${displayUri}`}></img>}
      </div>
    )
  } else
    return <div className={className + ' ' + styles.bgNA}></div>
}

export const BgModal = ({ collection, voucherAmount, setIsOpened = () => null, updateVoucher = () => null }) => {
  const [step, setStep] = useState(bgOpen ? 0 : 1)
  const [selectedCollection, setSelectedCollection] = useState(null) // collection Index
  const [selectedBg, setSelectedBg] = useState(0)
  const [success, setSuccess] = useState(false)
  const [resultMessage, setResultMessage] = useState('Connecting wallet...')
  const [confirmRedeem, setConfirmRedeem] = useState(false)
  const context = useContext(TezDozenContext)

  useEffect(() => {
    // set selected collection to the first available token
    for (let index = 0; index < collection.length; index++) {
      const token = collection[index];
      let attrs = {}
      token.attributes.forEach(({ name, value }) => { attrs[name] = value })
      if (attrs['Background'] !== 'tex' && attrs['Class'] !== 'artist_original') {
        setSelectedCollection(index)
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // STEP 1: Welcome
  const welcome = () => {

    return (
      <>
        <div className={styles.row}>
          <div className={styles.welcomeDeco}></div>
          <div className={styles.welcomeText}>
            <h4 className={styles.stepTitle}>Hello member!</h4>
            <ol>
              <li>BG-WorkShop service is open from 2022/3/1 0:00 to 2022/3/31 23:59 (GMT+8).</li>
              <li>You can change the background of one TDTM through the burning of one BG-Voucher.</li>
              <li>TDTMs of artist-original and textured background are not allowed to change background.</li>
              <li>You may not be able to undo the process after issuing the service, but you can change the background again by issuing another valid BG-Voucher before March 31.</li>
              <li>After completing the action, your TDTM will be updated within one week.</li>
            </ol>
          </div>
        </div>
        <div className={styles.startButton}>
          <button onClick={() => setStep(1)}>Start</button>
        </div>
      </>
    )
  }

  // STEP 2: select TDTM and Background
  const selectTDTM = (collection) => {

    function handleTDTMChange(index) {
      setSelectedCollection(index)
    }

    return (
      <>
        <div className={styles.row}>
          <div className={styles.left}>
            <h4 className={styles.description}>Choose the TDTM</h4>
            <Swiper slidesPerView={3} spaceBetween={0} slidesPerGroup={1} pagination={{
              // "clickable": true
            }} centeredSlides={false} navigation={true} className={styles.TDTMList}
              // initialSlide={selectedCollection}
              // preventClicks={false}
              // preventClicksPropagation={false}
              // slideToClickedSlide={false}
              // onSlideChange={(swiper) => { handleTDTMChange(swiper.realIndex) }}
              breakpoints={{
                320: {
                  slidesPerView: 2
                },
                768: {
                  slidesPerView: 3
                }
              }}>
              {collection.map((tdtm, i) => (<SwiperSlide key={i}>
                <TDTMCard token={tdtm} onClick={() => { handleTDTMChange(i) }} className={selectedCollection === i ? styles.selected : ''} />
              </SwiperSlide>))}
            </Swiper>
            <h4 className={styles.description}>Select the background</h4>
            <div className={styles.bgChoose}>
              <div className={styles.bg1 + ' ' + (selectedBg === 1 ? styles.selected : '')} onClick={() => setSelectedBg(selectedBg === 1 ? 0 : 1)}></div>
              <div className={styles.bg2 + ' ' + (selectedBg === 2 ? styles.selected : '')} onClick={() => setSelectedBg(selectedBg === 2 ? 0 : 2)}></div>
              <div className={styles.bg3 + ' ' + (selectedBg === 3 ? styles.selected : '')} onClick={() => setSelectedBg(selectedBg === 3 ? 0 : 3)}></div>
            </div>
          </div>

          <div className={styles.right}>
            <h4>Preview</h4>
            {selectedCollection === null ? <BgPreview className={styles.preview} />
              : <BgPreview selectedBg={selectedBg} className={styles.preview} token={collection[selectedCollection]} />}
            <div className={styles.endedNote}>Thanks for your support!<br />
              The redemption of BG-Workshop<br />
              has ended on 3/31.</div>
            {!bgOpen ? <button className={styles.changeButton} onClick={()=>setIsOpened(false)}>Exit</button>
              : voucherAmount > 0 ? <button className={styles.changeButton} onClick={() => { setStep(2); setConfirmRedeem(false) }} disabled={selectedBg === 0 || selectedBg > 3}>Confirm</button>
                : <button className={styles.changeButton} disabled>BG-voucher needed</button>}
          </div>
        </div>
      </>
    )
  }

  // STEP 3: Confirm
  const confirm = (selectedTDTM, selectedBg) => {
    const link = (opHash, message) => (
      <>{message}<br></br>
        {opHash && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={tzktUrl + opHash}>View on tzkt.io →</a>}
      </>
    )

    function onConfirm() {
      // call beacon here
      var send = context.bgChange
      setResultMessage('Connecting your wallet...')
      setStep(3)
      send(
        bgContract,
        selectedBg,
        selectedTDTM.tokenId,
        (opHash) => {
          setResultMessage(link(opHash, 'Processing your transaction...'))
        },
        (opHash) => {
          setStep(4)
          setSuccess(true)
          updateVoucher()
          setResultMessage(link(opHash, <div><h4>Background changed successfully!</h4>Your TDTM will be updated within one week.</div>))
        },
        (opHash) => {
          setStep(4)
          setResultMessage(link(opHash, `Failed to change background!`))
        }
      ).then((e) => {
        if (e) {
          setResultMessage(e.description)
          setStep(4)
        } else {
          // setResultMessage('Transaction failed!')
        }
      })
    }
    return (<>

      <div className={styles.graph}>

        <div className={styles.left}>
          <TDTMCard token={selectedTDTM} noTitle />
        </div>

        <div className={styles.mid}>
          <div>
            <div className={styles.arrow}><ArrowIcon /></div>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.preview}>
            <BgPreview selectedBg={selectedBg} className={styles.newBackground} token={selectedTDTM} />
          </div>
        </div>
      </div>
      <div className={styles.confirm}>
        <p className={styles.notice}>Notice：</p>
        <p className={styles.notice}> 1.	You may not be able to undo this action after clicking “REDEEM.”</p>
        <p className={styles.notice}> 2.	Changing background involves manual verification and may take a few days.</p>
        <div><input type="checkbox" value={false} onChange={() => setConfirmRedeem(!confirmRedeem)} checked={confirmRedeem} />  I have read and agreed the above notices.</div>
        <button onClick={() => onConfirm()} disabled={!confirmRedeem}>Redeem</button>
      </div>
    </>)
  }

  // STEP 4
  const processing = () => (
    <div className={styles.container}>
      <div className={styles.loading}></div>
      <div>{resultMessage}</div>
    </div>
  )

  // STEP 5
  const result = (success, TDTM, bg) => {
    function onFinish() {
      setIsOpened(false)
    }
    function onBack() {
      setStep(2)
    }
    return (
      <div className={styles.container + ' ' + styles.result}>
        {
          success ? <BgPreview token={TDTM} selectedBg={bg} />
            : <div className={styles.resultIcon}>  <ErrorIcon /></div>
        }
        <div className={styles.resultMessage}>{resultMessage}</div>
        {!success && <button onClick={onBack}>Back</button>}
        <button onClick={onFinish}>Finish</button>
      </div>
    )
  }


  function onBack() {
    setStep(step - 1)
  }
  return (
    <div className={styles.modalOverlay} onClick={(e) => { if (e.target === e.currentTarget) setIsOpened(false) }}>
      {step !== 1 && <div className={styles.decoLeft}></div>}
      {step !== 1 && <div className={styles.decoRight}></div>}
      <div className={styles.bgModal}>
        <div className={styles.CloseButton} onClick={(e) => { setIsOpened(false) }}><CloseButton /></div>
        <h3 className={styles.title}><div className={styles.titledecoLeft}></div>BG-WorkShop<div className={styles.titledecoRight}></div></h3>
        {step === 0 && welcome(collection)}
        {step === 1 && selectTDTM(collection)}
        {step === 2 && confirm(collection[selectedCollection], selectedBg)}
        {step === 3 && processing()}
        {step === 4 && result(success, collection[selectedCollection], selectedBg)}
        {bgOpen && step > 0 && step < 3 && <button className={styles.navButton} onClick={() => onBack()}>Back</button>}
      </div>
    </div>
  )
}