import React, { useContext, useEffect, useState } from 'react'
import { TezDozenContext } from "../../context/TezDozenContext"
import styles from './styles.module.scss'
import SwiperCore, { Navigation, EffectCards, Parallax } from 'swiper';
import "swiper/swiper.scss";
import { ReactComponent as AkaDaoIcon } from './img/akaDao.svg'
import { ReactComponent as VoucherIcon } from './img/voucher.svg'
import { akaswapApi, fa2, voucherFA2, irlVoucherTokenID, irlOpen, bgOpen, ggOpen, raffleOpen, growlVoucherTokenID, raffleTicketTokenID, tgfa2, daoStoreOpen, objFA2, doorList, voucherList, bgVoucherTokenID, meowVoucherTokenID, roarVoucherTokenID, apiUrl, daofa2, room1VoucherTokenID, room2VoucherTokenID } from '../../context/constant';
import { irlObj } from '../../data/DaoStoreIndex.json'
import { SyncButton } from '../syncButton';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import IRLModal from '../irl3-modal';
import { GalleryModal } from '../gallery';
import { AtmModal } from '../atm-modal';
import { BgModal } from '../bg-modal';
import { GgModal } from '../gg-modal';
import { RaffleModal } from '../raffle-modal';
import RoomModal from '../room-modal';
import { useHistory } from 'react-router-dom';
import ARModal from '../ar-modal';

const axios = require('axios')
SwiperCore.use([Navigation, EffectCards, Parallax]);

export const TDTMCard = ({ token, className, onClick = () => null, active }) => {
    // parse token info
    const name = token.name.split("Tez Dozen ")[1]
    const id = token.tokenId
    const displayUri = token.displayUri.split("ipfs://")[1]
    var attrs = {}
    if (token.attributes) token.attributes.forEach(({ name, value }) => {
        attrs[name] = value
    })

    return (
        <div className={styles.TDTMCard + ' ' + (className ? className : '')}>
            <div className={styles.clickable} onClick={() => onClick()}></div>
            <div className={styles.image}>
                <img alt={id} src={`https://assets.akaswap.com/ipfs/${displayUri}`}></img>
            </div>
            <div className={styles.title}>
                {active && name}
            </div>
        </div>
    )
}


export const DozenVerseSection = () => {
    const context = useContext(TezDozenContext)
    const [collection, setCollection] = useState([])
    const [tgCollection, setTgCollection] = useState([])
    const [irl2Collection, setIrl2Collection] = useState([])
    const [akaDao, setAkaDao] = useState(0)
    const [vouchersAmount, setVouchersAmount] = useState({})
    const history = useHistory()

    function getVoucher(address) {
        const contractAddr = voucherFA2
        var joinId = voucherList.map(v => voucherData[v].id).join(',')
        axios.get(`${apiUrl}/v1/tokens/balances?account=${address}&token.contracts=${contractAddr}&token.tokenId.in=${joinId}`)
            .then((res) => {
                if (res.data) {
                    var tokens = res.data
                    if (tokens.length > 0) {
                        var amount = {}
                        voucherList.forEach(v => {
                            var t = tokens.find(token => parseInt(token.token.tokenId) === voucherData[v].id)
                            if (t) amount[v] = parseInt(t.balance)
                        })
                        setVouchersAmount(amount)
                    }
                }
            })
            .catch((e) => console.log(e))
    }
    function getAkaDao(address) {
        axios.get(`${apiUrl}/v1/tokens/balances?token.contract=${daofa2}&account=${address}&select=balance`)
            .then((res) => {
                if (res.data && res.data[0])
                    setAkaDao(parseInt(res.data[0]) / 1000000)
            })
            .catch((e) => console.log(e))
    }
    function getMoreCollection(address, page, tokens) {
        var limit = 10
        axios.get(`${akaswapApi}/accounts/${address}/fa2tokens?contracts=${fa2}&offset=${limit * page}&limit=${limit}`)
            .then((res) => {
                if (res.data) {
                    var c = tokens.concat(res.data.tokens)
                    setCollection(c)
                    page += 1
                    if (res.data.count > page * limit) {
                        console.log(res.data.count)
                        getMoreCollection(address, page, c)
                    }
                }
            })
            .catch((e) => console.log(e))
    }
    function getTgCollection(address, page, tokens) {
        var limit = 10
        axios.get(`${akaswapApi}/accounts/${address}/fa2tokens?contracts=${tgfa2}&offset=${limit * page}&limit=${limit}`)
            .then((res) => {
                if (res.data) {
                    var c = tokens.concat(res.data.tokens)
                    setTgCollection(c)
                    page += 1
                    if (res.data.count > page * limit) {
                        getTgCollection(address, page, c)
                    }
                }
            })
            .catch((e) => console.log(e))
    }
    function getIrl2Collection(address) {
        var joinId = ''
        if (irlObj) irlObj.forEach((obj, i) => {
            joinId += (i > 0 ? ',' : '') + obj;
        })
        var contractAddr = objFA2
        axios.get(`${apiUrl}/v1/tokens/balances?account=${address}&token.contracts=${contractAddr}&token.tokenId.in=${joinId}`)
            .then((res) => {
                if (res.data) {
                    // console.log(res.data)
                    var collections = []
                    res.data.forEach(token => {
                        if (token.token.metadata) {
                            for (let i = 0; i < parseInt(token.balance); i++) {
                                var metadata = token.token.metadata
                                metadata.tokenId = token.token.tokenId
                                collections.push(metadata)
                            }
                        }
                        else {
                            // get metadata from akaswap api if no metadata, not tested
                            axios.get(`${akaswapApi}/fa2tokens/${objFA2}/${token.token.tokenId}`)
                                .then((res) => {
                                    var c = irl2Collection
                                    for (let i = 0; i < parseInt(token.balance); i++) {
                                        c.push(res.data)
                                    }
                                    setIrl2Collection(c)
                                })
                        }
                    });
                    setIrl2Collection(collections)
                }
            })
            .catch((e) => console.log(e))
    }
    function getCollection(address) {
        getMoreCollection(address, 0, [])
        getTgCollection(address, 0, [])
        getIrl2Collection(address)
    }

    useEffect(() => {
        if (context.address) {
            getCollection(context.address)
            getAkaDao(context.address)
            getVoucher(context.address)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.address])

    const Welcome = () => (
        <div className={styles.welcomeRow}>
            <div className={styles.left}>
                <h2>DoZenVerse</h2>
                <div>
                    <img src='/images/DozenVerse.png' alt=' '></img>
                </div>
                <div>
                    {/* <p>Welcome to DoZenVerse, an exclusive space for TDTM owners.</p>
                    <p>To enter this space, you need to own at least one TDTM or TDG in your wallet.</p> */}
                    <p>Welcome to DoZenVerse. To enter this space, please sync your wallet first.</p>
                </div>
                <SyncButton feature='dozenVerse' className={styles.SyncButton} disabled={context.address && collection.length <= 0} />
            </div>
            <div className={styles.right}>
                <iframe src="https://www.youtube.com/embed/ubdZmdsDxGw?&playlist=ubdZmdsDxGw&autoplay=1&mute=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>)

    const AkaDao = () => (
        <div>
            <div className={styles.header}>
                <AkaDaoIcon />
                <h4>akaDAO</h4>
            </div>
            <div className={styles.text}>
                <p>{akaDao}</p>
            </div>
        </div>
    )

    const voucherData = {
        ed: {
            name: 'ED-Voucher',
            id: 1313,
        },
        irl: {
            name: 'IRL-Voucher',
            id: irlVoucherTokenID
        },
        bg: {
            name: 'BG-Voucher',
            id: bgVoucherTokenID
        },
        meow: {
            name: 'Meow-Voucher',
            id: meowVoucherTokenID
        },
        growl: {
            name: 'GrOwl-Voucher',
            id: growlVoucherTokenID
        },
        roar: {
            name: 'Roar-Voucher',
            id: roarVoucherTokenID
        },
        re: {
            name: 'Raffle-Ticket',
            id: raffleTicketTokenID
        },
        room: {
            name: 'TD-Showroom Voucher',
            id: room1VoucherTokenID
        },
        room2: {
            name: 'TD-ShowRoom Twilight Edition Voucher',
            id: room2VoucherTokenID
        }
    }
    const Voucher = () => (
        <div>
            <div className={styles.header}>
                <VoucherIcon />
                <h4>Voucher</h4>
            </div>
            <div className={styles.text}>
                {voucherList.map((v, i) => (<p>
                    <a target='_blank' rel='noreferrer' href={`https://akaswap.com/akaobj/${voucherData[v].id}`}>
                        {voucherData[v].name}
                    </a>
                    x {vouchersAmount && vouchersAmount[v] ? vouchersAmount[v] : 0}
                </p>))}
            </div>
        </div>
    )

    const TDTM = () => (
        <div>
            <Swiper slidesPerView={'auto'} spaceBetween={0} slidesPerGroup={1} pagination={{
                "clickable": true
            }} centeredSlides={false} navigation={false} className={styles.TDTMCarousel} effect={'cards'} cardsEffect={{ slideShadows: false }}
            >
                {collection.length + tgCollection.length === 0 &&
                    <SwiperSlide className={styles.slide}>
                        {({ isActive }) => (
                            <div className={styles.TDTMCard + ' ' + styles.guestCard}>
                                <div className={styles.clickable}></div>
                                <div className={styles.image}>

                                </div>
                                <div className={styles.title}>
                                    Guest Mode
                                </div>
                            </div>
                        )}
                    </SwiperSlide>
                }
                {collection.length > 0 && collection.map((token, i) => (
                    <SwiperSlide className={styles.slide} key={i}>
                        {({ isActive }) => (
                            <TDTMCard active={isActive} token={token} onClick={() => { setToken(token) }} />
                        )}
                    </SwiperSlide>
                )).reverse()}
                {tgCollection.length > 0 && tgCollection.map((token, i) => (
                    <SwiperSlide className={styles.slide} key={i}>
                        {({ isActive }) => (
                            <TDTMCard active={isActive} token={token} onClick={() => { if (token.tokenId < 603) setToken(token) }} />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div >
    )
    const [token, setToken] = useState(null)
    const TDTMModal = (token) => {
        const name = token.name.split("Tez Dozen ")[1]
        var attrs = {}
        token.attributes.forEach(({ name, value }) => { attrs[name] = value })

        return (
            <GalleryModal
                isGuardian={token.contract === tgfa2}
                id={parseInt(token.tokenId)}
                name={name}
                artifact={token.artifactUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/")}
                attr={attrs}
                setIsOpened={() => { setToken() }} />
        )
    }

    const [translate, setTranslate] = useState(0)
    const [transition, setTransition] = useState(0)
    const [modalOpen, setModalOpen] = useState(null)
    const doorData = {
        irl: {
            title: 'IRL-Shop',
            button: 'Enter',
            construction: !irlOpen,
            openModal: <IRLModal
                collection={collection.reverse()}
                tdgCollection={tgCollection.reverse()}
                setIsOpened={() => setModalOpen()}
                updateDao={() => getAkaDao(context.address)}
                walletBalance={akaDao} />
        },
        ds: {
            title: 'DAO-Store',
            construction: !daoStoreOpen,
            button: (!collection.length && !tgCollection.length) ? 'TDTM/TDG Needed' : 'Enter',
            openPage: 'dao-store'
        },
        gg: {
            title: 'Guardian Garden',
            construction: !ggOpen,
            button: !vouchersAmount.roar ? 'Roar-Voucher needed' : !collection.length ? 'TDTM needed' : 'Enter',
            openModal: <GgModal
                collection={collection} tgCollection={tgCollection}
                growlVoucherAmount={vouchersAmount.roar}
                setIsOpened={() => setModalOpen()}
                updateVoucher={() => getVoucher(context.address)} />
        },
        atm: {
            title: 'DAO-ATM',
            button: !collection.length ? 'TDTM Needed' : 'Enter',
            openModal: <AtmModal
                collection={collection}
                setIsOpened={() => setModalOpen()}
                updateDao={() => getAkaDao(context.address)}
                walletBalance={akaDao} />
        },
        re: {
            title: 'Raffle-Event',
            button: !collection.length ? 'TDTM Needed' : 'Enter',
            openModal: <RaffleModal
                voucherAmount={vouchersAmount.re ? vouchersAmount.re : 0}
                setIsOpened={() => setModalOpen()}
                updateVoucher={() => getVoucher(context.address)} />
        },
        bg: {
            title: 'BG-WorkShop',
            button: (bgOpen && !vouchersAmount.bg) ? 'BG voucher needed' : !collection.length ? 'TDTM needed' : 'Enter',
            openModal: <BgModal
                collection={collection} voucherAmount={vouchersAmount.bg}
                setIsOpened={() => setModalOpen()}
                updateVoucher={() => getVoucher(context.address)} />
        },
        room: {
            title: 'ShowRoom-Reception',
            button: 'Enter',
            openModal: <RoomModal
                tdtm={collection}
                tdg={tgCollection}
                room1VoucherAmount={vouchersAmount?.room || 0}
                room2VoucherAmount={vouchersAmount?.room2 || 0}
                setIsOpened={() => setModalOpen()}
                updateVoucher={() => getVoucher(context.address)}
            />
        },
        ar: {
            title: 'AR-Workshop',
            button: 'Enter',
            openModal: <ARModal
                setIsOpened={() => setModalOpen()}
            />
        }
    }
    // Doors
    const Events = () => (
        <>
            <div className={styles.floor}></div>
            <Swiper slideClass={styles.slide} slidesPerView={'auto'} spaceBetween={0} pagination={{
                "clickable": true
            }} centeredSlides={true} navigation={true} className={styles.EventCarousel}
                onSetTranslate={(swiper, translate) => setTranslate(translate)}
                onTouchMove={() => setTransition('0ms')}
                onTouchEnd={() => setTransition('300ms')}
                parallax={true}
            >
                <div slot="container-start" className={styles.parallaxBg} style={{
                    transform: `translateX(${translate}px)`,
                    transitionDuration: transition
                }}>
                    <div className={styles.deco1}></div>
                    <div className={styles.deco2}></div>
                    <div className={styles.deco3}></div>
                    <div className={styles.deco4}></div>
                    <div className={styles.deco5}></div>
                </div>
                {doorList.map((door, i) => (
                    <SwiperSlide className={styles.slide}>
                        <div className={styles.title}>{doorData[door].title}</div>
                        <div className={styles.image}>
                            <img src={doorData[door].construction ? './images/door_construction.png' : `./images/door_${door}.png`} alt=' ' />
                        </div>
                        {!doorData[door].construction &&
                            <button className={styles.EnterButton}
                                onClick={() => doorData[door].openPage ? history.push(doorData[door].openPage) : setModalOpen(doorData[door].openModal)}
                                disabled={doorData[door].button !== 'Enter'}
                            >{doorData[door].button}</button>
                        }
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
    const DashBoard = () => (
        <div className={styles.DozenVerseContainer}>
            <div className={styles.panelRight}>
                <h2>DoZenVerse</h2>
                {Events()}
            </div>
            <div className={styles.panelLeft}>
                {token && TDTMModal(token)}
                {modalOpen}
                {TDTM()}
                <div className={styles.addrInfo}>
                    {AkaDao()}
                    {voucherList.length > 0 && Voucher()}
                </div>
            </div>
        </div>
    )
    return (
        <>
            {(context.address) ? DashBoard() : Welcome()}
        </>
    )
}