import React, { useEffect, useState } from 'react'
import { getDeltaSecond } from '../../datetime';
import styles from './styles.module.scss'

export const Timer = ({ time, type, end }) => {
    // initialize timeLeft with the seconds prop
    const [timeLeft, setTimeLeft] = useState(getDeltaSecond(time));

    // function refreshPage() {
    //     window.location.reload(false);
    // }

    useEffect(() => {
        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(getDeltaSecond(time));
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    if (timeLeft < 0) {
        if (end === "refresh") return (
            <>
                <span className={styles.time}>00</span><span>&nbsp;s</span>
            </>
        )
        else
            return (<></>)
    }
    else if (type === "dhms") {
        let tl = timeLeft
        let d = Math.floor(tl / (60 * 60 * 24))
        tl %= 60 * 60 * 24
        let hh = Math.floor(tl / (60 * 60))
        tl %= 60 * 60
        let mm = Math.floor(tl / 60)
        let ss = Math.floor(tl % 60)

        return (
            <>
                {(d > 0) && (
                    <>
                        <span className={styles.time}>{d}</span>
                        <span>day </span>
                        <span className={styles.time}>{(hh).toString().padStart(2, '0')}</span>
                        <span>hr </span>
                        <span className={styles.time}>{(mm).toString().padStart(2, '0')}</span>
                        <span>min </span>
                        <span className={styles.time}>{(ss).toString().padStart(2, '0')}</span>
                        <span>sec </span>
                    </>
                )}
                {(d === 0) && (hh > 0) && (
                    <>
                        <span className={styles.time}>{(hh).toString().padStart(2, '0')}</span>
                        <span>hr </span>
                        <span className={styles.time}>{(mm).toString().padStart(2, '0')}</span>
                        <span>min </span>
                        <span className={styles.time}>{(ss).toString().padStart(2, '0')}</span>
                        <span>sec </span>
                    </>
                )}
                {(d === 0) && (hh === 0) && (mm > 0) && (
                    <>
                        <span className={styles.time}>{(mm).toString().padStart(2, '0')}</span>
                        <span>min </span>
                        <span className={styles.time}>{(ss).toString().padStart(2, '0')}</span>
                        <span>sec </span>
                    </>
                )}
                {(d === 0) && (hh === 0) && (mm === 0) && (
                    <>
                        <span className={styles.time}>{(ss).toString().padStart(2, '0')}</span>
                        <span>sec </span>
                    </>
                )}
            </>
        );
    }
    else if (type === "ISO") {
        return (
            <>
                {new Date(new Date().getTime() + timeLeft * 1000).toISOString()}
            </>
        );
    }
    else if (type === "mm") {
        return (
            <>
                {new Date(timeLeft * 1000).toISOString().substr(14, 5)}
            </>
        );
    }
    else {
        return (
            <>
                {parseInt(timeLeft)}
            </>
        );
    }


};