import React, { useContext, useState } from 'react'
import styles from './styles.module.scss'
import { TezDozenContext } from "../../context/TezDozenContext"
import { ReactComponent as SuccessIcon } from './images/success.svg'
import { ReactComponent as ErrorIcon } from './images/error.svg'
import { ReactComponent as TickIcon } from './images/tick.svg'
import { ReactComponent as AddIcon } from './images/add.svg'
import { ReactComponent as MinusIcon } from './images/minus.svg'
import whiteroom from './images/showroom_white_nonum.png'
import blackroom from './images/showroom_black_nonum.png'
import { tzktUrl, akaverseUrl, room1VoucherTokenID, room2VoucherTokenID, room1Redeem, room2Redeem } from '../../context/constant';
import { Modal } from '../modal';
import { useEffect } from 'react'

const RoomModal = ({ tdtm, tdg, room1VoucherAmount, room2VoucherAmount, setIsOpened = () => null, updateVoucher = () => null }) => {
    const context = useContext(TezDozenContext)

    const [step, setStep] = useState(0) //0: selectRoom, 1: redeemRoom, 2: processing, 3: result
    const [roomTypeIndex, setRoomTypeIndex] = useState(0)
    const [quantity, setQuantity] = useState(1)

    // transaction result states
    const [success, setSuccess] = useState(false)
    const [resultMessage, setResultMessage] = useState('Connecting to your wallet')

    function selectRoomType(index) {
        setRoomTypeIndex(index)
        setStep(1)
        setQuantity(roomType[index].limit || 1)
    }

    function handleRedeem() {
        let contract = roomTypeIndex ? room2Redeem : room1Redeem
        let voucherId = roomTypeIndex ? room2VoucherTokenID : room1VoucherTokenID
        let info = roomTypeIndex ? {
            type: roomTypeIndex,
            tdtmId: tdtm[0].tokenId,
            tgId: tdg[0].tokenId
        } : {
            type: roomTypeIndex,
            tdtmId: tdtm[0].tokenId
        }
        context.roomRedeem(quantity, contract, voucherId, info,
            (opHash) => {
                setResultMessage(link(opHash, 'Processing your transaction...'))
            },
            (opHash) => {
                setStep(3)
                setSuccess(true)
                setResultMessage(link(false, 'Redeemed successfully', true))
                updateVoucher()
            },
            (opHash) => {
                setStep(3)
                setResultMessage(link(opHash, `Failed to redeem`))
            }).then((e) => {
                if (e) {
                    setResultMessage(e.description)
                    setStep(3)
                }
            })
        setStep(2)
    }

    const link = (opHash, message, akv) => (
        <>{message}<br></br>
            {opHash && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={tzktUrl + opHash}>View on tzkt.io →</a>}
            <br></br>
            {akv && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={akaverseUrl}>View on akaVerse →</a>}
        </>
    )

    const roomType = [
        {
            name: 'TD-ShowRoom',
            thumbnail: whiteroom,
            voucherID: room1VoucherTokenID,
            limit: room1VoucherAmount
        },
        {
            name: <>TD-ShowRoom<br />Twilight Edition</>,
            thumbnail: blackroom,
            voucherID: room2VoucherTokenID,
            limit: room2VoucherAmount
        }
    ]

    useEffect(() => {
        console.log(room1VoucherAmount)
        console.log(room2VoucherAmount)
        console.log(tdtm.length)
    }, [])

    const amountInput = (min, max, disabled) =>
        <div className={styles.quantity + ' ' + (disabled ? styles.disabled : '')}>
            <button className={styles.inputButton}
                onClick={() => parseInt(quantity) > min ? setQuantity(parseInt(quantity) - 1) : null}
                disabled={parseInt(quantity) <= min}
            >
                <MinusIcon />
            </button>
            <input value={quantity} type='number' min={min} max={max}
                onChange={(e) => { if (parseInt(e.target.value) >= min && parseInt(e.target.value) <= max) setQuantity(e.target.value) }} />
            <button className={styles.inputButton}
                onClick={() => parseInt(quantity) < max ? setQuantity(parseInt(quantity) + 1) : null}
                disabled={parseInt(quantity) >= max}
            >
                <AddIcon />
            </button>
        </div>

    // Step 0
    const selectRoom = () => (
        <div className={styles.row + ' ' + styles.selectRoom}>
            <ul>
                <li>You are allowed to redeem TD-ShowRoom NFT or TD-ShowRoom Twilight Edition NFT, between 2022/09/30 00:00 and 2022/11/30 23:59 (UTC+8).</li>
                <li>When holding a ShowRoom NFT in your wallet, you can curate exhibitions within it by arranging the NFTs in this ShowRoom using a WYSIWYG editor provided in akaVerse.</li>
                <li>akaVerse Official Website: <a target='_blank' rel='noreferrer' href='https://akaverse.app'> https://akaverse.app </a></li>
            </ul>
            <h4 className={styles.stepTitle}>Select the ShowRoom you want to redeem </h4>
            <div className={styles.row + ' ' + styles.roomType}>
                {roomType.map((room, i) =>
                    <div className={styles.room} key={i} onClick={() => selectRoomType(i)} >

                        <div className={styles.thumbnail}><div className={styles.roomOverlay} /><img src={room.thumbnail} /></div>
                        <div className={styles.roomName}>{room.name}</div>
                    </div>
                )}
            </div>
        </div >
    )

    // Step 1
    const redeemRoom = () => (
        <div className={styles.row + ' ' + styles.redeemRoom}>
            <div className={styles.left}>
                <div className={styles.thumbnail}><img src={roomType[roomTypeIndex].thumbnail} /></div>
                <div>{roomType[roomTypeIndex].name}</div>
            </div>
            <div className={styles.right}>
                <ul>
                    <li>
                        <div className={styles.description}>
                            When redeeming this voucher, your wallet needs to own at least one TD-ShowRoom
                            {roomType[roomTypeIndex].name == 'TD-ShowRoom' ?
                                'Voucher and one TDTM.'
                                : 'Twillight Edition Voucher, one TDTM and one TDG.'}
                        </div>
                        <div className={styles.row}>
                            <div className={styles.checkVoucher + ' ' + (roomType[roomTypeIndex].limit > 0 ? '' : styles.disabled)}>
                                {roomType[roomTypeIndex].limit > 0 && <TickIcon />}
                            </div>
                            <div className={styles.checkTDTM + ' ' + (tdtm.length > 0 ? '' : styles.disabled)}>
                                {tdtm.length > 0 && <TickIcon />}
                            </div>
                            {roomTypeIndex === 1 && <div className={styles.checkTDG + ' ' + (tdg.length > 0 ? '' : styles.disabled)}>
                                {tdg.length > 0 && <TickIcon />}
                            </div>}
                        </div>
                    </li>
                    <li>
                        Select the amount you want to redeem
                        {amountInput(1, roomType[roomTypeIndex].limit, !tdtm.length || !roomType[roomTypeIndex].limit || (!roomTypeIndex === 1 && !tdg.length))}
                    </li>
                </ul>
                <button className={styles.redeemButton} onClick={() => handleRedeem(parseInt(quantity))}
                    disabled={!tdtm.length || (!tdg.length && roomTypeIndex === 1) || roomType[roomTypeIndex].limit < parseInt(quantity)}>
                    {!roomType[roomTypeIndex].limit ? 'Voucher Needed'
                        : (!tdtm.length ? 'TDTM Needed'
                            : !tdg.length && roomTypeIndex) === 1 ? 'TDG Needed'
                            : 'Redeem'}
                </button>
            </div>
        </div >
    )

    // Step 2
    const processing = () => (
        <div className={styles.container}>
            <div className={styles.loading}></div>
            <div>{resultMessage}</div>
        </div>
    )

    // Step 3
    const result = (success) => {
        function onContinue() {
            setStep(0)
        }
        function onFinish() {
            setIsOpened(false)
        }
        function onBack() {
            setStep(step - 2)
        }
        return (
            <>
                <div className={styles.container + ' ' + styles.result}>
                    <div className={styles.resultIcon}>{success ? <SuccessIcon /> : <ErrorIcon />}</div>
                    <div className={styles.resultMessage}>
                        {resultMessage}
                    </div>
                    {success ? <button onClick={onContinue}>Continue</button>
                        : <button onClick={onBack}>Back</button>}
                    <button onClick={onFinish}>Finish</button>
                </div>
            </>
        )
    }

    return (
        <Modal setIsOpened={setIsOpened}>

            <h3 className={styles.title}>
                <div className={styles.titledecoLeft}></div>
                ShowRoom-Reception
                <div className={styles.titledecoRight}></div>
            </h3>

            {step === 1 && <button className={styles.backButton} onClick={() => setStep(0)}>back</button>}
            {step === 0 && selectRoom()}
            {step === 1 && redeemRoom()}
            {step === 2 && processing()}
            {step === 3 && result(success)}

        </Modal>
    )
}

export default RoomModal