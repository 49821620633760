import ReactPixel from 'react-facebook-pixel';

export const Pixel = () => {
    const options = {
        autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('494004378520837', null, options);
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
}
export const PageView = () => {
    ReactPixel.pageView();
} // For tracking page view

export const completeRegistration = () => {
    ReactPixel.track('CompleteRegistration');
}
