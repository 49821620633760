import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
// import { useHistory } from 'react-router'

export const Page = ({ children, className, top }) => {
    return (
        <div className={`${styles.pageContainer} ${className ? className : ''} ${top ? styles.top : ''}`}>
            {children}
        </div >
    )
}

export const Container = ({ children, className, full, larger , smaller}) => {
    return (
        <div className={`${styles.Container} ${className ? className : ''} ${larger ? styles.larger : ''}  ${smaller ? styles.smaller : ''}`}>
            {children}
        </div>
    )
}
export const Section = ({ children, className, id, full, onScrollTop = () => null, scrollOffset = 30 }) => {
    // function onScroll() {
    //     const location = window.location.toString().split('#')[0];
    //     history.replaceState(null, null, location + '#' + hash.name);
    // }
    // function getWindowDimensions() {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     return {
    //         width,
    //         height
    //     }
    // }
    // function handleResize() {
    //     setWindowDimensions(getWindowDimensions())
    // }
    // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const sectionRef = useRef(null)
    const [added, setAdded] = useState(false);
    var scrolledTop = false
    function getScrollTop(offset) {
        if (!sectionRef.current)
            return false;
        var top = sectionRef.current.getBoundingClientRect().top
        var bottom = sectionRef.current.getBoundingClientRect().bottom
        return (top < offset && bottom > offset)
    }
    // const history = useHistory()
    const handleScroll = (onScrollTop, scrollOffset) => {
        if ((getScrollTop(scrollOffset)) && !scrolledTop) {
            scrolledTop = true
            onScrollTop(); //<--- Bug Here
        }
        else if (!getScrollTop(scrollOffset) && scrolledTop) {
            scrolledTop = false
        }

    }

    useEffect(() => {
        if (!added) {
            window.addEventListener('scroll', () => handleScroll(onScrollTop, scrollOffset), { passive: true })
            // window.addEventListener('resize', handleResize)
            setAdded(true)
        }
        return () => {
            window.removeEventListener('scroll', () => handleScroll(onScrollTop, scrollOffset))
            // window.removeEventListener('resize', handleResize)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={sectionRef} className={`${styles.Section} ${className ? className : ''} ${full ? styles.full : ''}`} id={id}
        >
            {children}
        </div>
    )
}
export const Row = ({ children, className }) => {
    return (
        <div className={styles.Row + ' ' + className}>
            {children}
        </div>
    )
}
export const Col = ({ children, className }) => {
    return (
        <div className={styles.Col + ' ' + className}>
            {children}
        </div>
    )
}