import { Home } from './pages/home'
import { Reveal } from './pages/reveal'
import { Fomo_reg } from './pages/fomo-register'
// import { ENV } from './context/constant'
import { Auth } from './pages/discord-auth'
import { Gallery } from './pages/gallery'
import { RoarAuction } from './pages/roar-auction'
import { DSSwap } from './pages/ds-swap'
import { DozenVerse } from './pages/dozenverse'
import { DaoStore } from './pages/dao-store'

export const routes = [
    {
        exact: true,
        path: '/',
        component: Home,
    },
    {
        exact: true,
        path: '/test',
        component: Home,
    },
    {
        exact: true,
        path: '/fomo',
        component: Fomo_reg,
    },
    {
        exact: true,
        path: '/gallery',
        component: Gallery,
    },
    {
        exact: true,
        path: '/dcauth/:nonce',
        component: Auth,
    },
    {
        exact: true,
        path: '/roar-auction',
        component: RoarAuction,
    },
    {
        exact: true,
        path: '/dao-store-swap',
        component: DSSwap,
    },
    {
        exact: true,
        path: '/DoZenVerse',
        component: DozenVerse,
    },
    {
        exact: true,
        path: '/dao-store',
        component: DaoStore,
    },
    {
        exact: true,
        path: '/reveal',
        component: Reveal,
    }
]