import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { Timer } from '../../components/timer'
import { AuctionButton, SyncButton } from '../../components/syncButton'
import { parseDateTime } from '../../datetime'
import { TezDozenContext } from '../../context/TezDozenContext'
import { apiUrl, auctionContract, auctionStart, auctionEnd } from '../../context/constant'

export const RoarAuction = ({ headerControl = () => null }) => {
    const header = (args) => headerControl(args)
    const today = new Date()
    const startTime = parseDateTime(auctionStart)
    const endTime = parseDateTime(auctionEnd)
    const [started, setStarted] = useState(true)
    const [ended, setEnded] = useState(false)
    const context = useContext(TezDozenContext)
    const [soldAmount, setSoldAmount] = useState(0)
    const [maxSupply, setMaxSupply] = useState(2)
    const [currentPrice, setCurrentPrice] = useState(120)
    const [bidPrice, setBidPrice] = useState(120)
    const [bid, setBid] = useState(null)
    const [bidStatus, setBidStatus] = useState(0) // 0: no bidded; >0: bid placed; -1: placing bid

    const axios = require('axios')

    function getSoldAmount(addr, updateInterval) {
        axios.get(`${apiUrl}/v1/contracts/${addr}/storage`)
            .then((res) => {
                if (res.data) {
                    setBid(res.data.collect_meta)
                    let amount = res.data.collect_meta ? Object.keys(res.data.collect_meta).length : 0
                    let total = res.data.max_supply
                    setSoldAmount(amount)
                    setBidPrice(res.data.current_price / 1000000)
                    if (res.data.start_price !== '120000000') {
                        let countDown = startTime - today
                        var temp = res.data.startPrice / 1000000 + 5
                        while (countDown <= 0) {
                            countDown += 300000  // +5minute
                            temp -= 5
                        }
                        setCurrentPrice(temp)
                    }
                    setMaxSupply(total)
                    if (amount == total) {
                        setEnded(true)
                        clearInterval(updateInterval)
                    }
                }else clearInterval(updateInterval)
            })
    }

    function updatePrice(price) {
        let now = new Date()
        if (now < endTime) {
            setCurrentPrice(price) // -5tez
            setTimeout(() => updatePrice(price - 5), 300000)
        }
        else {
            setBidPrice(currentPrice)
            setEnded(true)
        }
    }

    function startTimeFormat(){
        let temp = startTime.getFullYear() + '/' + (startTime.getMonth() + 1)+'/' + startTime.getDate() +
        ' '+ startTime.getHours() +':';
        
        temp = (startTime.getMinutes() < 10)
            ?temp + '0' + startTime.getMinutes()
            :temp + startTime.getMinutes()
    
        return temp;
    }

    useEffect(() => {
        header({ fixed: true, showMenu: false })
        getSoldAmount(auctionContract)
        const soldupdate = setInterval(() => {
            getSoldAmount(auctionContract, soldupdate)  // refresh amount left every 10s
        }, 10000);

        var countDown = 0
        countDown = startTime - today
        if (countDown >= 0) {
            // if auction not started
            setStarted(false)
            setTimeout(() => {
                setStarted(true)
                updatePrice(currentPrice)
            }, countDown)

        } else if (today < endTime) {
            // if auction started, calculate next pricedrop time
            setStarted(true)
            var temp = 125
            while (countDown <= 0) {
                countDown += 300000  // +5minute
                temp -= 5
            }
            setCurrentPrice(temp)

            // in next pricedrop
            setTimeout(() => {
                updatePrice(temp-5)
            }, countDown)

        } else {
            setEnded(true)
            clearInterval(soldupdate)
        }
        
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [started])
    return (
        <>
            <Page top>
                <Section
                    id='Home' className={styles.homeSection}>

                    <div className={styles.full} y={[-80, 80]}>
                        <div className={styles.bg}></div>
                    </div>
                    <div className={styles.full} y={[-30, 30]}>
                        <img alt='' className={styles.decoLeft} src={`./images/teams/roar/deco_left.png`} />
                        <img alt='' className={styles.decoRight} src={`./images/teams/roar/deco_right.png`} />
                    </div>
                    <div className={styles.full}>
                        <div className={styles.butterfly}></div>
                        <div className={styles.bee}></div>
                    </div>
                    <div y={[0, 0]}>
                        <Container className={styles.content}>
                            <div className={styles.text}>
                                <img alt='Tez Dozen' className={styles.titleImage} src={'./images/title_2.png'} />

                                <h2>Dutch Auction for TDG-Roar *10</h2>
                                <p className={styles.auctiondate}>
                                    {!started 
                                        ? 'Auction will start on ' + startTimeFormat() + ' (UTC+8).' 
                                        : ended 
                                            ? 'Auction Ended' : 'Auction Active'
                                    }</p>

                                {ended ?
                                    <div className={styles.complete}>
                                        <img alt='' className={styles.meowImage} src='./images/teams/roar/complete.png' />
                                        <div className={styles.teamName}>Tez Dozen Guardian Roar</div>
                                        <div className={styles.teamName}>Hammer Price : {bidPrice} xtz</div>
                                    </div>
                                    :
                                    <div className={styles.row}>
                                        <div className={styles.left}>
                                            <img alt='' className={styles.meowImage} src='./images/teams/roar/comingSoon.png' />
                                            <div className={styles.teamName}>Tez Dozen Guardian Roar</div>
                                        </div>
                                        <div className={styles.right}>
                                            <div className={styles.label}>Time Remaining</div>
                                            <div className={styles.countDown}>
                                                {started
                                                    ? <Timer time={endTime} type="dhms" />
                                                    : <><big>1</big> hr <big>5</big> min <big>0</big> sec</>
                                                }</div>
                                            <div className={styles.label}>Roar Remaining</div>
                                            <div className={styles.soldAmount}><big>{maxSupply - soldAmount}</big>/{maxSupply}</div>
                                            <div className={styles.label}>Current Price</div>
                                            <div className={styles.soldAmount}><big>{currentPrice}</big> xtz</div>
                                            <div className={styles.actionButton}>
                                                {!context.acc
                                                    ? <SyncButton feature='sync' className={styles.enrollButton} />
                                                    : !started
                                                        ? <button className={styles.enrollButton} disabled><span>Coming Soon</span></button>
                                                        : (bid && bid[context.address])
                                                            ? <button className={styles.enrollButton} disabled><span>Bade with {bid[context.address].pay / 1000000} xtz</span></button>
                                                            : <AuctionButton auctionContract={auctionContract} currentPrice={currentPrice} className={styles.enrollButton} setBidStatus={setBidStatus}></AuctionButton>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                }

                                {(bidStatus > 0 || (bid && bid[context.address]))
                                    ? <>
                                        <p className={styles.hint}>
                                            <div className={styles.hintDecoLeft}></div>
                                            Congratulation!<br />
                                            You have successfully bid!
                                            <div className={styles.hintDecoRight}></div>
                                        </p>
                                        <ul className={styles.hintpoint}>
                                            <li>Guardian Roar and transaction refund will be airdrop to your wallet after the auction. </li>
                                            <li>Guardian Roar will reveal at 2022/3/31 00:00 (UTC+8).</li>
                                        </ul>
                                    </>
                                    : <ul className={styles.hintpoint}> <li>Dutch Auction Starts at 120 xtz and decreases every 5 minutes until 60 xtz. Duration: maximum 65 minutes.</li>
                                        <li>The auction will close when all items are sold out or when the auction time is over.</li>
                                        <li>The price of the last bid will be used as the final sell price for all bidders. After the auction ends, the smart contract will automatically refund the difference between each bid price and the hammer price.</li>
                                        <li>Limit one purchase per wallet.</li>
                                    </ul>}
                            </div>
                        </Container>
                    </div>
                </Section>

            </Page >
        </>
    )
}