import React, { useContext, useEffect, useState, useRef } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as AkaswapSmall } from './img/akaswap_small.svg'
import { ReactComponent as ObjktSmall } from './img/objkt_small.svg'
import { ReactComponent as AuctionIcon } from './img/auction.svg'
import { ReactComponent as GachaIcon } from './img/gacha.svg'
import { ReactComponent as BundleIcon } from './img/bundle.svg'
import { ReactComponent as OpenIcon } from './img/open.svg'
import { ReactComponent as FilterButton } from './img/filter.svg'
import { ReactComponent as SearchIcon } from './img/ic_search.svg'
import { ReactComponent as CloseButton } from './img/closeButton.svg'
import { ReactComponent as FilterIcon } from './img/filter_options.svg'
import { ReactComponent as GoldBackIcon } from './img/goldcard_icon.svg'
import { ReactComponent as ArtistIcon } from './img/artist_icon.svg'
import { parseDateTime } from '../../datetime'
import { Accordion } from '../accordion'
import { TezDozenContext } from '../../context/TezDozenContext'
import { hex_to_ascii } from '../../hexString'
import cardbacks from '../../data/CardBack.json'

const axios = require('axios')

export const GalleryTab = ({ team, onClick, selected }) => {
    const [revealed, setRevealed] = useState(false)   // set default page to 1
    const getRevealed = () => {
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        setRevealed(team.generalMintStart ? (parseDateTime(team.generalMintStart) <= yesterday) : true)
    }
    var image = (team.imageFolder && team.imageFolder !== "artist_original") ? `/images/teams/${team.imageFolder}/${selected ? 'profileIconWhite' : 'profileIcon'}.png` : null
    useEffect(() => {
        getRevealed()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })
    return (
        revealed && <button className={`${styles.galleryTab} ${selected ? styles.selected : ''} ${image ? styles.iconPadding : ''}`} onClick={onClick}>
            {image && <img src={image} className={styles.tabIcon} alt=' ' />}
            {team.team}
        </button>
    )
}
export const FilterOption = ({ isActive, value, rarity, onClick = () => null, color, isStatus }) => {
    function handleOnlick() {
        onClick()
    }
    return (
        <div className={`${isStatus ? styles.status : styles.option} ${isActive ? styles.active : ''}`} onClick={() => { handleOnlick() }}>
            {(color && value !== 'tex' && value !== 'special_ed')
                ? <><span style={{ backgroundColor: value, height: '1rem', width: '1rem', borderRadius: "2rem", marginRight: "0.5rem" }}></span>{value}</>
                : value}
            {rarity && <div className={styles.rarity}>{rarity}%</div>}
        </div>
    )
}
export const GalleryPanel = ({ team, onSubmit = () => null, TeamAttrs, StatusOptions }) => {
    const [searchID, setSearchID] = useState(null)
    const [isSidebarOpened, setIsSidebarOpened] = useState(false)

    function toggleSidebar(condition = true) {
        if (condition) {
            setIsSidebarOpened(!isSidebarOpened)
        }
    }

    function handleSearchInput(e) {
        var id = null
        if (e.target.value !== '')
            id = parseInt(e.target.value) + team.tokenStart - 1
        if (team.breakPoint)
            id = (id - team.tokenStart + team.breakPoint > team.tokenEnd) ? (id - team.tokenEnd + team.breakPoint - 1) : (id - team.tokenStart + team.breakPoint)
        setSearchID(id)
    }

    function handleSearchSubmit() {
        var searchStr = ''
        if (searchID !== null && searchID >= team.tokenStart && searchID <= team.tokenEnd)
            searchStr += `&id=${searchID}`
        onSubmit(searchStr)
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13)
            handleSearchSubmit()
    }

    return (
        <>
            <div onClick={(e) => toggleSidebar(e.target === e.currentTarget)} className={styles.sidebarOverlay + ' ' + (isSidebarOpened ? '' : styles.hide)}>
                <div className={styles.sidebar} id='sidebar'>
                    <button onClick={(e) => toggleSidebar()} className={styles.sidebarToggle}>{isSidebarOpened ? <CloseButton /> : <FilterButton />}</button>
                    <div className={styles.galleryPanel}>
                        <h3>{team.team}</h3>
                        <div className={styles.gallerySearchBox}>
                            <input type="number" placeholder="Search by ID" onChange={(e) => handleSearchInput(e)} onKeyDown={handleKeyDown} />
                            <div className={styles.SearchIcon} onClick={() => handleSearchSubmit()}><SearchIcon /></div>
                        </div>
                        <h3>Status</h3>
                        <div className={styles.galleryStatus}>
                            {StatusOptions}
                        </div>
                        <h3>Filters</h3>
                        <div className={styles.galleryOptions}>
                            {TeamAttrs}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
export const GalleryPage = ({ items, pageSize, navbar, activeAttrs, objktSales, objktAuctions, start }) => {
    const [currentPage, setCurrentPage] = useState(0)   // set default page to 1
    const [nav, setNav] = useState(null)
    const handleChangePage = (i) => {
        setCurrentPage(i)
        navbar.current.scrollIntoView()
    }
    const [tdtm, setTdtm] = useState(null)

    const pageBtn = (i) => (
        <button key={i} className={currentPage === i ? styles.active : ''} onClick={() => handleChangePage(i)}>{i + 1}</button>
    )
    useEffect(() => {
        var buttons = []
        if (items) {
            // select item according to page
            for (var i = 0; i < items.length / pageSize; i++) {
                buttons.push(pageBtn(i))
            }
            setNav(buttons)
            setTdtm(items.map(({ id }, i) => (i < pageSize * (currentPage + 1) && i >= pageSize * (currentPage))
                ? <GalleryItem key={i} id={id} objktSale={objktSales[id - start]} objktAuction={objktAuctions[id - start]} />
                : null))
        }
        else {
            // fill id 0 (loading state) when items not ready
            var arr = Array(pageSize).fill(0);
            var ItemArr = arr.map((id, i) => <GalleryItem key={i} id={id} />)
            setTdtm(ItemArr)
            setCurrentPage(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, items, objktSales, objktAuctions])

    return (
        <div className={styles.galleryResult}>
            <div className={styles.activeFilters}>
                {activeAttrs}
            </div>
            <div className={styles.galleryGrid}>
                {tdtm}
            </div>
            <div className={styles.galleryPageNav}>
                {nav && nav.map((b, i) => b)}
            </div>
        </div>
    )
}

export const GalleryItem = ({ id, className, objktSale, objktAuction }) => {

    // get info from akaSwap Api
    const [name, setName] = useState("---") // get name from akaswap api
    const [IPFS, setIPFS] = useState('/images/gallery_loading.gif')
    const [artifact, setArtifact] = useState('/images/gallery_loading.gif')
    const [attr, setAttr] = useState(null)
    const [sale, setSale] = useState(null)
    async function getInfo(tokenID) {
        setIPFS('/images/gallery_loading.gif')
        setName('---')
        if (id > 0) {
            const contractAddr = id > 3360 ? 'KT1ShjqosdcqJBhaabPvkCwoXtS1R2dEbx4W' : 'KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax'
            const apiUrl = 'https://api.akaswap.com/v2/'
            var artifactUri, displayUri
            var token = await axios.get(`${apiUrl}fa2tokens/${contractAddr}/${(tokenID - 1) % 3360 + 1}`)
                .then((res) => {
                    if (res.data.length === 0)
                        return null
                    else {
                        return res.data
                    }
                })
                .catch((e) => console.log(e))
            if (token) {
                setName(token.name.split('Tez Dozen ')[1])
                setSale(token.sale)
                var attrs = {}
                token.attributes.forEach(({ name, value }) => { attrs[name] = value })
                setAttr(attrs)
                displayUri = token.displayUri.replace('ipfs://', `https://assets.akaswap.com/ipfs/`)
                artifactUri = token.artifactUri.replace('ipfs://', `https://assets.akaswap.com/ipfs/`)
            } else {
                // if burned, get ipfs addr from tzkt api
                var ipfs = await axios.get(`https://api.tzkt.io/v1/bigmaps/23779/keys/${id}`)
                    .then((res) => {
                        if (res.data.length === 0)
                            return null
                        else {
                            return hex_to_ascii(res.data.value.token_info[''])
                        }
                    })
                    .catch((e) => console.log(e))

                if (ipfs) {
                    token = await axios.get(ipfs.replace('ipfs://', 'https://assets.akaswap.com/ipfs/'))
                        .then((res) => {
                            if (res.data.length === 0)
                                return null
                            else {
                                return res.data
                            }
                        })
                        .catch((e) => console.log(e))

                    if (token) {
                        setName(token.name.split('Tez Dozen ')[1])
                        setSale('burn')
                        var temp = {}
                        token.attributes.forEach(({ name, value }) => { temp[name] = value })
                        setAttr(temp)
                        displayUri = token.displayUri.replace('ipfs://', `https://assets.akaswap.com/ipfs/`)
                        artifactUri = token.artifactUri.replace('ipfs://', `https://assets.akaswap.com/ipfs/`)
                    }
                }
            }
            setIPFS(displayUri)
            setArtifact(artifactUri)
            return displayUri
        }
    }

    const [isOpened, setIsOpened] = useState(false)

    useEffect(() => {
        getInfo(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <>
            {(true ||
                !sale || sale.swaps.length > 0 || sale.bundles.length || sale.gachas.length > 0) &&
                <div className={styles.galleryItem + ' ' + (className ? className : '')}>
                    {sale === 'burn' ?
                        <div className={styles.image + ' ' + styles.burn}>
                            <div className={styles.clickable}
                                onClick={() => { setIsOpened(true) }}
                            ></div>
                            {IPFS && <img alt={id} src={IPFS}></img>}
                        </div>
                        : <div className={styles.image}>
                            <div className={styles.clickable}
                                onClick={() => { setIsOpened(true) }}
                            ></div>
                            {IPFS && <img alt={id} src={IPFS}></img>}
                        </div>}
                    <div className={styles.info}>
                        <div className={styles.name}>{name}
                            {id <= 3360 && cardbacks[id].isGoldBack && <GoldBackIcon />}
                            {attr && attr['Class'] !== 'regular' && <ArtistIcon />}
                        </div>
                        {sale === 'burn' ? <div className={styles.price + ' ' + styles.notforsale}>Burned</div>
                            : sale && sale.swaps.length > 0 ?
                                <div>
                                    <AkaswapSmall />
                                    <div className={styles.price}>{sale.swaps[0].xtzPerToken / 1000000} xtz </div>
                                    {id > 3360
                                        ? <a target='_blank' rel='noreferrer' href={'https://akaswap.com/td-guardian/' + ((id - 1) % 3360 + 1)}><OpenIcon /></a>
                                        : <a target='_blank' rel='noreferrer' href={'https://akaswap.com/tezdozen/' + id}><OpenIcon /></a>
                                    }
                                </div>
                                : sale && sale.auctions.length > 0 ?
                                    <div>
                                        <AkaswapSmall />
                                        <AuctionIcon />
                                        <div className={styles.price}>{(sale.auctions[0].currentBidPrice === 0 ? sale.auctions[0].startPrice : sale.auctions[0].currentBidPrice) / 1000000} xtz </div>
                                        {id > 3360
                                            ? <a target='_blank' rel='noreferrer' href={'https://akaswap.com/td-guardian/' + ((id - 1) % 3360 + 1)}><OpenIcon /></a>
                                            : <a target='_blank' rel='noreferrer' href={'https://akaswap.com/tezdozen/' + id}><OpenIcon /></a>
                                        }
                                    </div>
                                    : sale && sale.bundles.length > 0 ?
                                        <div>
                                            <AkaswapSmall />
                                            <BundleIcon />
                                            <div className={styles.price}>{sale.bundles[0].xtzPerBundle / 1000000} xtz </div>
                                            {id > 3360
                                                ? <a target='_blank' rel='noreferrer' href={'https://akaswap.com/td-guardian/' + ((id - 1) % 3360 + 1)}><OpenIcon /></a>
                                                : <a target='_blank' rel='noreferrer' href={'https://akaswap.com/tezdozen/' + id}><OpenIcon /></a>
                                            }
                                        </div>
                                        : sale && sale.gachas.length > 0 ?
                                            <div>
                                                <AkaswapSmall />
                                                <GachaIcon />
                                                <div className={styles.price}>{sale.gachas[0].xtzPerGacha / 1000000} xtz </div>
                                                {id > 3360
                                                    ? <a target='_blank' rel='noreferrer' href={'https://akaswap.com/td-guardian/' + ((id - 1) % 3360 + 1)}><OpenIcon /></a>
                                                    : <a target='_blank' rel='noreferrer' href={'https://akaswap.com/tezdozen/' + id}><OpenIcon /></a>
                                                }
                                            </div>
                                            : objktSale ?
                                                <div>
                                                    <ObjktSmall />
                                                    <div className={styles.price}>{objktSale / 1000000} xtz </div>
                                                    {id > 3360
                                                        ? <a target='_blank' rel='noreferrer' href={'https://objkt.com/asset/tezdozen_guardian/' + ((id - 1) % 3360 + 1)}><OpenIcon /></a>
                                                        : <a target='_blank' rel='noreferrer' href={'https://objkt.com/asset/tezdozen/' + id}><OpenIcon /></a>
                                                    }
                                                </div>
                                                : objktAuction ?
                                                    <div>
                                                        <ObjktSmall />
                                                        <AuctionIcon />
                                                        <div className={styles.price}>{objktAuction / 1000000} xtz </div>
                                                        {id > 3360
                                                            ? <a target='_blank' rel='noreferrer' href={'https://objkt.com/asset/tezdozen_guardian/' + ((id - 1) % 3360 + 1)}><OpenIcon /></a>
                                                            : <a target='_blank' rel='noreferrer' href={'https://objkt.com/asset/tezdozen/' + id}><OpenIcon /></a>
                                                        }
                                                    </div>
                                                    : <div className={styles.price + ' ' + styles.notforsale}>Not for Sale</div>
                        }
                    </div>
                </div >
            }
            {(isOpened && attr !== undefined) && <GalleryModal
                id={id}
                name={name}
                artifact={artifact}
                attr={attr}
                setIsOpened={() => setIsOpened()}
                isGuardian={id > 3360} />}
        </>
    )
}

export const GalleryModal = ({ id, name, artifact, attr, setIsOpened = () => null, isGuardian = false }) => {
    const context = useContext(TezDozenContext)
    const rarity = isGuardian ? context.tgRarity : context.rarity
    const akaswapLink = `https://akaswap.com/${isGuardian ? "td-guardian" : 'tezdozen'}/${id % 3360}`
    const objktLink = `https://objkt.com/asset/${isGuardian ? "tezdozen_guardian" : 'tezdozen'}/${id % 3360}`
    var cardback = artifact
    // if cardback not ready, load artifactUri
    if (cardbacks[id] && !isGuardian) {
        cardback = `https://assets.akaswap.com/ipfs/${cardbacks[id].artifactUri}`
    }
    return (
        < div className={styles.modalOverlay} onClick={(e) => { if (e.target === e.currentTarget) setIsOpened(false) }}>
            <div className={styles.galleryModal}>
                <div className={styles.closeButton} onClick={() => { setIsOpened(false) }}>
                    <CloseButton />
                </div>
                <div className={styles.right}>
                    <div className={styles.image}>
                        <img src={cardback} alt=' ' />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.tokenName}>{name}</div>
                        <div className={styles.mainAttr}>Class - {attr.Class}</div>
                        {!isGuardian && < div className={styles.mainAttr}>CardBank - {attr.CardBank ? attr.CardBank : 'Hidden'}</div>}
                    </div>
                </div>
                <div className={styles.left}>
                    {attr && rarity && <div className={styles.attrTable}>
                        {Object.keys(attr).map((attrName, i) => {
                            if (attrName !== 'Team' && attrName !== 'Class' && attrName !== 'CardBank') return (
                                <React.Fragment key={i}>
                                    <div className={styles.attrName}>{attrName} :</div>
                                    <div className={styles.attrValue}>{attr[attrName]}</div>
                                    <div className={styles.attrAmount}>{Math.floor(id > 3360 ? (rarity[attr.Team][attrName][attr[attrName]] * 400 / 100) : rarity[attr.Team][attrName][attr[attrName]] / rarity[attr.Team]['Class']['artist_original'] * (id > 1440 ? 12 : 6) + 0.5)}</div>
                                    <div className={styles.attrPercent}>{rarity[attr.Team][attrName][attr[attrName]]}%</div>
                                </React.Fragment>
                            )
                            else return null
                        }
                        )}
                    </div>}
                    <div className={styles.viewButtons}>
                        <a target='_blank' rel='noreferrer' href={akaswapLink}>View on akaSwap <OpenIcon /></a>
                        <a target='_blank' rel='noreferrer' href={objktLink}>View on objkt <OpenIcon /></a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export const GallerySection = ({ teams, pageSize, showFilter, select }) => {
    const [selectedTab, setSelectedTab] = useState(showFilter ? 0 : null) // set default team to alpha
    const [teamItems, setTeamItems] = useState(null)
    const [objktSales, setObjktSales] = useState(Array(120).fill(0))
    const [objktAuctions, setObjktAuctions] = useState(Array(120).fill(0))
    const [rarity, setRarity] = useState(null)
    const navRef = useRef(null)
    const context = useContext(TezDozenContext)

    function getObjktSales() {
        const fa2Addr = selectedTab < 12 ? "KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax" : "KT1ShjqosdcqJBhaabPvkCwoXtS1R2dEbx4W"
        const objktBigmap = 5909
        const objktv2Bigmap = 103258
        const auctionBigmap = 6210
        const auctionv2Bigmap = 103262
        var rangeStart = 1
        var rangeEnd = 10000
        if (teams[selectedTab].tokenEnd) {
            rangeStart = (teams[selectedTab].tokenStart - 1) % 3360 + 1
            rangeEnd = (teams[selectedTab].tokenEnd - 1) % 3360 + 1
        }
        var urlAuction = `https://api.tzkt.io/v1/bigmaps/${auctionBigmap}/keys?value.fa2=${fa2Addr}&value.objkt_id.ge=${rangeStart}&value.objkt_id.le=${rangeEnd}&sort.asc=id&value.state=0`
        var urlAuctionV2 = `https://api.tzkt.io/v1/bigmaps/${auctionv2Bigmap}/keys?value.token.address=${fa2Addr}&value.token.token_id.ge=${rangeStart}&value.token.token_id.le=${rangeEnd}&sort.asc=id&value.state=0`
        var urlSale = `https://api.tzkt.io/v1/bigmaps/${objktBigmap}/keys?value.fa2=${fa2Addr}&value.objkt_id.ge=${rangeStart}&value.objkt_id.le=${rangeEnd}&sort.asc=id&active=true`
        var urlSaleV2 = `https://api.tzkt.io/v1/bigmaps/${objktv2Bigmap}/keys?value.token.address=${fa2Addr}&value.token.token_id.ge=${rangeStart}&value.token.token_id.le=${rangeEnd}&sort.asc=id&active=true`
        console.log(rangeEnd - rangeStart + 1)
        var sales = Array(rangeEnd - rangeStart + 1).fill(null)
        var auctions = Array(rangeEnd - rangeStart + 1).fill(null)
        // var sales = Array(rangeEnd - rangeStart + 1).fill(null)
        // var auctions = Array(rangeEnd - rangeStart + 1).fill(null)
        axios.get(urlSale)
            .then((res) => {
                if (res.data[0]) {
                    for (var i = 0; i < res.data.length; i++) {
                        var id = parseInt(res.data[i].value.objkt_id) - rangeStart
                        // console.log(id + " " + i)
                        sales[id] = parseInt(res.data[i].value.xtz_per_objkt)
                    }
                    // console.log(sales)
                    setObjktSales(sales)
                }
            })
        axios.get(urlSaleV2)
            .then((res) => {
                if (res.data[0]) {
                    for (var i = 0; i < res.data.length; i++) {
                        var id = parseInt(res.data[i].value.token.token_id) - rangeStart
                        // console.log(id + " " + i)
                        sales[id] = parseInt(res.data[i].value.amount)
                    }
                    // console.log(sales)
                    setObjktSales(sales)
                }
            })
        axios.get(urlAuction)
            .then((res) => {
                if (res.data[0]) {
                    for (var i = 0; i < res.data.length; i++) {
                        var id = parseInt(res.data[i].value.objkt_id) - rangeStart
                        // console.log(id + " " + i)
                        auctions[id] = parseInt(res.data[i].value.current_price) ? parseInt(res.data[i].value.current_price) : parseInt(res.data[i].value.reserve)
                    }
                    setObjktAuctions(auctions)
                }
                // console.log(res.data)
            })
        axios.get(urlAuctionV2)
            .then((res) => {
                if (res.data[0]) {
                    for (var i = 0; i < res.data.length; i++) {
                        var id = parseInt(res.data[i].value.token.token_id) - rangeStart
                        // console.log(id + " " + i)
                        auctions[id] = parseInt(res.data[i].value.current_price) ? parseInt(res.data[i].value.current_price) : parseInt(res.data[i].value.reserve)
                    }
                    setObjktAuctions(auctions)
                }
                // console.log(res.data)
            })
    }

    // get team from DB
    function getTeam(team, option) {
        setTeamItems(null) // clear previous team while waiting
        var teamName = team.imageFolder
        if (!option && teamName !== 'artist_original') {
            var array = [];
            if (teamName == 'growl' || teamName == 'roar') {
                for (var i = team.breakPoint; i <= team.tokenEnd; i++) {
                    array.push({ id: i })
                }
                for (var i = team.tokenStart; i < team.breakPoint; i++) {
                    array.push({ id: i })
                }
            } else {
                for (var i = team.tokenStart; i <= team.tokenEnd; i++) {
                    array.push({ id: i })
                }
            }
            setTeamItems(array)
        } else {
            if (!option) option = ''
            var uri = `https://tools.tezdozen.xyz/gallery/query?${teamName === 'artist_original' ? 'Class' : 'Team'}=${teamName}${option}&limit=999${teamName === 'meow' ? '&IsGuardian=True' : ''}`
            // console.log(uri)
            axios.get(uri)
                .then((res) => {
                    setTeamItems(Object.values(res.data)) // convert key to array
                })
        }
    }
    const handleSearch = (str) => {
        getTeam(teams[selectedTab], str)
    }

    const [activeAttrs, setActiveAttrs] = useState(null)
    const [TeamAttrs, setTeamAttrs] = useState(null)
    const [StatusOptions, setStatusOptions] = useState(null)
    async function getAttrs() {
        var attrs = null
        await axios.get(`https://akaswap.com/data/list/rarity/tezdozen_rarity.json`)
            .then((res) => {
                if (res.data.length !== 0) {
                    attrs = res.data
                    renderAttrs(attrs, {})
                    setRarity(attrs)
                }
                return true
            })
            .catch((e) => console.log(e))
    }
    function handleSubmit(activeAttr) {
        var searchStr = ''
        if (activeAttr !== {}) {
            Object.keys(activeAttr).forEach((name) => {
                if (activeAttr[name].length > 0) {
                    searchStr += `&${name}=`
                    activeAttr[name].forEach((value, i) => {
                        if (i > 0)
                            searchStr += '$'
                        searchStr += value.replace('#', '%23')
                    })
                }
            })
        }
        // console.log(searchStr)
        handleSearch(searchStr)
    }

    function handleAttrSelect(name, value, attrs, filter) {
        var temp = filter
        if (!temp[name]) {
            temp[name] = [value]
        } else {
            if (temp[name].includes(value)) {
                temp[name] = temp[name].filter(item => item !== value)
            } else {
                temp[name].push(value)
                if (temp[name].length === 0)
                    delete temp[name]
            }
        }
        renderAttrs(attrs, temp)
        handleSubmit(temp)
    }

    function clearAttrSelect(attrs) {
        renderAttrs(attrs, {})
        getTeam(teams[selectedTab])
    }

    function renderAttrs(attrs, filter) {

        var team = teams[selectedTab]
        // console.log(context.tgRarity)
        attrs = { ...attrs, ...context.tgRarity }
        // console.log(attrs)

        // render team attr, exclude Class and CardBank
        var temp = Object.keys(attrs[team.imageFolder]).map(
            (attr, i) => (
                attr !== 'Class' && attr !== 'CardBank' && <Accordion key={i} headerText={<><div className={styles.filterIcon}><FilterIcon /></div>{attr}</>} isOpened={false} className={styles.accordion} >
                    {Object.keys(attrs[team.imageFolder][attr]).map((value, j) =>
                        <FilterOption
                            isActive={filter && filter[attr] && filter[attr].includes(value)}
                            key={j}
                            value={value}
                            rarity={attrs[team.imageFolder][attr][value]}
                            onClick={() => { handleAttrSelect(attr, value, attrs, filter); }}
                            color={attr === 'Background'}
                        />)}
                </Accordion>
            )
        )
        setTeamAttrs(temp)

        // render On Sale, Artist Original && CardBank
        temp = <div className={styles.StatusOptions}>
            <FilterOption
                isActive={filter && filter['sellAt'] && filter['sellAt'].includes('AkaSwap$objkt')}
                value='On Sale'
                onClick={() => handleAttrSelect('sellAt', 'AkaSwap$objkt', attrs, filter)}
                isStatus
            />
            <FilterOption
                isActive={filter && filter['CardBank'] && filter['CardBank'].includes('sextuple')}
                value='Sextuple CardBank'
                onClick={() => handleAttrSelect('CardBank', 'sextuple', attrs, filter)}
                isStatus
            />
            <FilterOption
                isActive={filter && filter['Class'] && filter['Class'].includes('artist_original')}
                value='Artist Original'
                onClick={() => handleAttrSelect('Class', 'artist_original', attrs, filter)}
                isStatus
            />
        </div>
        setStatusOptions(temp)

        // render active attr
        var empty = true
        temp = Object.keys(filter).map((key) => {
            if (key === 'sellAt' && filter[key].includes('AkaSwap$objkt')) {
                empty = false
                return (
                    <div className={styles.activeFilter} onClick={() => handleAttrSelect(key, 'AkaSwap$objkt', attrs, filter)}>
                        {`On Sale x`}
                    </div>
                )
            } else if (key === 'CardBank' && filter[key].includes('sextuple')) {
                empty = false
                return (
                    <div className={styles.activeFilter} onClick={() => handleAttrSelect(key, 'sextuple', attrs, filter)}>
                        {`Sextuple CardBank x`}
                    </div>
                )
            } else if (key === 'Class' && filter[key].includes('artist_original')) {
                empty = false
                return (
                    <div className={styles.activeFilter} onClick={() => handleAttrSelect(key, 'artist_original', attrs, filter)}>
                        {`Artist Original x`}
                    </div>
                )
            } else {
                return (filter[key].map((value, i) => {
                    empty = false
                    return (
                        <div key={i} className={styles.activeFilter} onClick={() => handleAttrSelect(key, value, attrs, filter)}>
                            {`${key}: `}
                            {(key === 'Background' && value !== 'tex' && value !== 'special_ed') && <span style={{ backgroundColor: value, height: '1rem', width: '1rem', borderRadius: "2rem", marginLeft: "0.5rem", marginRight: "0.2rem", display: 'inline-block' }}></span>}
                            {`${value}  x`}
                        </div>
                    )
                }))
            }
        })
        if (!empty) {
            temp.push(<div key={10000} className={styles.clearFilter} onClick={() => clearAttrSelect(attrs)}>Clear all</div>)
        }
        setActiveAttrs(temp)
    }

    useEffect(() => {
        if (selectedTab !== null) {
            getTeam(teams[selectedTab])
            getAttrs()
            getObjktSales()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams, selectedTab])

    return (
        <>
            <div className={styles.galleryTabBar} ref={navRef}>
                {teams.map((t, i) => {
                    return (<GalleryTab team={t} key={i} selected={selectedTab === i} onClick={() => setSelectedTab(i)} />)
                })}
            </div>
            {selectedTab !== null && rarity && (
                showFilter && teams[selectedTab].imageFolder !== "artist_original" ?
                    <div className={styles.galleryRow}>
                        <GalleryPanel team={teams[selectedTab]} onSubmit={(str) => handleSearch(str)} TeamAttrs={TeamAttrs} StatusOptions={StatusOptions} />
                        <GalleryPage items={teamItems} pageSize={pageSize} navbar={navRef} select={0} activeAttrs={activeAttrs} objktAuctions={objktAuctions} objktSales={objktSales} start={teams[selectedTab].tokenStart} rarity={rarity} />
                    </div>
                    : <GalleryPage items={teamItems} pageSize={pageSize} navbar={navRef} start={teams[selectedTab].tokenStart} objktSales={objktSales} objktAuctions={objktAuctions} rarity={rarity} />)}
        </>
    )
}