import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation, Redirect } from 'react-router'
import { routes } from './routes';
import './App.css';
import { Header } from './components/header';
import TezDozenContextProvider from './context/TezDozenContext';
import { track } from './Tracker';
import { PageView } from './pixel';

function App() {

  const [headerFixed, setHeaderFixed] = useState(true)
  const [headerHide, setHeaderHide] = useState(false)
  const [headerMenuShow, setHeaderMenuShow] = useState(true)
  const location = useLocation()

  const headerControl = ({ fixed, hide, current, showMenu }) => {
    if (fixed != null) {
      setHeaderFixed(fixed)
    }
    if (hide != null) {
      setHeaderHide(hide)
    }
    if (showMenu === false) {
      setHeaderMenuShow(false)
    }
    else {
      setHeaderMenuShow(true)
    }
    if (current != null) {
      window.location.hash = current
    }
  }

  useEffect(() => {
    PageView()
  }, [location.pathname])

  return (
    <TezDozenContextProvider>
      {!headerHide && <Header
        fixed={headerFixed}
        showMenu={headerMenuShow}
      />}
      <div className="App">
        <Switch>
          {routes.map(({ exact, path, component: Comp }) => (
            <Route path={path} exact={exact} key={path}
              component={track(() => Comp({ headerControl: headerControl }))}
            />
          ))}
          <Redirect from='*' to='/' />
        </Switch>
      </div>
    </TezDozenContextProvider>
  );
}

export default App;
