import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { TezDozenContext } from '../../context/TezDozenContext'
import { ReactComponent as RemoveBtn } from './img/remove_btn.svg'
import { ReactComponent as AkaDaoIcon } from './img/akaDao.svg'
import { ReactComponent as RefreshBtn } from './img/refresh_btn.svg'
import { SyncButton } from '../syncButton'
import { storeContract, objFA2, whiteListBigMap, apiUrl, englishAuctionsBigMap, dutchAuctionsBigMap, swapItemsBigMap, collectedBigMap, fa2, tgfa2, akaswapApi, daofa2, daoStoreOpen } from '../../context/constant'
import { creators, endedCreators, archive, archiveV1, archiveV2, archiveUnsort } from '../../data/DaoStoreIndex.json'
import { Timer } from '../timer'
import { parseDateTime } from '../../datetime'
import { Modal } from '../modal'
import { useHistory } from 'react-router-dom'

const axios = require('axios')

export const SwapSection = () => {

    const [inWhiteList, setInWhiteList] = useState(false)
    const [auctionType, setAuctionType] = useState(0)   // 0: none, 1: english auction; 2: dutch auction; 3: swap
    const [tokens, setTokens] = useState([])
    const [tokensLength, setTokenLength] = useState(0)
    const [validateSubmit, setValidateSubmit] = useState(false)
    const [statusMsg, setStatusMsg] = useState(null)
    const [auctionInfo, setAuctionInfo] = useState({})
    const context = useContext(TezDozenContext)

    function sortArchive() {
        if (archiveUnsort > 0) {
            var url = `${apiUrl}/v1/bigmaps/${[0, englishAuctionsBigMap, dutchAuctionsBigMap, swapItemsBigMap][archiveUnsort]}/keys?select=key,value`
            axios.get(url)
                .then((res) => {
                    if (res.data && res.data) {
                        var arr = res.data.sort((a, b) => parseInt(a.key) < parseInt(b.key) ? -1 : parseInt(a.key) > parseInt(b.key) ? 1 : 0)
                            .map(({ value }) => (value))
                        // console.log(arr)
                    }
                })
        }
    }

    function checkWhiteList() {
        axios.get(`${apiUrl}/v1/bigmaps/${whiteListBigMap}/keys/${context.address}`)
            .then((res) => {
                if (res.data && res.data.active) setInWhiteList(true)
                else setInWhiteList(false)
            })
    }
    function getAuctionInfo(addr) {
        axios.get(`${apiUrl}/v1/contracts/${addr}/storage`)
            .then((res) => {
                if (res.data) {
                    setAuctionInfo(res.data)
                }
            })
    }
    function removeToken(i) {
        var arr = tokens.slice(0, tokens.length)
        arr.splice(i, 1)
        setTokens(arr)
        setValidateSubmit(validate(arr))
        setTokenLength(tokensLength - 1)
    }

    function addToken() {
        var arr = tokens.slice(0, tokens.length)
        arr.push({ token_id: 0, token_fa2: objFA2, token_amount: 1 })
        setTokens(arr)
        setValidateSubmit(false)
        setTokenLength(tokensLength + 1)
    }

    function setTokenId(i, value) {
        var arr = tokens.slice(0, tokens.length)
        arr[i].token_id = value
        setValidateSubmit(validate(arr))
        setTokens(arr)
    }

    function setTokenAmount(i, value) {
        var arr = tokens.slice(0, tokens.length)
        arr[i].token_amount = value
        setValidateSubmit(validate(arr))
        setTokens(arr)
    }

    function setTokenPrice(i, value) {
        var arr = tokens.slice(0, tokens.length)
        arr[i].token_price = value
        setValidateSubmit(validate(arr))
        setTokens(arr)
    }

    function validate(arr) {
        if (auctionType === 0 || tokens.length === 0) return false
        else {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].token_id < 1 || arr[i].token_amount < 1) {
                    return false
                }
            }
        }
        return true
    }

    function submit() {
        // remember to set amount to 1 if english auction
        var arr
        switch (auctionType) {
            case 1:
                arr = tokens.map(t => ({
                    token_fa2: t.token_fa2,
                    token_id: parseInt(t.token_id)
                }))
                break;
            case 2:
                arr = tokens.map(t => ({
                    token_amount: parseInt(t.token_amount),
                    token_fa2: t.token_fa2,
                    token_id: parseInt(t.token_id)
                }))
                break;
            case 3:
                arr = tokens.map(t => ({
                    akaDAO_per_token: t.token_price * 1000000,
                    token_amount: parseInt(t.token_amount),
                    token_fa2: t.token_fa2,
                    token_id: parseInt(t.token_id)
                }))
                break;
            default:
                break;
        }
        setStatusMsg("Connecting to your wallet...")
        context.daoStoreSwap(storeContract, auctionType, arr,
            (opHash) => setStatusMsg("Swapping..."),
            (opHash) => setStatusMsg("Successful!"),
            (opHash) => setStatusMsg("Some error occurred")
        )
    }


    useEffect(() => {
        if (context.address) {
            checkWhiteList()
            getAuctionInfo(storeContract)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.address, tokens])

    // STEP 1
    const syncWallet = () => (
        <div>
            <div className={styles.stepTitle}>Step 1. Sync your wallet</div>
            <div className={styles.stepContent}><SyncButton feature='sync' />
                {context.address && !inWhiteList && <div className={styles.warning}>not valid in this event</div>}
            </div>
        </div>
    )

    // STEP 2
    const chooseAuctionType = () => (
        <div>
            <div className={styles.stepTitle}>Step 2. Choose how to sell</div>
            <div className={styles.stepContent}>
                <div className={`${styles.auctionType} ${auctionType === 1 ? styles.selected : ''}`} onClick={() => setAuctionType(1)}>English Auction
                    {auctionInfo.english_auction_info && <div className={styles.small}>({`${parseDateTime(auctionInfo.english_auction_info.start_time).toLocaleString()} ~ ${parseDateTime(auctionInfo.english_auction_info.due_time).toLocaleString()}`})</div>}
                </div>
                <div className={`${styles.auctionType} ${auctionType === 2 ? styles.selected : ''}`} onClick={() => setAuctionType(2)}>Dutch Auction
                    {auctionInfo.dutch_auction_info && <div className={styles.small}>({`${parseDateTime(auctionInfo.dutch_auction_info.start_time).toLocaleString()} ~ ${parseDateTime(auctionInfo.dutch_auction_info.due_time).toLocaleString()}`})</div>}
                </div>
                <div className={`${styles.auctionType} ${auctionType === 3 ? styles.selected : ''}`} onClick={() => setAuctionType(3)}>Special Edition
                    {/* {auctionInfo.swap_info && <div className={styles.small}>({`${parseDateTime(auctionInfo.swap_info.start_time).toLocaleString()} ~ ${parseDateTime(auctionInfo.swap_info.due_time).toLocaleString()}`})</div>} */}
                </div>
            </div>
        </div>
    )

    // STEP 3
    const listTokens = () => (
        <div>
            <div className={styles.stepTitle}>Step 3. List your NFT you want to swap to DAO-Exclusive Store</div>
            <div className={styles.stepContent}>
                {tokensTable(tokens, tokensLength)}
                <button className={styles.outline} onClick={() => addToken()}>+ add</button>
            </div>
        </div>
    )

    const tokensTable = (tokens) => (
        <div className={styles.tokensTable}>
            <div>Token ID</div>
            <div>Amount</div>
            <div>Price</div>
            <div></div>
            <div className={styles.warning}></div>
            {tokens.map((token, i) => (
                <React.Fragment key={i}>
                    <div><input type='number' value={token.token_id ? token.token_id : ''} onChange={e => setTokenId(i, e.target.value)}></input></div>
                    <div><input type='number' value={auctionType === 1 ? 1 : token.token_amount ? token.token_amount : ''} onChange={e => setTokenAmount(i, e.target.value)} disabled={auctionType === 1}></input></div>
                    <div><input type='number' value={
                        auctionType === 3 ? (token.token_price || 0)
                            : parseInt(auctionInfo[['english_auction_info', 'dutch_auction_info'][parseInt(auctionType) - 1]].start_akaDAO) / 1000000
                    }
                        onChange={e => setTokenPrice(i, e.target.value)} disabled={auctionType !== 3}></input></div>
                    <div className={styles.removeButton} onClick={() => removeToken(i)}><RemoveBtn /></div>
                    <div className={styles.warning}>{token.token_id <= 0 && 'Token ID needed'}</div>
                </React.Fragment>
            ))}
        </div>
    )

    const submitButton = () => (
        <div className={styles.centerContainer}>
            {statusMsg ? <button className={styles.submitButton} disabled>{statusMsg}</button>
                : <button className={styles.submitButton} disabled={!validateSubmit} onClick={() => submit()}>Swap {tokens.length} objects</button>
            }
        </div>
    )

    return (
        <div className={styles.swapSection}>
            <div className={styles.centerContainer}>
                <img alt='Tez Dozen' className={styles.titleImage} src={'./images/title_2.png'} onClick={() => sortArchive()} />
            </div>
            <h2 className={styles.centerContainer + ' ' + styles.pageTitle}>Swap your NFT to DAO-Exclusive Store</h2>
            {syncWallet()}
            {context.address && auctionInfo && inWhiteList && chooseAuctionType()}
            {context.address && inWhiteList && auctionType !== 0 && listTokens()}
            {context.address && inWhiteList && submitButton()}
        </div>
    )
}

const AuctionItem = ({ auctionId, item, type, startPrice, collection, auctionInfo, maxDao, inCollection }) => {
    const [tokenInfo, setTokenInfo] = useState(null)
    const [isItemModalOpen, setIsItemModalOpen] = useState(false)
    const [isBidModalOpen, setIsBidModalOpen] = useState(false)
    const currentPrice = (parseInt(item.current_akaDAO) > 0 ? parseInt(item.current_akaDAO) : startPrice) / 1000000
    const [bidPrice, setBidPrice] = useState((parseInt(item.current_akaDAO) > 0 ? parseInt(item.current_akaDAO) + parseInt(auctionInfo.akaDAO_increment) : startPrice) / 1000000)
    const [isEnded, setIsEnded] = useState(false)
    const [soldOut, setSoldOut] = useState(false)
    const [isStarted, setIsStarted] = useState(true)
    const context = useContext(TezDozenContext)
    const [bidMessage, setBidMessage] = useState(type === 3 ? 'Collect' : 'Bid')
    const [Collected, setCollected] = useState(false)   // use In dutch bidded and swap items collect temporary state
    const min = ((parseInt(item.current_akaDAO) > 0 ? parseInt(item.current_akaDAO) + parseInt(auctionInfo.akaDAO_increment) : startPrice) / 1000000)
    const max = Math.floor(maxDao) // change to max akaDao

    function getTokenInfo() {
        // get info from akaswap API
        axios.get(`${akaswapApi}/fa2tokens/${objFA2}/${item.token_id}`)
            .then((res) => {
                if (res.data)
                    setTokenInfo(res.data)
            })
    }

    function handleInput(e) {
        let n = e.target.value
        if (parseFloat(n) >= 0) {
            setBidPrice(Math.floor(n))
        } else {
            setBidPrice('')
        }
    }

    function handleCollect() {
        var validate = {
            is_tdtm: collection.contract === fa2,
            token_id: parseInt(collection.tokenId)
        }
        setBidMessage('Connecting wallet...')
        switch (type) {
            case 1: // English Auction
                context.bidEnglishAuction(storeContract, auctionId, bidPrice * 1000000, validate,
                    () => {
                        // on wallet opened
                        setBidMessage('Bidding...')
                    },
                    () => {
                        // on transaction completed
                        setBidMessage('Successful!')
                        setBidPrice(bidPrice + auctionInfo.akaDAO_increment / 1000000)
                    },
                    () => {
                        // on transaction failed
                        setBidMessage('Some Error Occurred!')
                    }
                ).then((e) => {
                    // on transaction sent
                    if (e) {
                        console.log(e)
                    }
                })
                break;
            case 2: // Dutch Auction
                context.collectDutchAuction(storeContract, auctionId, validate,
                    () => null,
                    () => setCollected(true),
                    () => null
                ).then((e) => {
                    if (e) {
                        console.log(e)
                    }
                })
                break;
            case 3: // Swap
            default:
                context.collectDSSwap(storeContract, auctionId, validate,
                    () => null,
                    () => setCollected(true),
                    () => null
                ).then((e) => {
                    if (e) {
                        console.log(e)
                    }
                })
                break;
        }
    }

    const trimAddress = (address) => {
        return <a className={styles.link} target='_blank' rel='noreferrer' href={`https://tzkt.io/${address}/operations/`}>{address.substring(0, 5) + '...' + address.substring(address.length - 5, address.length)}</a>
    }

    function checkEnded() {
        const now = new Date()
        var endTime = parseDateTime(auctionInfo.due_time)

        switch (type) {
            case 1: // English auction
                if (!isBidModalOpen)
                    setBidPrice((parseInt(item.current_akaDAO) > 0 ? parseInt(item.current_akaDAO) + parseInt(auctionInfo.akaDAO_increment) : startPrice) / 1000000)
                if (auctionInfo.date) setIsEnded(true)  // prevent refresh on ended creators
                else if (now > endTime)  // if sales end, wont get updates from DaoStoreSection
                    axios.get(`${apiUrl}/v1/bigmaps/${englishAuctionsBigMap}/keys/${auctionId}`)
                        .then((res) => {
                            item = res.data.value
                            endTime = parseDateTime(item.due_time)
                            if (now >= endTime)
                                setIsEnded(true)
                            else if ((endTime - now) > 10000)   // refresh every 10s
                                setTimeout(() => {
                                    checkEnded()
                                }, 10000)
                            else
                                setTimeout(() => {  // check ended if sales end
                                    checkEnded()
                                }, endTime - now)
                        })
                else if (endTime - now < 10000) // check ended if sales end
                    setTimeout(() => checkEnded(), endTime - now)
                break;

            case 2: // Dutch auction
                if (now >= endTime || auctionInfo.date)    // ended
                    setIsEnded(true)
                else {
                    // check if bidded
                    if (context.address && item.collect_meta) setCollected(Object.keys(item.collect_meta).includes(context.address))
                    setSoldOut(parseInt(item.token_amount) === 0)
                    if ((endTime - now) < 10000)
                        setTimeout(() => checkEnded(), endTime - now)
                }
                break;
            default:
            case 3:
                // set specific endtime
                endTime = parseDateTime(item.due_time)
                if (now >= endTime || auctionInfo.date)    // ended
                    setIsEnded(true)
                else {
                    setSoldOut(parseInt(item.current_amount) === 0)
                    setTimeout(() => checkEnded(), endTime - now)
                }
                break;
        }
    }

    function setBidModalOpen(bool) {
        setIsBidModalOpen(bool)
    }

    const itemModal = () => (
        <Modal setIsOpened={setIsItemModalOpen} theme='white' className={styles.itemModal}>
            <div className={styles.itemRow}>
                <div className={styles.mediaCol}>
                    <img className={styles.display} alt='' src={tokenInfo.thumbnailUri.replace('ipfs://', 'https://assets.akaswap.com/ipfs/')} />
                </div>
                <div className={styles.infoCol}>
                    <h3>{tokenInfo.name}</h3>
                    {tokenInfo.description.split('\n').map((p, i) => <p key={i}>{p}</p>)}
                    <a target='_blank' rel='noreferrer' href={`https://akaswap.com/akaobj/${item.token_id}`}>View on akaSwap</a>
                </div>
            </div>
        </Modal>)

    const bidModal = () => (
        <Modal setIsOpened={setBidModalOpen} className={styles.bidModal}>
            <h3>Make a bid for this akaOBJ</h3>
            <img className={styles.thumbnail} alt='' src={tokenInfo.thumbnailUri.replace('ipfs://', 'https://assets.akaswap.com/ipfs/')} />
            <div className={styles.bidForm}>
                <div className={styles.price}>
                    <div className={styles.inputLabel}>Price: </div>
                    <input id='bid' value={bidPrice} min={min} max={max} onChange={(e) => handleInput(e)} type='number' className={styles.input}></input>
                    {bidPrice < min && <div className={styles.warning}>Bid Price should be higher than {min} akaDao.</div>}
                    {bidPrice > max && <div className={styles.warning}>You only have {max} akaDao</div>}
                    <div className={styles.note}>*The default value is the minimum bid price on top of the current highest bid.</div>
                    <div className={styles.note}>*Each bid should be 5 akaDAO more than the current price.</div>
                </div>
            </div>
            <button onClick={() => handleCollect()} disabled={bidPrice < min || bidMessage !== 'Bid'}>{bidMessage}</button>
        </Modal>)

    useEffect(() => {
        if (tokenInfo == null) {
            getTokenInfo()
        }
        // this run on every item update (refreshSales)
        const now = new Date()
        var startTime = parseDateTime(item.start_time ? item.start_time : auctionInfo.start_time)
        if (startTime > now) {  // is not started
            setIsStarted(false)
            setTimeout(() => setIsStarted(true), startTime - now)
        } else
            checkEnded()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return (
        <div className={styles.auctionItem}>
            <div onClick={() => setIsItemModalOpen(true)}>
                {tokenInfo && tokenInfo.thumbnailUri && <img className={styles.thumbnail} alt='' src={tokenInfo.thumbnailUri.replace('ipfs://', 'https://assets.akaswap.com/ipfs/')} />}
                {item.due_time !== auctionInfo.due_time && isStarted && !soldOut && !isEnded && <div className={styles.extendTimer}><Timer time={item.due_time} type="dhms" /></div>}
            </div>
            <div className={styles.itemName}>{tokenInfo && tokenInfo.name}</div>
            {type === 1 && <>
                <div className={styles.label}>{isEnded ? 'Hammer Price' : parseInt(item.current_akaDAO) > 0 ? 'Current Price' : 'Start Price'} : </div>
                <div className={styles.info}><div className={styles.AkaDaoIcon}>{currentPrice} <AkaDaoIcon /> </div></div>
                <div className={styles.bidder}>{parseInt(item.current_akaDAO) > 0 && <>bidden by {trimAddress(item.current_bidder)}</>}</div>
                {!auctionInfo.date && <button className={styles.button} onClick={() => { setIsBidModalOpen(true); setBidMessage('Bid') }} disabled={!isStarted || isEnded}>
                    {!isStarted ? 'Coming Soon' : isEnded ? 'Ended' : 'Bid'}
                </button>}
            </>}
            {type === 2 && <>
                <div className={styles.label}>Editions:</div>
                <div className={styles.info}>{item.token_amount}/{parseInt(item.token_amount) + Object.keys(item.collect_meta).length}</div>
                <div className={styles.label}>{((isEnded && Object.keys(item.collect_meta).length > 0) || soldOut) && 'Hammer Price: '}</div>
                <div className={styles.info}>{((isEnded && Object.keys(item.collect_meta).length > 0) || soldOut) && <div className={styles.AkaDaoIcon}>{currentPrice} <AkaDaoIcon /> </div>}</div>
                {!auctionInfo.date && <button className={styles.button} onClick={() => handleCollect()} disabled={!isStarted || isEnded || Collected || soldOut || maxDao < currentPrice}>
                    {!isStarted ? 'Coming Soon' : isEnded ? 'Ended' : soldOut ? `Sold out` : Collected ? 'Bade' : 'Bid'}
                </button>}
            </>}
            {type === 3 && <>
                <div className={styles.label}>Price: </div>
                <div className={styles.info}><div className={styles.AkaDaoIcon}>{currentPrice} <AkaDaoIcon /> </div></div>
                <br />
                <div className={styles.bidder}>Editions: {item.current_amount}/{item.total_amount}</div>
                {!auctionInfo.date && <button className={styles.button} onClick={() => handleCollect()} disabled={!isStarted || isEnded || Collected || inCollection || soldOut || maxDao < currentPrice}>
                    {!isStarted ? `Start at ${parseDateTime(item.start_time).toLocaleTimeString()}` : isEnded ? 'Ended' : soldOut ? `Sold out` : (inCollection || Collected) ? 'Collected' : 'Collect'}
                </button>}
            </>}
            {isItemModalOpen && itemModal()}
            {type === 1 && isBidModalOpen && bidModal()}
        </div>)
}

const CreatorSection = ({ collab, names, name, auctionType, auctionInfo, auctionItems, swapItems, collection, maxDao, collectedSwap }) => {
    const [started, setStarted] = useState(true)
    const [ended, setEnded] = useState(false)
    const [currentPrice, setCurrentPrice] = useState(120)

    function updatePrice(price, drop, interval, endTime) {  // update price for dutch auction (price drop)
        let now = new Date()
        if (now < endTime) {
            setCurrentPrice(price) // -5tez
            setTimeout(() => updatePrice(price - drop, drop, interval, endTime), interval)
        }
        else {
            setEnded(true)
        }
    }

    useEffect(() => {
        // for testing
        if (auctionInfo) {
            // auctionInfo.start_time = "2022-08-18T18:00:00+08:00"
            // auctionInfo.due_time = "2022-08-18T18:10:00+08:00"
            // auctionInfo.start_akaDAO = "100000000"
            // auctionInfo.drop_time = "10"
        }
        // Dutch Auction pricedrop calculate
        if (auctionType === 2 && auctionInfo) {
            const now = new Date()
            const startTime = parseDateTime(auctionInfo.start_time)
            const endTime = parseDateTime(auctionInfo.due_time)
            const startPrice = auctionInfo.start_akaDAO / 1000000
            const dropInterval = auctionInfo.drop_time * 1000   // auctionInfo.drop_time use second
            const drop = auctionInfo.drop_akaDAO / 1000000
            var countDown = 0
            countDown = startTime - now
            if (countDown >= 0) {
                // auction not started yet, set start time out
                setStarted(false)
                setCurrentPrice(startPrice)
                setTimeout(() => {
                    setStarted(true)
                    updatePrice(startPrice, drop, dropInterval, endTime)
                }, countDown)
            } else if (now < endTime) {
                // started, calculate next price drop time
                setStarted(true)
                var temp = startPrice + drop
                while (countDown <= 0) {
                    countDown += dropInterval
                    temp -= drop
                }
                setCurrentPrice(temp)

                // in next price drop
                setTimeout(() => {
                    updatePrice(temp - drop, drop, dropInterval, endTime)
                }, countDown)
            } else {
                setEnded(true)
            }
        }
        else if (auctionType === 1 && auctionInfo) {
            const now = new Date()
            const startTime = parseDateTime(auctionInfo.start_time)
            const endTime = parseDateTime(auctionInfo.due_time)
            if (startTime > now) {
                // auction not started yet, set start time out
                setStarted(false)
                setTimeout(() => {
                    setStarted(true)
                }, startTime - now)
            }
            else if (endTime > now)
                setTimeout(() => {
                    setEnded(true)
                }, endTime - now)
            else setEnded(true) // this only affect the section.
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionInfo, collectedSwap])

    const EnglishAuctionInfo = () => (<>
        <div className={styles.label}>Start Price</div>
        <div className={styles.text}><div className={styles.AkaDaoIcon}>{auctionInfo.start_akaDAO / 1000000}<AkaDaoIcon /></div></div>
        {auctionInfo.date ?
            <>
                <div className={styles.label}>Ended</div>
                <div className={styles.text}>{auctionInfo.date}</div>
            </>
            : <><div className={styles.label}>Time Remaining</div>
                <div className={styles.text}>{!started ? 'Coming Soon' : ended ? 'Ended' : <Timer time={auctionInfo.due_time} type="dhms" />}</div>
            </>}
        {/* <ul className={styles.note}>
            <li>Each bid should be 5 akaDAO more than the current price.</li>
            <li>Any bids made in the last 10 minutes of an auction will extend each auction by 10 more minutes.</li>
        </ul> */}
    </>)

    const DutchAuctionInfo = () => (<>
        <div className={styles.note_red}> {parseInt(auctionInfo.start_akaDAO) / 1000000} akaDAO  {'>'} (- {parseInt(auctionInfo.drop_akaDAO) / 1000000} akaDAO) {'/'} {parseInt(auctionInfo.drop_time) / 60} min</div>
        <div className={styles.label}>Current Price</div>
        <div className={styles.text}><div className={styles.AkaDaoIcon}>{currentPrice}<AkaDaoIcon /></div></div>
        {auctionInfo.date ?
            <>
                <div className={styles.label}>Ended</div>
                <div className={styles.text}>{auctionInfo.date}</div>
            </>
            : <><div className={styles.label}>Time Remaining</div>
                <div className={styles.text}>{!started ? 'Coming Soon' : ended ? 'Ended' : <Timer time={auctionInfo.due_time} type="dhms" />}</div>
            </>}
    </>)

    const SwapInfo = () => (<>
        {/* <div className={styles.label}>Collect Price</div>
        <div className={styles.text}><div className={styles.AkaDaoIcon}>{openEditionPrice}<AkaDaoIcon /></div></div> */}
    </>)

    return (
        <div id={name} className={styles.CreatorSection}>
            <div className={styles.left}>
                {collab
                    ? <>
                        <div className={styles.collab}>{names.map((name, i) =>
                            <img key={i} className={styles.creatorProfileIcon} alt='' src={`./images/${name}.png`} />
                        )}</div>
                        <div className={styles.creatorName}>{collab}</div>
                        <div className={styles.subcreatorName}>({names.map((name, i) => (i === 0 ? '' : ', ') + name)})</div></>
                    : <>
                        <img className={styles.creatorProfileIcon} alt='' src={`./images/${name}.png`} />
                        <div className={styles.creatorName}>{name}</div>
                    </>}
                <div className={styles.text}>{['', 'English Auction', 'Dutch Auction', 'Special Edition'][auctionType]}</div>
                {auctionType === 1 && auctionInfo && EnglishAuctionInfo()}
                {auctionType === 2 && auctionInfo && DutchAuctionInfo()}
                {auctionType === 3 && auctionInfo && SwapInfo()}
            </div>
            <div className={styles.right}>
                {swapItems && auctionInfo && swapItems.map((item, i) => (
                    <AuctionItem auctionId={item.key} item={item.value} key={i} collection={collection} maxDao={maxDao}
                        type={3}
                        inCollection={collectedSwap ? collectedSwap.includes(parseInt(item.key)) : false}
                        auctionInfo={auctionInfo}
                        startPrice={item.value.akaDAO_per_token}
                    />
                ))}
                {auctionItems && auctionInfo && auctionItems.map((item, i) => (
                    <AuctionItem auctionId={item.key} item={item.value} key={i} collection={collection} maxDao={maxDao}
                        type={auctionType}
                        auctionInfo={auctionInfo}
                        startPrice={auctionType === 1 ? auctionInfo.start_akaDAO : null}
                    />
                ))}
            </div>
            {auctionType === 2 && auctionInfo &&
                <ul className={styles.note}>
                    <li>Dutch Auction Starts at {parseInt(auctionInfo.start_akaDAO) / 1000000} akaDAO and decreases {parseInt(auctionInfo.drop_akaDAO) / 1000000} akaDAO every {parseInt(auctionInfo.drop_time) / 60} minutes until 10 akaDAO. Duration: maximum 60 minutes.</li>
                    <li>The auction will close when all items are sold out or when the auction time is over.</li>
                    <li>The price of the last bid will be used as the final sell price for all bidders.</li>
                    <li>After the auction ends, the smart contract will automatically refund the difference between each bid price and the hammer price.</li>
                    <li>Limit one purchase per wallet.</li>
                </ul>
            }
        </div>
    )
}

export const DaoStoreSection = ({ tdtm }) => {

    const [storeInfo, setStoreInfo] = useState({})
    const [storeItems, setStoreItems] = useState({})

    // Persona
    const [akaDao, setAkaDao] = useState(0)
    const [collectedSwap, setCollectedSwap] = useState([])    // save collected swap for banning
    const [collection, setCollection] = useState(null)  // Collection for validation
    const [backTimeOut, setBackTimeOut] = useState(null)
    const [showEnded, setShowEnded] = useState(false)   // Show Ended Auctions

    const context = useContext(TezDozenContext)
    const history = useHistory()

    function getAuctionInfo(addr) {
        axios.get(`${apiUrl}/v1/contracts/${addr}/storage`)
            .then((res) => {
                if (res.data) {
                    setStoreInfo([null, res.data.english_auction_info, res.data.dutch_auction_info, res.data.swap_info])
                }
            })
    }

    function validateCollection(address) {
        // check if has tdtm or tdg, and save it to 
        axios.get(`${akaswapApi}/accounts/${address}/fa2tokens?contracts=${fa2}&contracts=${tgfa2}&limit=1`)
            .then((res) => {
                if (res.data) {
                    if (res.data.tokens.length > 0)
                        setCollection(res.data.tokens[0])
                    else
                        history.push('/dozenverse')
                }
            })
            .catch((e) => console.log(e))
    }

    function refreshSale() {
        const now = new Date()
        // get dutch auction items in active creators
        axios.get(`${apiUrl}/v1/bigmaps/${dutchAuctionsBigMap}/keys?key.in=0,${creators.map(c => c.auctionIds).flat().join(',')}`)
            .then((res) => {
                // save to storeItems[2]
                var temp = storeItems
                if (!temp[2]) temp[2] = {}
                res.data.forEach(e => {
                    temp[2][parseInt(e.key)] = e
                })
                setStoreItems({ ...temp })
            })
        // get english auction items in active creators
        axios.get(`${apiUrl}/v1/bigmaps/${englishAuctionsBigMap}/keys?key.in=0,${creators.map(c => c.auctionIds).flat().join(',')}`)
            .then((res) => {
                // save to storeItems[1]
                var temp = storeItems
                if (!temp[1]) temp[1] = {}
                res.data.forEach(e => {
                    temp[1][parseInt(e.key)] = e
                })
                setStoreItems({ ...temp })
            })
        // get swap items in active creators
        axios.get(`${apiUrl}/v1/bigmaps/${swapItemsBigMap}/keys?key.in=0,${creators.map(c => c.swapIds).flat().join(',')}`)
            .then((res) => {
                // save to storeItems[3]
                var temp = storeItems
                if (!temp[3]) temp[3] = {}
                res.data.forEach(e => {
                    temp[3][parseInt(e.key)] = e
                })
                setStoreItems({ ...temp })
            })

        // schedule next refresh if time < duetime
        if (!(storeInfo[2] && parseDateTime(storeInfo[2].due_time) < now) || !(storeInfo[3] && now > parseDateTime(storeInfo[3].due_time))) {
            setTimeout(() => refreshSale(), 10000)
        }
    }
    function getAkaDao(address) {
        // use tzkt api
        axios.get(`${apiUrl}/v1/tokens/balances?token.contract=${daofa2}&account=${address}&select=balance`)
            .then((res) => {
                if (res.data)
                    setAkaDao(parseInt(res.data[0]) / 1000000)
            })
            .catch((e) => console.log(e))
    }
    function getCollectedSwap(address) {
        axios.get(`${apiUrl}/v1/bigmaps/${collectedBigMap}/keys?key=${address}&select=value`)
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    setCollectedSwap(res.data.map(i => parseInt(i)))
                }
            })
    }
    function getAccountInfo() {
        var address = context.address
        getAkaDao(address)
        getCollectedSwap(address)
        setTimeout(() => getAccountInfo(), 10000)
    }
    useEffect(() => {
        if (!daoStoreOpen) history.push('/dozenverse')  // redirect to dozenverse if store closed
        if (context.address) {
            validateCollection(context.address) // redirect to dozenverse if not TDTM holder
            getAuctionInfo(storeContract)
            refreshSale()   // refresh every 10s if (now < swapdueTime | auctionduetime)
            getAccountInfo()   // refresh every 10s
            clearTimeout(backTimeOut)
        } else {
            // redirect to dozenverse if not synced
            setBackTimeOut(setTimeout(() => {
                if (!context.address)
                    history.push('/dozenverse')
            }, 5000))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.address])

    const creatorsMenu = () => (
        <div className={styles.CreatorMenu}>
            <div className={styles.akaDao}>
                <div>
                    <div className={styles.label}> My akaDAO </div>
                    <div className={styles.text}>
                        <div className={styles.AkaDaoIcon}>{akaDao.toFixed(2)}<AkaDaoIcon />
                            <div className={styles.refreshBtn + ' ' + styles.AkaDaoIcon}>
                                <RefreshBtn onClick={() => getAkaDao(context.address)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

    const creatorSections = () => (
        <div className={styles.creators}>
            {storeItems[3] && creators.map((creatorInfo, i) => (
                (!creatorInfo.auctionType || storeItems[creatorInfo.auctionType]) && <CreatorSection
                    key={i} {...creatorInfo} collection={collection} maxDao={akaDao}
                    auctionInfo={storeInfo[creatorInfo.auctionType]}
                    auctionItems={creatorInfo.auctionType ? creatorInfo.auctionIds.map(id => storeItems[creatorInfo.auctionType][id]) : null}
                    swapItems={creatorInfo.swapIds.map(id => storeItems[3][id])}
                    collectedSwap={collectedSwap}
                />
            ))}
        </div >
    )
    const endedCreatorSections = () => (
        <div className={styles.creators}>
            {endedCreators.map((creatorInfo, i) => (
                <CreatorSection
                    key={i} {...creatorInfo} collection={collection} maxDao={akaDao}
                    auctionItems={creatorInfo.auctionIds.map(
                        id => ({
                            key: id, value: (
                                (creatorInfo.auctionInfo.date === '5/15' || creatorInfo.auctionInfo.date === '6/15') ? archiveV2
                                    : creatorInfo.auctionInfo.date === '4/15' ? archiveV1
                                        : archive[creatorInfo.auctionType])[id]
                        })
                    )}
                    swapItems={creatorInfo.swapIds ? creatorInfo.swapIds.map(id => ({ key: id, value: archive[3][id] })) : null}
                />

            ))}
        </div >
    )

    return (
        <div className={styles.DaoStoreSection}>
            <div className={styles.container}>
                <div className={styles.notice}>
                    <div className={styles.noticeIcon}>
                        <img src="./images/deco/drum.png" alt=""></img>
                        <h2>News</h2>
                    </div>
                    <div className={styles.noticeBackground}>
                        <ul>
                            <li>The fifth round of auction will be held on 2022/8/26  23:00 - 24:00 (UTC+8).</li>
                            <li>You can only use akaDAO to bid NFT in the DAO-Exclusive store.</li>
                            <li>(English Auction) Each bid should be 5 akaDAO more than the current price.</li>
                            <li>(English Auction) Any bids made in the last 10 minutes of an auction will extend each auction by 10 more minutes.</li>
                            <li>(Special Edition) Each NFT will only open for 20 minutes to collect and each wallet can only collect one of the same NFT.</li>
                            <li>(Special Edition) The remaining editions will be burned at the end of the event.</li>
                        </ul>
                    </div>
                </div>
                {creatorsMenu()}
                {creatorSections()}
                {endedCreators.length > 0 && <>
                    <div className={styles.break}>
                        <div><button className={styles.showDetailsButton} onClick={() => setShowEnded(!showEnded)}>{showEnded ? 'Hide previous auctions' : 'Show previous auctions'}</button></div>
                    </div>
                    {showEnded && endedCreatorSections()}
                </>}
            </div>
        </div>
    )
}