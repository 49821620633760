import React from "react"
import styles from './styles.module.scss'
import { tzktUrl } from "../../context/constant"
import { ReactComponent as WarningIcon } from './images/warning_icon.svg';
import { ReactComponent as CompleteIcon } from './images/complete_icon.svg';

export const Alert = ({ type = null, icon, text, className, hideCallback, timer, opHash = null }) => {
    return (
        <>
            {type !== null && <div className={styles.alertOverlay} onClick={(e) => {
                hideCallback()
            }}>
                <div className={styles.alertBubble}>
                    <div className={styles.icon}>{
                        type === "complete" ? <CompleteIcon /> :
                            type === "warning" ? <WarningIcon /> :
                                icon
                    }</div>
                    <div className={styles.text}>{text}
                        {opHash !== null && (
                            <>
                                Check it on <a target='_blank' rel='noreferrer' className={styles.alertLink} href={tzktUrl + opHash}>tzkt.io →</a>
                            </>
                        )}
                    </div>
                </div>
            </div >}
        </>
    )
}