import React, { useContext, useState } from "react"
import { TezDozenContext } from "../../context/TezDozenContext"
import styles from './styles.module.scss'
import { apiUrl, dcAuthApi } from "../../context/constant"
import { Alert } from "../alert"
import { completeRegistration } from "../../pixel"
const axios = require('axios')


export const SyncButton = ({ feature, className, children, disabled }) => {
    const [alert, setAlert] = useState(null)
    const [enrollState, setEnrollState] = useState(0)
    const displayAlert = (type, text, opHash, timeOut = 30000) => {
        const hide = () => setAlert(null)
        if (timeOut !== false) {
            setTimeout(() => {
                hide()
            }, timeOut)
        }
        setAlert(<Alert type={type} text={text} hideCallback={() => hide()} opHash={opHash} />)

    }
    const context = useContext(TezDozenContext)
    const { syncTaquito, registerFOMO, disconnect } = useContext(TezDozenContext)
    const handleSyncUnsync = () => {
        if (context.acc?.address) {
            // disconnect wallet
            disconnect()
            // setSyncDropDown(false)
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }

    const handleRegisterFOMO = ({ fomoApi, blacklistApi, contractAddr }) => {
        if (context.acc?.address && blacklistApi > 0) {

            axios.get(`${apiUrl}/v1/bigmaps/${blacklistApi}/keys`, {
                params: {
                    key: context.acc?.address,
                },
            })
                .then((res) => {
                    if (res.data.length > 0) {
                        displayAlert("warning", "Operation could not be completed.")
                    }
                    else {
                        setEnrollState(1)
                        displayAlert("warning", "Connecting to your wallet...", null, false)
                        registerFOMO(
                            contractAddr,
                            (opHash) => {
                                completeRegistration();
                                setEnrollState(2)
                                displayAlert("warning", "Enrolling... ", opHash, false)
                            },
                            (opHash) => {
                                setEnrollState(3)
                                displayAlert("complete", "Successfully enrolled as fomo. ", opHash)
                            },
                            () => {
                                setEnrollState(0)
                                displayAlert("warning", "An error occured")
                            }
                        ).then((e) => {
                            if (e) {
                                setEnrollState(0)
                                displayAlert("warning", e.description)
                            } else {
                                //displayAlert("warning", "Enrolling...")
                            }
                        })
                    }
                })
                .catch((e) => console.log(e))

        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }

    return (
        <>
            {alert}
            {feature === 'sync' &&
                <button className={className + ' ' + styles.SyncButton} onClick={() => handleSyncUnsync()} >
                    <span>{context.acc ? context.address.substring(0, 5) + '...' + context.address.substring(context.address.length - 5, context.address.length) : 'Sync'}</span>
                    {context.acc && <span className={styles.unsynchover}>Unsync</span>}
                </button >
            }
            {
                feature === 'dozenVerse' &&
                <button className={className + ' ' + styles.SyncButton} onClick={() => handleSyncUnsync()} disabled={disabled}>
                    <span>
                        {context.acc ?
                            <>Oops! There’s no TDTM in your wallet.<br />
                                You may be able to collect one in the <a href="https://akaswap.com/metaverse?page=1&collections=KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax&sortBy=mintTimeDesc">secondary market</a>.</>
                            : 'Sync My Wallet'}
                    </span>
                    {/* {context.acc && <span className={styles.unsynchover}>Unsync</span>} */}
                </button >

            }
            {feature === 'fomoReg' &&
                <button className={className} onClick={() => handleRegisterFOMO(context.fomoTeam)} disabled={(!context.acc || context.registeredFOMO || enrollState !== 0) ? true : false}>
                    <span>{(context.registeredFOMO || enrollState === 3) ? "Enrolled Successfully" : (enrollState === 1) ? `Connecting to wallet...` : (enrollState === 2) ? `Enrolling...` : `Click to Enroll`}</span>
                </button >}
        </>
    )
}
export const AuthButton = ({ nonce = () => null, className, children }) => {
    const [alert, setAlert] = useState(null)
    const displayAlert = (type, text, opHash, timeOut = 30000) => {
        const hide = () => setAlert(null)
        if (timeOut !== false) {
            setTimeout(() => {
                hide()
            }, timeOut)
        }
        setAlert(<Alert type={type} text={text} hideCallback={() => hide()} opHash={opHash} />)

    }
    const context = useContext(TezDozenContext)
    async function handleSign() {
        console.log('nonce=' + nonce)
        if (context.acc?.address) {
            var sig = await context.signMessage()
            axios.get(`${dcAuthApi}${nonce}/${context.address}/${sig}`).then((res) => {
                console.log(res)
                if (res.status === 201)
                    displayAlert('complete', `Successfully linked tezos wallet to your Discord account!`)
            }).catch((e) => {
                console.log(e.response.data)
                if (e.response.data.includes('Nonce')) {
                    displayAlert('warning', 'Invalid Link. Please try \'Go\' from Discord again.')
                } else if (e.response.data.includes('not on chain')) {
                    displayAlert('warning', 'Your address is not on chain.')
                } else if (e.response.data.includes('Signature')) {
                    displayAlert('warning', 'Bad signature.')
                } else if (e.response.data.includes('not support')) {
                    displayAlert('warning', 'Your address is not supported.')
                } else {
                    displayAlert('warning', e.response.data)
                }
            })
        }
    }

    return (
        <>
            {alert}
            <button className={className + ' ' + styles.SyncButton} onClick={() => handleSign()} disabled={!context.acc}>
                <span>Sign</span>
            </button >
        </>
    )
}

export const MintButton = ({ children, amount, team, isFomo, className }) => {
    const [alert, setAlert] = useState(null)
    const displayAlert = (type, text, opHash, timeOut = 30000) => {
        const hide = () => setAlert(null)
        if (timeOut !== false) {
            setTimeout(() => {
                hide()
            }, timeOut)
        }
        setAlert(<Alert type={type} text={text} hideCallback={() => hide()} opHash={opHash} />)
    }
    const context = useContext(TezDozenContext)
    const { syncTaquito, mint } = useContext(TezDozenContext)

    const handleMint = (mintAmount, isFOMO, { contractAddr, fomoApi, blacklistApi, mintApi, team }) => {
        if (context.acc?.address) {
            if (amount > 3)
                displayAlert('warning', 'Maximum of 3 TDTMs per deployment.')
            else if (isFOMO) {
                displayAlert('warning', 'Connecting to your wallet...', null, false)
                mint(mintAmount, isFOMO, contractAddr,
                    (opHash) => { displayAlert("warning", `Deploying... `, opHash, false) },
                    (opHash) => { displayAlert("complete", `Success deployed ${team}. `, opHash) },
                    () => { displayAlert("warning", "An error occured") }
                ).then((e) => {
                    if (e) {
                        displayAlert('warning', e.description)
                    }
                    else {
                        console.log(e)
                        // displayAlert('warning', 'Deploying...')
                    }
                })
            }
            else {
                displayAlert('warning', 'Connecting to your wallet...', null, false)
                mint(mintAmount, isFOMO, contractAddr,
                    (opHash) => { displayAlert("warning", `Deploying... `, opHash, false) },
                    (opHash) => { displayAlert("complete", `Success deployed ${team}. `, opHash) },
                    () => { displayAlert("warning", "An error occured") }
                ).then((e) => {
                    if (e) {
                        displayAlert('warning', e.description)
                    }
                    else {
                        console.log(e)
                        // displayAlert('warning', 'Deploying...')
                    }
                })
            }

        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    return (
        <>
            {alert}
            <button className={className} onClick={() => handleMint(amount, isFomo, team)} disabled={context.acc === null}>
                {context.acc ? 'Deploy' : 'Sync to Deploy'}
            </button >
        </>
    )
}

export const AuctionButton = ({ auctionContract, currentPrice, className, setBidStatus = ()=>null }) => {
    const [alert, setAlert] = useState(null)
    const displayAlert = (type, text, opHash, timeOut = 30000) => {
        const hide = () => setAlert(null)
        if (timeOut !== false) {
            setTimeout(() => {
                hide()
            }, timeOut)
        }
        setAlert(<Alert type={type} text={text} hideCallback={() => hide()} opHash={opHash} />)
    }
    const context = useContext(TezDozenContext)
    const handleAuction = () => {
        // call beacon here
        var send = context.meowAuction
        displayAlert("warning", `Connecting to your wallet... `)
        
        setBidStatus(-1)
        send(
            auctionContract,
            currentPrice,
            // after transaction send
            (opHash) => {
                setBidStatus(-1)
                displayAlert("warning", `Placing bid... `, opHash, false)
            },
            // after done
            (opHash) => {
                setBidStatus(currentPrice)
                displayAlert("complete", `Success bid. `, opHash)
            },
            // after failed
            (opHash) => {
                setBidStatus(0)
                displayAlert("warning", "An error occured")
            }
        ).then((e) => {
            if (e) {
                setBidStatus(0)
                displayAlert('warning', e.description)
            } else {
                // setResultMessage('Transaction failed!')
            }
        })
    }
    return (
        <>
            {alert}
            <button className={className} onClick={() => handleAuction()}>
                <span>BID NOW</span>
            </button >
        </>
    )
}