import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as AkaswapIcon } from './images/akaswap.svg'
import { ReactComponent as DiscordIcon } from './images/discord.svg'
import { ReactComponent as InstagramIcon } from './images/instagram.svg'
import { ReactComponent as ObjktIcon } from './images/objkt.svg'
import { ReactComponent as TwitterIcon } from './images/twitter.svg'
import { mint as mintDatas } from '../../data/mint.json'
import {tdg} from '../../data/tdg.json'
import { networkType, fa2, tgfa2, meowContract } from '../../context/constant'


export const Footer = ({ ipfs, contract }) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.credit}>
                <a href="https://tzkt.io" target="_blank" rel="noopener noreferrer">Powered by TZKT API</a>
            </div>
            <div className={styles.centered}>

                <div className={styles.logo}>
                    <img alt='Tez Dozen' src={'./images/title.png'}></img>
                </div>
                <div className={styles.ipfs}>
                    <div>
                        IPFS
                        <div>
                            {mintDatas.concat(tdg).map(({ imageFolder, ipfs }, i) => {
                                if (ipfs !== undefined && imageFolder !== undefined)
                                    return (
                                        <a key={i} href={`https://cloudflare-ipfs.com/ipfs/${ipfs}`} target="_blank" rel="noopener noreferrer">
                                            <img alt={imageFolder} src={`./images/teams/${imageFolder}/ipfs.png`} />
                                        </a>
                                    )
                                else
                                    return null
                            })}
                        </div>
                    </div>
                    <div>
                        Contract
                        <div>
                            {mintDatas.map(({ imageFolder, contractAddr }, i) => {
                                if (contractAddr !== undefined && contractAddr !== '' && imageFolder !== undefined)
                                    return (
                                        <a key={i} href={`https://better-call.dev/${networkType}/${contractAddr}/operations`} target="_blank" rel="noopener noreferrer">
                                            <img alt={imageFolder} src={`./images/teams/${imageFolder}/ipfs.png`} />
                                        </a>
                                    )
                                else
                                    return null
                            })}
                            <a href={`https://better-call.dev/${networkType}/${fa2}/operations`} target="_blank" rel="noopener noreferrer">
                                <img alt='FA2' src={`./images/FA2.svg`} />
                            </a>
                            {tdg.map(({ imageFolder, contractAddr }, i) => {
                                if (contractAddr !== undefined && contractAddr !== '' && imageFolder !== undefined)
                                    return (
                                        <a key={i} href={`https://better-call.dev/${networkType}/${contractAddr}/operations`} target="_blank" rel="noopener noreferrer">
                                            <img alt={imageFolder} src={`./images/teams/${imageFolder}/ipfs.png`} />
                                        </a>
                                    )
                                else
                                    return null
                            })}
                            <a href={`https://better-call.dev/${networkType}/${tgfa2}/operations`} target="_blank" rel="noopener noreferrer">
                                <img alt='FA2' src={`./images/tgFA2.svg`} />
                            </a>

                        </div>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.copyRight}>
                        © 2021 Tez Dozen. All rights reserved.
                    </div>

                </div>
            </div>
            <div className={styles.community}>
                <a target='_blank' rel='noreferrer' href='https://akaswap.com/metaverse?page=1&collections=KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax&sortBy=mintTimeDesc'><AkaswapIcon /></a>
                <a target='_blank' rel='noreferrer' href='https://twitter.com/TezDoZen'><TwitterIcon /></a>
                <a target='_blank' rel='noreferrer' href='https://discord.gg/jvbmQbAVpS'><DiscordIcon /> </a>
                <a target='_blank' rel='noreferrer' href='https://www.instagram.com/tez_dozen/'><InstagramIcon /> </a>
                <a target='_blank' rel='noreferrer' href='https://objkt.com/collection/tezdozen'><ObjktIcon /> </a>
                {/* <a href=''><ObjktIcon /> </a> */}
            </div>
        </footer >
    )
}