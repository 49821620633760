import React from 'react';
import styles from './styles.module.scss'
import { parseDateTime } from '../../datetime';
export const Timeline = ({
    children,
    id = null,
    className = null,
    data,
}) => {
    const today = new Date()
    const item = ({ title, sub, desc, date, hint }, index) => (
        <li key={index} className={((index % 2) ? styles.left : styles.right) + " " + (parseDateTime(date) > today ? styles.future : styles.past)}>
            <div className={styles.timelineMarker} />
            <div className={styles.timelinePanel}>
                <div className={styles.title}>{title}</div>
                <div className={styles.sub}>{
                    sub[0].length>1 ? sub.map((s, i) => <div key={i}>{s}</div>
                    ) : sub}
                </div>
                <div className={styles.desc}>
                    {desc.map((d, i) => <p key={i}>{d}</p>)}
                    {hint && <p className={styles.hint}>{hint}</p>}
                </div>
            </div>
        </li>
    )

    return (
        <ul className={styles.timeline}>
            {data.map((d, index) => item({ ...d }, index))}
            <div className={styles.timelineMarker} />
        </ul>

    )
}