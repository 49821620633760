import React, { useContext, useEffect, useState } from 'react'
// import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Container, Page, Section } from '../../components/layout'
import { faq as faqDatas } from '../../data/faq.json'
import { roadmap as roadmapDatas } from '../../data/roadmap.json'
import { mint as mintDatas } from '../../data/mint.json'
import { tdg as tdgDatas } from '../../data/tdg.json'
import { about as aboutDatas } from '../../data/about.json'
import { Accordion } from '../../components/accordion'
import { Timeline } from '../../components/timeline'
import { Feature, FeaturesContainer } from '../../components/feature'
// import { Background, Parallax } from 'react-parallax'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { ReactComponent as ScrollIcon } from './images/scroll.svg'
import { ReactComponent as InstagramIcon } from './images/ig_s.svg'
import { ReactComponent as TwitterIcon } from './images/twitter_s.svg'
import { Footer } from '../../components/footer'
// import { MintSection, MintTitle } from '../../components/mintCarousel'
import { HashLink } from 'react-router-hash-link'
import { parseDateTime } from '../../datetime'
// import { getDeltaSecond } from '../../datetime'

export const Home = ({ headerControl = () => null }) => {
    const today = new Date()
    // const [useNext, setUseNext] = useState(null)
    const setHash = (hash) => {
        // window.history.pushState(null, null, `#${hash}`) // this won't reload but it wont change the header
        // window.location.href = `${window.location.href.split('/')[0]}${hash}` // this change the header but cause glitch when scrolling up home reload
    }
    // const mint = (
    //     <>
    //         <MintSection teamsData={mintDatas} setHeader={(i) => setUseNext(i)} />
    //     </>
    // )
    const completed = (
        <>
            <div className={styles.completedBg}></div>
            <div className={styles.completedContent}>

                <Container>
                    <div className={styles.completed}>
                        <p>All 3360 Tez Dozen Team Members have completed deployment.</p>
                        <p>You can find them in the secondary market <a href="https://akaswap.com/metaverse?collections=KT1Xphnv7A1sUgRwZsecmAGFWm7WNxJz76ax,KT1ShjqosdcqJBhaabPvkCwoXtS1R2dEbx4Wc" target="_blank" rel='noreferrer'>AkaSwap</a> or <a href="https://objkt.com/collection/tezdozen" target="_blank" rel='noreferrer'>Objkt</a>.</p>
                    </div></Container></div>
        </>
    )
    const keyFeatures = (
        <FeaturesContainer>
            {aboutDatas.keyFeatures.map((f, i) => (
                <Feature
                    className={styles.keyFeatures}
                    title={f.feature}
                    image={f.image}
                    desc={f.desc}
                    hint={f.hint}
                    key={i} />
            ))}
        </FeaturesContainer>
    )
    const team = (
        <FeaturesContainer>
            {aboutDatas.team.map((t, i) => {
                let name = (
                    <span>
                        {t.name}
                        {t.twitter !== undefined && <a className={styles.socialIcon} href={t.twitter} target="_blank" rel="noopener noreferrer"><TwitterIcon /></a>}
                        {t.instagram !== undefined && <a className={styles.socialIcon} href={t.instagram} target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>}
                    </span>)
                return (
                    <Feature
                        title={name}
                        desc={t.desc}
                        key={i}
                        image={t.image}
                        className={styles.team} />
                )
            })}
        </FeaturesContainer>
    )
    const faqs = (
        faqDatas.map((faq, i) => (
            <Accordion
                key={i}
                headerText={faq.q}
                isOpened={false}
            >
                {faq.a.map((p, i) => (<p key={i}>{p}</p>))}
            </Accordion>
        ))
    )
    const timelinedata = roadmapDatas.map((r) => {
        return ({
            title: r.team,
            sub: r.title,
            desc: r.desc,
            date: r.date,
            hint: r.hint
        })
    })
    const timeline = (
        <Timeline
            data={[...timelinedata]}
        />
    )
    const [randomTeamIndex, setRandomTeamIndex] = useState(14)
    useEffect(() => {
        headerControl({ fixed: true, showMenu: true })
        setRandomTeamIndex(Math.floor(Math.random() * 14))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Page top>
                <Section
                    onScrollTop={() => { setHash('#Home') }}
                    id='Home' className={styles.homeSection} full>

                    <ParallaxProvider>
                        <Parallax className={styles.full} y={[-80, 80]}>
                            <div className={styles.bg}></div>
                        </Parallax>
                        <Parallax className={styles.full} y={[-30, 30]}>
                            <img alt='' className={styles.decoLeft} src={`./images/teams/roar/deco_left.png`} />
                            <img alt='' className={styles.decoRight} src={`./images/teams/roar/deco_right.png`} />
                            {/* {randomTeamIndex < 12 ?
                                <>
                                    <img alt='' className={styles.decoLeft} src={`./images/teams/${mintDatas[randomTeamIndex].imageFolder}/deco_left.png`} />
                                    <img alt='' className={styles.decoRight} src={`./images/teams/${mintDatas[randomTeamIndex].imageFolder}/deco_right.png`} />
                                </> : randomTeamIndex < 14 ?
                                    <>
                                        <img alt='' className={styles.decoLeft} src={`./images/teams/${tdgDatas[randomTeamIndex % 12].imageFolder}/deco_left.png`} />
                                        <img alt='' className={styles.decoRight} src={`./images/teams/${tdgDatas[randomTeamIndex % 12].imageFolder}/deco_right.png`} />
                                    </>
                                    :
                                    <>
                                        {/* {(context && context.mintTeam)
                                        ? <img alt='' className={styles.decoLeft} src={`./images/teams/${context.mintTeam.imageFolder}/deco_left.png`} />
                                        : <img alt='' className={styles.decoLeft} src={`./images/teams/alpha/deco_left.png`} />}
                                        {(context && context.mintTeam)
                                            ? <img alt='' className={styles.decoRight} src={`./images/teams/${context.mintTeam.imageFolder}/deco_right.png`} />
                                            : <img alt='' className={styles.decoRight} src={`./images/teams/alpha/deco_right.png`} />
                                        } 
                                    </>
                            }*/}
                        </Parallax>
                        <Parallax className={styles.full}>
                            <div className={styles.butterfly}></div>
                            <div className={styles.bee}></div>
                        </Parallax>
                        <Parallax className={styles.text} y={[0, 50]}>
                            <Container className={styles.content}>
                                <div className={styles.text}>
                                    <img alt='' className={styles.titleImage} src={'./images/title_2.png'} />
                                    <h2>Rebirth of the Twelve Teams of the Matriverse</h2>
                                    <HashLink to='#About' className={styles.scrollButton} smooth>
                                        <span></span>
                                        {/* <span>Mint and Deploy</span> */}
                                        <ScrollIcon />
                                    </HashLink>
                                </div>
                            </Container>
                        </Parallax>
                        <div>

                        </div>
                    </ParallaxProvider>
                </Section>
                <ParallaxProvider>
                    <Section
                        onScrollTop={() => { setHash('#About') }}
                        id='About' className={styles.aboutSection}>

                        <div className={styles.full}>
                            <img alt='' src={'./images/deco/plants.png'} className={styles.decoLeft} />
                            <img alt='' src={'./images/deco/flowers.png'} className={styles.decoRight} />
                        </div>
                        <Parallax>
                            <Container className={styles.content}>
                                <img alt='' className={styles.titleDeco} src={'./images/deco/boat.png'} />
                                <h2>About Tez Dozen</h2>
                                {aboutDatas.aboutTezDozen.map((p, i) => <p key={i}>{p}</p>)}
                                <h2>Story</h2>
                                {aboutDatas.story.map((p, i) => <p key={i}>{p}</p>)}
                                <h2>Key Features</h2>
                                {keyFeatures}
                                <h2>Creators</h2>
                                {team}
                            </Container>

                        </Parallax>
                    </Section>
                </ParallaxProvider>
                <Section full className={styles.completedSection}>{completed}

                </Section>
                <Section id="Timeline" className={styles.timelineSection}
                    onScrollTop={() => { setHash('#Timeline') }}
                >
                    <Container className={styles.content}>
                        <img alt='' className={styles.titleDeco} src={'./images/deco/fan.png'} />
                        <h2>Roadmap</h2>
                        {timeline}
                    </Container>
                    <img alt='' src={'./images/deco/coconut.png'} className={styles.decoLeft} />
                    <img alt='' src={'./images/deco/cactus.png'} className={styles.decoRight} />
                </Section>
                <Section id="FAQ" className={styles.faqSection}
                    onScrollTop={() => { setHash('#FAQ') }} >

                    <Container className={styles.content}>
                        <img alt='' className={styles.titleDeco} src={'./images/deco/smiley.png'} />
                        <h2>FAQs</h2>
                        {faqs}
                    </Container>
                    <img alt='' src={'./images/deco/river.png'} className={styles.decoLeft} />
                    <img alt='' src={'./images/deco/stone.png'} className={styles.decoRight} />
                </Section>
                <Footer />

            </Page>
        </>
    )
}