import { parseDateTime } from "./datetime";

function calcCardBankBalance(baseDAO, baseTimestamp, accumulateRate, withdrawn, extra, all_bonus) {
    var now = new Date()
    var duration_in_sec = (now - baseTimestamp) / 1000
    var accumulateRate_sec_per_microdao = accumulateRate / 10000
    var balance_microdao = accumulateRate_sec_per_microdao === 0 ? (all_bonus + baseDAO - withdrawn + extra) : (all_bonus + baseDAO + (duration_in_sec / accumulateRate_sec_per_microdao) - withdrawn + extra)
    var balance = balance_microdao / 1000000
    return balance
}

export function getAkaDao(atm, id, { withdrawn, single_bonus }, { Team, CardBank }) {

    // get atm(json) from https://api.tzkt.io/v1/contracts/KT1AXScSrpWLMud1hGN7BcsjDqPgXzAwCxo1/storage
    var all_bonus = atm.all_bonus

    // get withdrawn, single_bonus(json) from https://api.tzkt.io/v1/bigmaps/98036/keys/{id}

    // get Team and CardBank from tdtm attribute (akaswap api)

    // parse team id
    var teamId
    switch (Team) {
        case 'alpha':
            teamId = 1
            break;
        case 'beta':
            teamId = 2
            break;
        case 'gamma':
            teamId = 3
            break;
        case 'delta':
            teamId = 4
            break;
        case 'epsilon':
            teamId = 5
            break;
        case 'zeta':
            teamId = 6
            break;
        case 'eta':
            teamId = 7
            break;
        case 'theta':
            teamId = 8
            break;
        case 'iota':
            teamId = 9
            break;
        case 'kappa':
            teamId = 10
            break;
        case 'lambda':
            teamId = 11
            break;
        case 'mu':
            teamId = 12
            break;
        default:
            teamId = 0
            break;
    }

    // get duration_id and base_time
    var now = new Date()
    var duration_id, base_time

    var team_durations = atm.time_to_duration_id[teamId]

    team_durations.forEach(duration => {
        if (now > parseDateTime(duration.base_time)) {
            duration_id = duration.duration_id
            base_time = duration.base_time
        }
    });

    // get acc rate and base_dao from duration_id
    var acc_rate, base_dao

    var isGold = CardBank === 'sextuple'

    if (isGold) {
        acc_rate = atm.gold_duration_map[duration_id].acc_rate
        base_dao = atm.gold_duration_map[duration_id].base_dao
    }
    else {
        acc_rate = atm.regular_duration_map[duration_id].acc_rate
        base_dao = atm.regular_duration_map[duration_id].base_dao
    }

    return calcCardBankBalance(parseInt(base_dao), parseDateTime(base_time), parseFloat(acc_rate), parseInt(withdrawn), parseInt(single_bonus), parseInt(all_bonus))
}