import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as AddIcon } from './images/add_icon.svg'
import { ReactComponent as MinusIcon } from './images/minus_icon.svg'
import { ReactComponent as CloseButton } from './images/close_button.svg'
import { ReactComponent as SuccessIcon } from './images/success.svg'
import { ReactComponent as ErrorIcon } from './images/error.svg'
import { TezDozenContext } from '../../context/TezDozenContext';
import { raffleContract, apiUrl, tzktUrl, rafflesBigMap, raffleOpen } from '../../context/constant';
import { prizes } from '../../data/raffle.json'

export const RaffleModal = ({ voucherAmount, setIsOpened = () => null, updateVoucher = () => null }) => {
  const [step, setStep] = useState(0)
  const [success, setSuccess] = useState(false)
  const [raffles, setRaffles] = useState([])
  const [raffleUsed, setRaffleUsed] = useState(voucherAmount)
  const [showRaffles, setShowRaffles] = useState(false)
  const [resultMessage, setResultMessage] = useState('Connecting your wallet...')
  const context = useContext(TezDozenContext)
  const axios = require('axios')

  useEffect(() => {
    // set selected collection to the first available token
    getRaffles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trimAddress = (address) => {
    //return <a target='_blank' rel='noreferrer' href={`https://tzkt.io/${address}/operations/`}>{address.substring(0, 3) + '...' + address.substring(address.length - 5, address.length)}</a>
    return <a target='_blank' rel='noreferrer' href={`https://tzkt.io/${address}/operations/`}>{'...' + address.substring(address.length - 5, address.length)}</a>
  }

  const raffleBox = () => <>
    <div className={styles.raffleBox} onClick={() => setShowRaffles(!showRaffles)}>
      <div className={styles.raffleBoxImage}>
        {showRaffles && <div className={styles.raffleBoxGrid}>
          {raffles.map((r, i) => <div key={i}
            style={{ animationDelay: `${i * 100}ms` }}
            className={`${styles.ticket} ${r.value === context.address ? styles.myTicket : ''}`}>
            {trimAddress(r.value)}
          </div>)}
        </div>}
      </div>
      <div className={styles.caption}>Click to check the raffle box!</div>
    </div>
  </>

  const amountInput = () =>
    <div className={styles.amountInput}>
      <MinusIcon onClick={() => { if (raffleUsed > 1) { setRaffleUsed(raffleUsed - 1) } }} className={raffleUsed > 1 ? styles.enabled : styles.disabled} />
      <input
        value={`${raffleUsed} ${raffleUsed > 1 ? 'tickets' : 'ticket'}`}
        onChange={(e) => {
          let n = parseInt(e.target.value.split(' ')[0])
          if (n > 0 && n <= voucherAmount) {
            setRaffleUsed(n)
          }
        }}
        max={voucherAmount} min={1}
        disabled={voucherAmount === 0} />
      <span></span>
      <AddIcon onClick={() => { if (raffleUsed < voucherAmount) { setRaffleUsed(raffleUsed + 1) } }} className={raffleUsed < voucherAmount ? styles.enabled : styles.disabled} />
    </div>

  function getRaffles() {
    axios.get(`${apiUrl}/v1/bigmaps/${rafflesBigMap}/keys`)
      .then((res) => {
        setRaffles(res.data)
      })
  }


  // STEP 1: Welcome
  const raffle = () => {
    const link = (opHash, message) => (
      <>{message}<br></br>
        {opHash && <a className={styles.tzktLink} target='_blank' rel='noreferrer' href={tzktUrl + opHash}>View on tzkt.io →</a>}
      </>
    )
    function onSendRaffle() {
      // call beacon here
      var send = context.raffle
      setResultMessage('Connecting your wallet...')
      setStep(1)
      send(
        raffleContract,
        raffleUsed,
        (opHash) => {
          setResultMessage(link(opHash, 'Transaction processing...'))
        },
        (opHash) => {
          setStep(2)
          setSuccess(true)
          updateVoucher()
          setResultMessage(link(opHash, <div><h4>Transaction Completed  !</h4>
            The result will be executed by smart contract on 2022/6/9, <br></br>
            and automatically airdrop the prize to winner's wallet .</div>))
        },
        (opHash) => {
          setStep(2)
          setResultMessage(link(opHash, `Failed to change background!`))
        }
      ).then((e) => {
        if (e) {
          setResultMessage(e.description)
          setStep(2)
        } else {
          // setResultMessage('Transaction failed!')
        }
      })
    }
    return (
      <>
        <div className={styles.row}>
          <div className={styles.left}>{raffleBox()}</div>
          <div className={styles.right}>
            <div>
              <h4 className={styles.stepTitle}>akaSwap x TezDozen Event</h4>
              <h4 className={styles.stepTitle}>2022/6/1 to 2022/6/8 23:59 (UTC+8)</h4>
              <ul className={styles.raffleDesc}>
                <li>You can click "RAFFLE" button to drop the raffle ticket into the raffle box.</li>
                <li>After the contract is executed, the raffle ticket will be sent to the contract.</li>
                <li>You can click the raffle box on the left to check the tickets in the box.</li>
              </ul>
              <div>{amountInput()}</div>
              <div className={styles.raffleButton}>{voucherAmount > 0
                ? <button onClick={() => onSendRaffle()}>Raffle</button>
                : <button disabled>Ticket needed</button>}
              </div>

            </div>

          </div>
        </div>

      </>
    )
  }

  // STEP 2
  const processing = () => (
    <div className={styles.container}>
      <div className={styles.loading}></div>
      <div className={styles.loadingMessage}>{resultMessage}</div>
    </div>
  )

  // STEP 3
  const result = (success, TDTM, bg) => {
    function onFinish() {
      setIsOpened(false)
    }
    function onBack() {
      setStep(0)
    }
    return (
      <div className={styles.container + ' ' + styles.result}>
        {
          success ? <div className={styles.resultIcon}><SuccessIcon /></div>
            : <div className={styles.resultIcon}>  <ErrorIcon /></div>
        }
        <div className={styles.resultMessage}>{resultMessage}</div>
        {!success && <button onClick={onBack}>Back</button>}
        <button onClick={onFinish}>Finish</button>
      </div>
    )
  }

  // Step 4
  const prize = () => {
    return (
      <>
        <div className={styles.row}>
          <div className={styles.left}>
            <div className={styles.raffleBox}>
              <div className={styles.raffleBoxImage + ' ' + styles.win}>
              </div>
              {/* <div className={styles.caption}>Click to check the raffle box!</div> */}
            </div>
          </div>
          <div className={styles.right}>
            <div>

              <div className={styles.rafflePrizes}>
                {prizes.map((p, i) =>
                  <div className={styles.prize} key={i}>
                    <img src={`./images/prizes/${p.id}.png`} alt={p.id}></img>
                    <div className={styles.prizeName}>{p.name}</div>
                    <div className={styles.prizeWinner}>Winner: {trimAddress(p.address)}</div>
                  </div>)}
              </div>
              <h4 className={styles.stepTitle}>The raffle event has ended on 6/9. Congratulations to the winners and welcome to TDTM family！</h4>
            </div>
          </div>
        </div>

      </>
    )
  }

  function onBack() {
    setStep(step - 1)
  }
  return (
    <div className={styles.modalOverlay} onClick={(e) => { if (e.target === e.currentTarget) setIsOpened(false) }}>
      {/* {step !== 1 && <div className={styles.decoLeft}></div>}
      {step !== 1 && <div className={styles.decoRight}></div>} */}
      <div className={styles.raffleModal}>
        <div className={styles.CloseButton} onClick={(e) => { setIsOpened(false) }}><CloseButton /></div>
        <h3 className={styles.title}><div className={styles.titledecoLeft}></div>Raffle Event<div className={styles.titledecoRight}></div></h3>
        {!raffleOpen && prize()}
        {raffleOpen && step === 0 && raffle()}
        {raffleOpen && step === 1 && processing()}
        {raffleOpen && step === 2 && result(success)}
        {raffleOpen && step > 0 && step < 3 && <button className={styles.navButton} onClick={() => onBack()}>Back</button>}
      </div>
    </div>
  )
}